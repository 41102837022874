import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ExtraContent, PartnerInfo } from './cobranding.interface';

export interface CobrandingState extends EntityState<ExtraContent> {
  showSponsorContent: boolean;
  currentVoucher: string;
  partnerInfo: PartnerInfo;
}

export function createInitialState(): CobrandingState {
  const nullState: CobrandingState = {
    showSponsorContent: undefined,
    currentVoucher: undefined,
    partnerInfo: {
      provider_id: undefined,
      name: undefined,
      logo_url: undefined,
      primary_color: undefined,
      secondary_color: undefined,
      onboarding_details_1: undefined,
      onboarding_details_2: undefined,
      custom_content_title: undefined,
      custom_content_headline: undefined,
      custom_content_description: undefined,
      custom_content_type_1_title: undefined,
      custom_content_type_1_description: undefined,
      custom_content_type_2_title: undefined,
      custom_content_type_2_description: undefined,
    },
  };

  return nullState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'cobranding',
  idKey: 'extra_id',
  resettable: true,
})
export class CobrandingStore extends EntityStore<CobrandingState> {
  constructor() {
    super(createInitialState());
  }
}
