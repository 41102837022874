import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { map, tap } from 'rxjs/operators';
import { CheckupApiResponse } from '../../../shared/interfaces/api/checkup-api.interface';
import { CheckupApiService } from '../../api/checkup-api.service';
import { DataProviderService } from '../data-provider/data-handler.service';
import { StorageService } from '../storage/storage.service';

import { UserResults } from '../../../shared/interfaces/checkup.interface';
import { ResultsService } from '../../../modules/results/state/results.service';
import { WarningDoctorMessageComponent } from '../../../shared/overlays/warning-doctor-message/warning-doctor-message.component';
import { EventsService } from '../events/events.service';

@Injectable({
  providedIn: 'root',
})
export class CheckupService {
  // TODO: Move to a separate storage
  get checkup(): UserResults {
    if (
      this.dataProvider &&
      this.dataProvider.data &&
      this.dataProvider.data.user &&
      this.dataProvider.data.user.checkups &&
      this.dataProvider.data.user.checkups.length > 0
    ) {
      return this.dataProvider.data.user.checkups[0];
    }
    return null;
  }

  set checkup(checkup: UserResults) {
    if (this.dataProvider.data.user.checkups) {
      this.dataProvider.data.user.checkups[0] = checkup;
    } else {
      this.dataProvider.data.user.checkups = [checkup];
    }
  }

  get checkupNotes() {
    if (this.checkup) {
      return this.checkup.notes;
    }
    return null;
  }

  set checkupNotes(notes: CheckupApiResponse) {
    this.checkup.notes = notes;
  }

  constructor(
    private checkupApi: CheckupApiService,
    private dataProvider: DataProviderService,
    private storage: StorageService,
    private eventsService: EventsService,
    private modalCtrl: ModalController,
    private resultsService: ResultsService
  ) {}

  fetchResults(): Observable<UserResults> {
    return forkJoin([
      this.resultsService.getGroups(),
      this.resultsService.getReviews(),
      this.fetchCheckupInformation(),
    ]).pipe(
      map(([, , notes]) => {
        this.checkupNotes = notes;

        this.storage.saveData();
        this.eventsService.dataCheckupSubject$.next(this.checkup);

        return this.checkup;
      })
    );
  }

  fetchCheckupInformation() {
    return this.checkupApi.getCheckupInformation().pipe(
      tap((notes) => {
        this.checkHealthWarningModal(notes);
      })
    );
  }

  private async checkHealthWarningModal(notes: CheckupApiResponse) {
    if (!notes || !notes.appointment) {
      return false;
    }

    if (notes.appointment.risk_flagged && !notes.appointment.risk_accepted) {
      // check if modal is already open
      const activeModal = await this.modalCtrl.getTop();
      if (activeModal && activeModal.component === WarningDoctorMessageComponent) {
        return true;
      }

      const modal = await this.modalCtrl.create({
        component: WarningDoctorMessageComponent,
        componentProps: { message: notes.appointment.risk_message },
        cssClass: 'c-ion-modal',
        backdropDismiss: false,
      });

      await modal.present();
    }

    return false;
  }
}
