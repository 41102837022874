import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { DataProviderService } from '../services/data-provider/data-handler.service';
import { environment as environmentDevelopment } from '../../../environments/environment.dev';
import { environment as environmentDefault } from '../../../environments/environment';
import { EventsService } from '../services/events/events.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private maxWaiting = 45000;

  private impersonate = null;

  constructor(
    private http: HttpClient,
    private dataProvider: DataProviderService,
    private eventsService: EventsService
  ) {}

  getApiEndpoint() {
    return this.dataProvider.data.isDevelopmentEndpoint
      ? environmentDevelopment.api
      : environmentDefault.api;
  }

  del(url: string, body?: any): Observable<any> {
    const headers: HttpHeaders = this.createHeaders();

    return this.http.delete(this.getApiEndpoint() + url, { headers, params: body }).pipe(
      timeout(this.maxWaiting),
      catchError((error: any) => this.handleError(error))
    );
  }

  post(url: string, body: Object): Observable<any> {
    const headers = this.createHeaders();

    return this.http.post(this.getApiEndpoint() + url, body, { headers }).pipe(
      timeout(this.maxWaiting),
      catchError((error: any) => this.handleError(error))
    );
  }

  put(url: string, body: Object): Observable<any> {
    const headers = this.createHeaders();

    return this.http.put(this.getApiEndpoint() + url, JSON.stringify(body), { headers }).pipe(
      timeout(this.maxWaiting),
      catchError((error: any) => this.handleError(error))
    );
  }

  get(url: string, params: any = undefined): Observable<any> {
    const headers = this.createHeaders();

    return this.http.get(this.getApiEndpoint() + url, { headers, params }).pipe(
      timeout(this.maxWaiting),
      catchError((error: any) => this.handleError(error))
    );
  }

  getLocal(url: string): Observable<any> {
    const headers = this.createHeaders();

    return this.http.get(`http://localhost:8100${url}`, { headers }).pipe(
      timeout(this.maxWaiting),
      catchError((error: any) => this.handleError(error))
    );
  }

  createHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');

    if (this.dataProvider.data) {
      if (
        this.dataProvider.data.authentication &&
        this.dataProvider.data.authentication.token_type &&
        this.dataProvider.data.authentication.access_token
      ) {
        headers = headers.append(
          'Authorization',
          `${this.dataProvider.data.authentication.token_type} ${this.dataProvider.data.authentication.access_token}`
        );
      }

      headers = headers.append(
        'Accept-Language',
        this.dataProvider.data.settings.defaultLang.locale
      );
    }

    if (this.impersonate != null) {
      headers = headers.append('Impersonate-User', this.impersonate);
    }

    return headers;
  }
  /**
   * Set impersonate User ID. Used to set request headers to impersonate another user.
   * @param wellabeId - UserID to be impersonated
   */

  public setImpersonateHeader(wellabeId: string) {
    this.impersonate = wellabeId;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status && error.status === 401) {
      this.eventsService.userLogoutSubject$.next();
    }

    if (!navigator.onLine) {
      return throwError('ERROR_NO_INTERNET');
    }
    if ((error as any).name === 'TimeoutError') {
      return throwError('ERROR_TIMEOUT');
    }
    return throwError(error);
  }
}
