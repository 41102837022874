import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

// ================================================================================================
// COMPONENTS
// ================================================================================================

import { RouterModule } from '@angular/router';
import { FieldErrorMessageComponent } from './field-error-message/field-error-message.component';
import { FieldInputComponent } from './field-input/field-input.component';
import { FieldPasswordComponent } from './field-password/field-password.component';
import { FieldRadioComponent } from './field-radio/field-radio.component';
import { FieldCheckboxComponent } from './field-checkbox/field-checkbox.component';
import { ModalComponent } from './modal/modal.component';
import { FieldSelectComponent } from './field-select/field-select.component';
import { FieldToggleComponent } from './field-toggle/field-toggle.component';
import { FieldTextAreaComponent } from './field-textarea/field-textarea.component';

import { ButtonComponent } from './button/button.component';
import { ArrowButtonComponent } from './arrow-button/arrow-button.component';
import { IconComponent } from './icon/icon.component';
import { TermsFooterComponent } from './terms-footer/terms-footer.component';
import { FieldNumberComponent } from './field-number/field-number.component';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { BackButtonComponent } from './back-button/back-button.component';
import { FieldDateComponent } from './field-date/field-date.component';
import { FieldPickerComponent } from './field-picker/field-picker.component';
import { HeaderComponent } from './header/header.component';
import { FlyoverComponent } from './flyover/flyover.component';
import { FieldMultiComponent } from './field-multi/field-multi.component';
import { InformationPageTemplateComponent } from './information-page-template/information-page-template.component';
import { SlidesContainerComponent } from './slides-container/slides-container.component';
import { CodeInputContainerComponent } from './code-input-container/code-input-container.component';
import { FormInputCodeMaskComponent } from './form-input-code-mask/form-input-code-mask';
import { ResponsiveHeaderComponent } from './responsive-header/responsive-header.component';
import { LocaleDateComponent } from './locale-date/locale-date.component';
import { ContentParserComponent } from './content-parser/content-parser.component';
import { ImpersonateBarComponent } from './impersonate-bar/impersonate-bar.component';
import { ChallengeLogModalPage } from '../overlays/challenge-log-modal/challenge-log-modal.page';
import { FieldPasswordRulesComponent } from './field-password-rules/field-password-rules.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { LanguageDropdownComponent } from './language-dropdown/language-dropdown.component';
import { PickerRadioModal } from '../overlays/picker-radio/picker-radio.modal';

@NgModule({
  declarations: [
    ModalComponent,
    ButtonComponent,
    ArrowButtonComponent,
    FieldErrorMessageComponent,
    FieldPasswordComponent,
    FieldInputComponent,
    FieldRadioComponent,
    FieldMultiComponent,
    FieldToggleComponent,
    FieldCheckboxComponent,
    FieldSelectComponent,
    FieldNumberComponent,
    TermsFooterComponent,
    FieldTextAreaComponent,
    IconComponent,
    BackButtonComponent,
    FieldDateComponent,
    FieldPickerComponent,
    HeaderComponent,
    ResponsiveHeaderComponent,
    FlyoverComponent,
    InformationPageTemplateComponent,
    SlidesContainerComponent,
    CodeInputContainerComponent,
    FormInputCodeMaskComponent,
    LocaleDateComponent,
    ContentParserComponent,
    ImpersonateBarComponent,
    ProgressBarComponent,
    ChallengeLogModalPage,
    FieldPasswordRulesComponent,
    LanguageDropdownComponent,
    PickerRadioModal,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    DirectivesModule,
    PipesModule,
    RouterModule,
  ],
  exports: [
    ModalComponent,
    ButtonComponent,
    ArrowButtonComponent,
    FieldErrorMessageComponent,
    FieldPasswordComponent,
    FieldInputComponent,
    FieldRadioComponent,
    FieldMultiComponent,
    FieldToggleComponent,
    FieldCheckboxComponent,
    FieldSelectComponent,
    FieldNumberComponent,
    TermsFooterComponent,
    FieldTextAreaComponent,
    IconComponent,
    BackButtonComponent,
    FieldDateComponent,
    FieldPickerComponent,
    HeaderComponent,
    ResponsiveHeaderComponent,
    FlyoverComponent,
    InformationPageTemplateComponent,
    SlidesContainerComponent,
    CodeInputContainerComponent,
    FormInputCodeMaskComponent,
    LocaleDateComponent,
    ContentParserComponent,
    ImpersonateBarComponent,
    FieldPasswordRulesComponent,
    ProgressBarComponent,
    PickerRadioModal,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
