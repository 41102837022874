import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewportModule } from 'ng-in-viewport';
import { StickyHeaderDirective } from './sticky-header/sticky-header.directive';
import { FitTextDirective } from './fit-text/fit-text.directive';
import { ImpersonateDirective } from './impersonate/impersonate.directive';
import { QueryMergeDirective } from './query-merge/query-merge.directive';

@NgModule({
  declarations: [
    StickyHeaderDirective,
    FitTextDirective,
    ImpersonateDirective,
    QueryMergeDirective,
  ],
  imports: [CommonModule, InViewportModule],
  exports: [
    StickyHeaderDirective,
    FitTextDirective,
    ImpersonateDirective,
    InViewportModule,
    QueryMergeDirective,
  ],
})
export class DirectivesModule {}
