import { Injectable } from '@angular/core';
import { tap, finalize, catchError, map } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import * as moment from 'moment';
import { ResultsApiService } from '../../../core/api/results-api.service';
import { ResultsStore } from './results.store';
import { ResultsQuery } from './results.query';
import { BiomarkerReading } from '../../../shared/interfaces/api/results-api.interface';

@Injectable({
  providedIn: 'root',
})
export class ResultsService {
  constructor(
    private store: ResultsStore,
    private resultsQuery: ResultsQuery,
    private apiService: ResultsApiService
  ) {}

  getGroups() {
    if (this.resultsQuery.getHasCache()) {
      return of(null);
    }
    this.store.setLoading(true);
    this.store.setError(null);
    return this.apiService.getGroups().pipe(
      tap((groups) => this.store.set(groups)),
      catchError((err) => {
        this.store.setError(err.toString());
        return throwError(err);
      }),
      finalize(() => {
        this.store.setLoading(false);
      })
    );
  }

  getReviews() {
    this.store.setLoading(true);
    this.store.setError(null);
    return this.apiService.getReviews().pipe(
      map((reviews) => reviews.filter((review) => review.reviewed && review.performed)),
      map((reviews) => reviews.reverse()),
      tap((reviews) => this.store.update({ reviews })),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => {
        this.store.setLoading(false);
      })
    );
  }

  getBiomarker(biomarker_id: number) {
    this.store.setLoading(true);
    this.store.setError(null);

    return this.apiService.getBiomarker(biomarker_id).pipe(
      tap(async (biomarker) => {
        // Needs to check if biomarker is already in cache
        // This is necessary for new biomarkers calculated
        // from questionnaires
        if (!this.resultsQuery.getEntity(biomarker.group_id)) {
          await this.getGroups().toPromise();
        }

        const biomarkerGroup = this.resultsQuery.getEntity(biomarker.group_id);
        const biomarkerIdx = biomarkerGroup.biomarkers.findIndex((element) => {
          return element.biomarker_id === biomarker_id;
        });

        const biomarkers = [...biomarkerGroup.biomarkers];

        if (biomarkerIdx === -1) {
          biomarkers.push(biomarker);
        } else {
          biomarkers.splice(biomarkerIdx, 1, biomarker);
        }

        const updatedBiomarkerGroup = {
          ...biomarkerGroup,
          biomarkers,
        };

        this.store.upsert(biomarkerGroup.group_id, updatedBiomarkerGroup);
      }),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => {
        this.store.setLoading(false);
      })
    );
  }

  getBiomarkerReadings(group_id: number, biomarker_id: number) {
    return this.apiService.getBiomarkerReadings(biomarker_id).pipe(
      tap((readings) => {
        const biomarkerGroup = this.resultsQuery.getEntity(group_id);
        if (!biomarkerGroup) {
          return;
        }
        const biomarker = biomarkerGroup.biomarkers.find((el) => el.biomarker_id === biomarker_id);
        const biomarkerIdx = biomarkerGroup.biomarkers.findIndex(
          (el) => el.biomarker_id === biomarker_id
        );
        if (!biomarker) {
          return;
        }

        // update the readings for this biomarker
        const results = readings
          .map((reading) => {
            return {
              checkup_id: reading.checkup_id,
              performed: reading.type === 'measured',
              reading,
            };
          })
          .sort((a, b) => (moment(a.reading.measured_at).isAfter(b.reading.measured_at) ? 1 : -1));
        const updatedBiomarker = { ...biomarker, results };
        const updatedBiomarkers = [...biomarkerGroup.biomarkers];
        updatedBiomarkers.splice(biomarkerIdx, 1, updatedBiomarker);
        const updatedBiomarkerGroup = { ...biomarkerGroup, biomarkers: updatedBiomarkers };
        this.store.upsert(group_id, updatedBiomarkerGroup);
      })
    );
  }

  getBiomarkersList(reportableStatus: 'all' | 'reportable' | 'notReportable' = 'all') {
    return this.apiService.getBiomarkerList().pipe(
      map((biomarkersGroups) => {
        if (reportableStatus === 'all') return biomarkersGroups;

        return biomarkersGroups.map((group) => {
          const biomarkers = group.biomarkers.filter((marker) => {
            return reportableStatus === 'reportable'
              ? marker.self_reportable
              : !marker.self_reportable;
          });

          return { ...group, biomarkers };
        });
      })
    );
  }

  getPdfResult() {
    return this.apiService.getPdfResult();
  }

  addBiomarkerReading(biomarker_id: number, body: BiomarkerReading) {
    return this.apiService.postBiomarkerReading(biomarker_id, body).pipe(
      tap(() => {
        this.store.setHasCache(false);
      })
    );
  }

  deleteBiomarkerReading(groupd_id: number, biomarker_id: number, reading_id: string) {
    return this.apiService.deleteBiomarkerReading(biomarker_id, reading_id).pipe(
      tap(() => {
        /*
          Invalidating the biomarker on cache to force reload,
          This is done because it's not guaranteed that the biomarker reading
          have their reading_id on cache, so we need to invalidate the entry.
        */
        const biomarkerGroup = this.resultsQuery.getEntity(groupd_id);
        if (!biomarkerGroup) {
          return;
        }
        const updatedBiomarkers = biomarkerGroup.biomarkers.filter(
          (el) => el.biomarker_id !== biomarker_id
        );
        const updatedBiomarkerGroup = {
          ...biomarkerGroup,
          biomarkers: updatedBiomarkers,
        };

        if (updatedBiomarkers.length > 0) {
          this.store.upsert(groupd_id, updatedBiomarkerGroup);
        } else {
          this.store.remove(groupd_id);
        }
        this.store.setHasCache(false);
      })
    );
  }

  getHealthSummary() {
    return this.apiService.getHealthSummary().pipe(
      tap((healthSummary) => {
        this.store.update({ healthSummary });
      })
    );
  }
}
