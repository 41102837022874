import { Injectable, OnDestroy, EventEmitter } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AnalyticsService } from '../analytics/analytics.service';
import { ANALYTICS_TRACKING } from '../../../shared/interfaces/analytics.interface';
import { ApiService } from '../../api/api';
import { DataProviderService } from '../data-provider/data-handler.service';
import { StorageService } from '../storage/storage.service';
import { TranslationsService } from '../translations/translations.service';
import { UserService } from '../user/user.service';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { TeleConsultationMessage, TeleConsultationMessageType } from './impersonate.interface';
import { AppModeService } from '../app-mode/app-mode.service';

@Injectable({
  providedIn: 'root',
})
export class ImpersonateService implements OnDestroy {
  public impersonateId$: EventEmitter<string>;

  private isInitialized = false;

  private isImpersonating = false;

  private impersonateId: string;

  private handler: any;

  private parent: Window;

  constructor(
    public translation: TranslationsService,
    private analyticsTrackerService: AnalyticsService,
    private api: ApiService,
    private userProvider: UserService,
    private loadingCtrl: LoadingController,
    private provider: DataProviderService,
    private errorDisplayProvider: ErrorDialogService,
    private storageProvider: StorageService,
    private router: Router,
    private alertController: AlertController,
    private actionSheetController: ActionSheetController,
    private translate: TranslationsService,
    private appModeService: AppModeService
  ) {
    this.impersonateId$ = new EventEmitter();

    this.handler = window.addEventListener('message', this.messageHandler.bind(this), false);

    if (this.provider.data.settings.impersonate != null) {
      this.isImpersonating = true;
      this.impersonateId = this.provider.data.settings.impersonate;
    }
  }

  ngOnDestroy() {
    // calling the handler will ensure it disconnects
    if (this.handler) {
      this.handler();
    }
  }

  public setup() {
    this.isInitialized = true;

    // populate parent if not already populated
    if (!this.parent) {
      this.parent = window.opener;
    }

    // if still no parent, skip
    if (!this.parent) {
      return;
    }

    // send handshake if has parent
    this.notify(TeleConsultationMessageType.HANDSHAKE, null);
  }

  public async showImpersonateModal() {
    const mode = this.appModeService.getIonicModeStyle();

    const alert = await this.alertController.create({
      mode,
      header: this.translate.phrases.IMPERSONATE_HEADER,
      inputs: [
        {
          label: this.translate.phrases.WELLABE_ID_LABEL,
          placeholder: this.translate.phrases.WELLABE_ID_LABEL,
          name: 'id',
          type: 'text',
        },
      ],
      buttons: [
        {
          text: this.translate.phrases.CANCEL_BUTTON,
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: this.translate.phrases.IMPERSONATE_CONFIRM,
          handler: (e) => {
            const { id } = e;

            if (!id) {
              return;
            }

            this.requestImpersonate(id);
          },
        },
      ],
    });

    await alert.present();
  }

  public async showImpersonateOptions() {
    const buttons = [
      {
        text: this.translate.phrases.IMPERSONATE_CHANGE_USER,
        handler: () => {
          this.showImpersonateModal();
        },
      },
      {
        text: this.translate.phrases.IMPERSONATE_STOP_IMPERSONATING,
        handler: () => {
          this.cancelImpersonate(true);
        },
      },
    ];

    const mode = this.appModeService.getIonicModeStyle();

    const actionSheet = await this.actionSheetController.create({
      mode,
      header: this.translate.phrases.IMPERSONATE_LABEL,
      buttons: [
        ...buttons,
        {
          text: this.translate.phrases.CANCEL_BUTTON,
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
  }

  public async requestImpersonate(impersonateId: string) {
    if (impersonateId == null || impersonateId === '') {
      return;
    }

    if (this.provider.data.user.info.can_impersonate !== true) {
      const phrase = this.translate.phrases.IMPERSONATE_ERROR_NO_PERMISSION;

      this.notify(TeleConsultationMessageType.ERROR, phrase, true);
      this.errorDisplayProvider.showError(phrase, 'error', false, false);
      return;
    }

    // eslint-disable-next-line no-param-reassign
    impersonateId = impersonateId.toUpperCase();

    this.analyticsTrackerService.trackEvent(
      ANALYTICS_TRACKING.ACTIONS.REQUEST_IMPERSONATE,
      `Request Impersonating User:${impersonateId}`
    );

    this.api.setImpersonateHeader(impersonateId);
    this.provider.data.settings.impersonate = impersonateId;

    this.isImpersonating = true;
    this.impersonateId = impersonateId;

    // eslint-disable-next-line consistent-return
    return this.forceUpdate()
      .catch(() => {
        this.notify(TeleConsultationMessageType.ERROR, 'Failed to impersonate', true);

        this.cancelImpersonate();
        this.isImpersonating = false;
      })
      .finally(() => {
        this.storageProvider.saveData();
      });
  }

  public async cancelImpersonate(forceUpdate = false) {
    // This if avoids calling this event when users logs out
    if (this.provider.data.settings.impersonate !== null) {
      this.analyticsTrackerService.trackEvent(
        ANALYTICS_TRACKING.ACTIONS.CANCEL_IMPERSONATE,
        `Cancel Impersonating User: ${this.provider.data.settings.impersonate.toUpperCase()}`
      );
    }

    this.api.setImpersonateHeader(null);
    this.provider.data.settings.impersonate = null;

    this.isImpersonating = false;
    this.impersonateId = null;

    if (forceUpdate) {
      return this.forceUpdate()
        .catch(() => {
          const message =
            'Error while updating data: You are no longer impersonating, but some user data might still show up for you';

          this.notify(TeleConsultationMessageType.ERROR, message, true);
          this.errorDisplayProvider.showError(message);
        })
        .finally(() => {
          this.storageProvider.saveData();
        });
    }
    // notify
    this.notify(TeleConsultationMessageType.IMPERSONATE, null, true);

    this.storageProvider.saveData();
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      resolve(true);
    });
  }

  public async forceUpdate() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const loading = await this.loadingCtrl.create({
        cssClass: 'bl-loading-modal',
      });

      loading.present();

      this.userProvider
        .updateAll()
        .then(() => {
          // check if wellabe ID is updated
          if (
            this.impersonateId &&
            this.provider.data.user.info.wellabe_id !== this.impersonateId
          ) {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw 'User does not exist';
          }

          this.analyticsTrackerService.trackEvent(
            ANALYTICS_TRACKING.ACTIONS.REQUEST_REFRESH_DATA,
            'Successfully Refresh Data'
          );

          this.notify(TeleConsultationMessageType.IMPERSONATE, this.impersonateId, true);

          resolve(true);
        })
        .catch((err) => {
          this.analyticsTrackerService.trackEventError(
            ANALYTICS_TRACKING.ACTIONS.REQUEST_REFRESH_DATA,
            err
          );

          this.errorDisplayProvider.showError(err, 'error', false);

          reject(err);
        })
        .finally(() => {
          loading.dismiss();
        });
    });
  }

  /**
   * Notifies parent and internal components of change
   */
  private notify(type: TeleConsultationMessageType, data: any, emitInternally?: boolean) {
    // emits data if needed
    if (type === TeleConsultationMessageType.IMPERSONATE && emitInternally) {
      this.impersonateId$.emit(this.impersonateId);
    }

    // notify parent if any
    if (this.parent) {
      this.parent.postMessage(this.buildMessageData(type, data), '*');
    }
  }

  /**
   * Handles postWindow incoming messages
   */
  private messageHandler(event) {
    try {
      // if it fails to convert to type, ignore message
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const event_data: TeleConsultationMessage = event.data;

      if (event_data.type === TeleConsultationMessageType.HANDSHAKE) {
        // application was handshaked, save origin as parent
        this.parent = event.source;

        // send handshake back if ready
        if (this.isInitialized) {
          this.notify(TeleConsultationMessageType.HANDSHAKE, null, false);
        }
      } else if (event_data.type === TeleConsultationMessageType.IMPERSONATE) {
        const { wellabeId } = event_data.data;

        if (wellabeId) {
          this.requestImpersonate(wellabeId).then(() => {
            // navigates user to results automatically
            this.router.navigate(['tabs/results'], { queryParamsHandling: 'merge' });
          });
        } else {
          this.cancelImpersonate().then(() => {
            // navigates user to results automatically
            this.router.navigate(['settings'], { queryParamsHandling: 'merge' });
          });
        }
      } else if (event_data.type === TeleConsultationMessageType.QUERY) {
        // parent query for who's impersonated
        this.notify(TeleConsultationMessageType.IMPERSONATE, this.impersonateId, false);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  /**
   * Build standard data object to be transferred through the message
   */
  private buildMessageData(
    messageType: TeleConsultationMessageType,
    messageData: any
  ): TeleConsultationMessage {
    return {
      type: messageType,
      data: messageData,
    };
  }
}
