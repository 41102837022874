import { AbstractControl, ValidatorFn } from '@angular/forms';

export function positiveNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = +control.value;

    if (Number.isNaN(value)) {
      return { isNan: { value: control.value } };
    }

    return value >= 0 ? null : { nonPositive: { value: control.value } };
  };
}
