import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CobrandingApiService } from '../../../core/api/cobranding-api.service';
import { ExtraContent } from './cobranding.interface';
import { CobrandingStore } from './cobranding.store';

@Injectable({ providedIn: 'root' })
export class CobrandingService {
  constructor(
    private cobrandingStore: CobrandingStore,
    private cobrandingApiService: CobrandingApiService
  ) {}

  getPartnerDetails(voucher: string) {
    return this.cobrandingApiService.getCobrandedPartner(voucher).pipe(
      tap((partnerInfo) => {
        if (partnerInfo) {
          this.cobrandingStore.update((state) => {
            return { ...state, showSponsorContent: true };
          });
        } else {
          this.cobrandingStore.update((state) => {
            return { ...state, showSponsorContent: false };
          });
        }

        this.cobrandingStore.update({ currentVoucher: voucher, partnerInfo });
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getExtraContent(): Observable<ExtraContent[]> {
    return this.cobrandingApiService.getPartnerExtraContent().pipe(
      tap((extraContents) => {
        this.cobrandingStore.set(extraContents);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  resetStore() {
    this.cobrandingStore.reset();
    this.cobrandingStore.update({ showSponsorContent: false });
  }
}
