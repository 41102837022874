import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Appointment } from './appointments.interface';

export interface AppointmentsState {
  past: Appointment[];
  upcoming: Appointment[];
}

export function createInitialState(): AppointmentsState {
  return {
    past: undefined,
    upcoming: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'appointments',
  idKey: 'appointment_id',
  resettable: true,
  cache: {
    ttl: 30000,
  },
})
export class AppointmentsStore extends Store<AppointmentsState> {
  constructor() {
    super(createInitialState());
  }
}
