<ion-content class="c-modal__ion-content">
  <app-modal class="e-challenge-log-modal" center="true">
    <app-icon name="bar-chart-outline" class="c-modal__icon" size="md"></app-icon>

    <h2 class="c-modal__title u-margin-bottom--sm">
      {{'CHALLENGE_LOG_HEADER' | translate}}
    </h2>

    <p class="c-modal__description u-margin-bottom--md">
      {{'CHALLENGE_LOG_DESCRIPTION' | translate:{title: challenge.data.title} }}
    </p>

    <div class="c-modal__content">
      <div class="e-challenge-log-modal__field">
        <ng-container *ngIf="challenge.data.log_type === 'incremental'">
          <app-button class="e-challenge-log-modal__increment" variant="icon" icon="remove-outline"
            (click)="decreaseValue()">
          </app-button>
        </ng-container>

        <app-field-number class="e-challenge-log-modal__input" mode="stacked" [(ngModel)]="value"
          [placeholder]="placeholder" [label]="unit">
        </app-field-number>

        <ng-container *ngIf="challenge.data.log_type === 'incremental'">
          <app-button class="e-challenge-log-modal__increment" variant="icon" icon="add-outline"
            (click)="incrementValue()">
          </app-button>
        </ng-container>
      </div>
    </div>

    <div class="c-modal__footer">
      <app-button class="u-width--100" (click)="submit()" [loading]="loading">
        {{ 'CHALLENGE_LOG_BUTTON_SUBMIT' | translate}}
      </app-button>
    </div>
  </app-modal>
</ion-content>