import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslationsService } from '../../../core/services/translations/translations.service';
import { CheckupApiService } from '../../../core/api/checkup-api.service';

@Component({
  selector: 'app-warning-doctor-message',
  templateUrl: './warning-doctor-message.component.html',
  styleUrls: ['./warning-doctor-message.component.scss'],
})
export class WarningDoctorMessageComponent {
  @Input() message = '';

  loading = false;

  constructor(
    public translations: TranslationsService,
    public checkupApiService: CheckupApiService,
    private modalController: ModalController
  ) {}

  submitWarningRead() {
    this.loading = true;
    this.checkupApiService
      .putCheckup({ risk_accepted: true })
      .then(() => {
        this.loading = false;
        this.dismiss();
      })
      .catch(() => {
        this.loading = false;
        this.dismiss();
      });
  }

  public dismiss() {
    this.modalController.dismiss();
  }
}
