import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AccountStepType } from '../../../shared/interfaces/account.interface';
import { UserFlagService } from '../user-flag/user-flag.service';
import { DataProviderService } from '../data-provider/data-handler.service';
import { AppModeService } from '../app-mode/app-mode.service';
import { WalkthroughFlyoverComponent } from '../../../shared/overlays/walkthrough-flyover/walkthrough-flyover.component';
import { AppointmentsQuery, AppointmentsService } from '../../../modules/appointments/state';

@Injectable({
  providedIn: 'root',
})
export class StepHandlerService {
  constructor(
    private userFlagService: UserFlagService,
    private dataProvider: DataProviderService,
    private router: Router,
    private appModeService: AppModeService,
    private modalController: ModalController,
    private appointmentsQuery: AppointmentsQuery,
    private appointmentsService: AppointmentsService
  ) {}

  async goToInitialPage() {
    let initialPage: any = '';
    let appointments = this.appointmentsQuery.upcomingAppointments();

    const pages: Array<{ page: any; params?: any }> = [];
    const step: AccountStepType = this.dataProvider.userStep;

    switch (step) {
      case 'email_verification':
        initialPage = 'email-verification';
        break;
      case 'appointment':
        initialPage = 'appointment';
        break;
      case 'waiting-checkup':
        initialPage = 'onboarding/waiting-checkup';
        break;
      case 'app':
        if (this.appModeService.isWeb()) {
          // load appointments if not loaded yet
          if (!appointments) {
            await this.appointmentsService.getUpcomingAppointments().toPromise();
            appointments = this.appointmentsQuery.upcomingAppointments();
          }

          if (appointments.length > 0) {
            initialPage = 'onboarding/waiting-checkup';
          } else {
            initialPage = 'onboarding/results';
          }
        } else if (!this.userFlagService.getFlag('completed_onboarding')) {
          // Workaround for changing the flags from user to
          pages.push({ page: 'tabs/results' });
          this.openOnboardingFlyover();
        } else {
          initialPage = 'tabs/todays-goals';
        }
        break;
      default:
        initialPage = 'home';
        break;
    }

    if (pages.length > 0) {
      return this.router.navigate([pages[pages.length - 1].page], {
        replaceUrl: true,
        queryParamsHandling: 'merge',
      });
    }
    return this.router.navigate([initialPage], { replaceUrl: true, queryParamsHandling: 'merge' });
  }

  async openOnboardingFlyover() {
    const modal = await this.modalController.create({
      component: WalkthroughFlyoverComponent,
      componentProps: {},
      mode: 'md',
      cssClass: 'c-ion-flyover',
    });

    modal.onDidDismiss().then(() => {
      this.userFlagService.setFlag('completed_onboarding');
    });

    return modal.present();
  }
}
