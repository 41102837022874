import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { ComponentsModule } from '../../shared/components/components.module';
import { PartnerModal } from './overlays/partner-modal/partner-modal.modal';
import { PartnerShortModal } from './overlays/partner-short-modal/partner-short-modal.modal';
import { ExtraContentFlyover } from './overlays/extra-content/extra-content.flyover';
import { ActivitiesListingComponent } from './components/activities-listing/activities-listing.component';
import { TranslationPipe } from '../../shared/pipes/translation/translation';
import { PartnerFooterComponent } from './components/partner-footer/partner-footer.component';

@NgModule({
  declarations: [
    PartnerModal,
    PartnerShortModal,
    ActivitiesListingComponent,
    ExtraContentFlyover,
    PartnerFooterComponent,
  ],
  providers: [TranslationPipe],
  imports: [
    PipesModule,
    CommonModule,
    ComponentsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    PartnerModal,
    PartnerShortModal,
    ActivitiesListingComponent,
    ExtraContentFlyover,
    PartnerFooterComponent,
  ],
})
export class CobrandingModule {}
