// tslint:disable:max-line-length

export class TRANSLATIONS {
  public static readonly EN_TRANSLATIONS = {
    SETTINGS_DEVELOPMENT_TITLE: 'Development Tools',
    'CANCEL_APPOINTMENT.CONFIRM_HEADER': 'Cancel your appointment',
    REGISTER_WITH_EMAIL_LI_1: 'Email password recovery',
    APPOINTMENTS_DETAILS_MODAL_CANCEL_LINK: 'reschedule your appointment.',
    BIOMARKER_MEANING_TITLE: 'What does it mean for you?',
    BIOMARKER_UNDERSTANDING_TITLE: 'Understanding my results',
    BIOMARKER_IMPROVE_TITLE: 'How can I improve?',
    BIOMARKER_PROGRESS_ARROW: 'Your reading',
    RESULTS_TITLE: 'Results',
    SCHEDULE_WAITING_CALENDAR_DUPLICATED_1: 'Add to my calendar',
    CHALLENGE_QUIT_BUTTON: 'Quit Challenge',
    'APPOINTMENT_EDIT_MODAL.CHECKUP_DESCRIPTION': 'Choose a new date and time for the check-up.',
    APPOINTMENT_UPCOMING_JOIN_CALL: 'Join call',
    CANCEL_BUTTON: 'Cancel',
    HELLO: 'Hello',
    LOGOUT: 'Logout',
    CHALLENGE_CLOSE: 'Close',
    DAYS_LOWERCASE: 'days',
    SETTINGS_PROFILE_TITLE: 'Your profile',
    SETTINGS_DEVELOPMENT_LANGUAGE: 'Language',
    CHALLENGE_QUIT_CONFIRMATION: 'Are you sure you want to quit this challenge?',
    APPOINTMENT_CHECKUP: 'Check-up',
    REGISTER_CREATE_ACCOUNT: 'Create Account',
    APPOINTMENT_DETAIL_TELECON_DATE: 'Video consultation\u00a0on\u00a0{{date}}',
    ERROR_NO_INTERNET: 'Please, check your internet connection',
    APPOINTMENT_CANCEL: 'Not going to make it? You can always',
    'APPOINTMENT_EDIT_MODAL.TELECONSULTATION_TITLE': 'Reschedule video consultation',
    ALERT_BUTTON_CANCEL: 'Cancel',
    NEWS_DISMISS_BUTTON: 'Dismiss',
    NO_INTERNET_CONNECTION: 'No Internet Connection',
    CHECK_YOUR_INTERNET_CONNECTION: 'Please check your internet connection.',
    CHALLENGES_COMPLETED_TITLE: 'Completed',
    CHALLENGE_DAYS_LEFT: 'days left',
    TAB_RESULTS: 'Results',
    BIOMARKER_OVERVIEW_TITLE: 'Biomarker overview',
    RESET_PASS_INSTRUCTION:
      'Please enter your email address or wellabe ID. We will send you a link with login information and instructions to reset your password.',
    RESET_PASS_CONFIRMATION:
      'We sent on your email instructions on how to reset your password. Be aware that the link you received expires in an hour.',
    FORM_RETURN: 'Return to Login',
    NEWS_VIEW_MORE: 'View more',
    NAVIGATION_BACK: 'Back',
    CHANGE_PASS_CURRENT: 'Current password',
    CHANGE_PASS_NEW: 'New password',
    CHANGE_PASS_CONFIRMATION: 'Your password has been successfully changed.',
    PASSWORD: 'Password',
    SETTINGS: 'Settings',
    SETTINGS_DEVELOPMENT_TOGGLE_LABEL: 'Developer Version',
    NOTIFICATION_ENGAGEMENT_TITLE_1: 'We miss you!',
    NOTIFICATION_ENGAGEMENT_TITLE_2: 'Where have you been?',
    NOTIFICATION_ENGAGEMENT_TITLE_3: 'Take care of yourself!',
    NOTIFICATION_ENGAGEMENT_PHRASE_1: 'We have new exciting challenges!',
    NOTIFICATION_ENGAGEMENT_PHRASE_2: 'Today prize: Your health',
    NOTIFICATION_ENGAGEMENT_PHRASE_3: 'Are you curious about your health?',
    APPOINTMENT_TELECONSULTATION: 'Video consultation',
    REGISTER_AGREE_TO: 'Agree to',
    'RECOMMENDATIONS.CHECKUP_MANDATORY_TELECONSULTATION.ENTRY_1': 'Arrive on time to avoid delays',
    APPOINTMENT_CANCEL_LINK: 'cancel your appointment.',
    NEXT_BUTTON: 'Next',
    LOADING_FETCHING: 'Fetching content...',
    YES_BUTTON: 'Yes',
    NO_BUTTON: 'No',
    SCHEDULE_DATE_TITLE: 'Appointment date',
    SCHEDULE_QUESTIONS_TITLE: 'Basic questionnaire',
    SCHEDULE_QUESTIONS_DESCRIPTION:
      'In order to improve your consultation and checkup, we have prepared a few sets of questions for you. Please answer them before your appointment to personalize your experience.',
    SCHEDULE_WELCOME_BUTTON: 'Available dates',
    SCHEDULE_WELCOME_BUTTON_NODATE: 'No available date',
    SIGN_UP_FORM_EMAIL: 'What is your email?',
    VERIFICATION_CODE_FORM_CODE: 'Voucher',
    SCHEDULE_USERDATA_TITLE: 'About you',
    SCHEDULE_USERDATA_DESCRIPTION:
      'Your checkup is personal and your results are unique. In order to achieve that, we need to know more about you.',
    BUTTON_NEXT_STEP: 'Next Step',
    SCHEDULE_WAITING_RECOMMENDATION: 'Recommendations',
    SCHEDULE_WAITING_RECOMMENDATION_TIME: 'Arrive on time to avoid delays',
    SCHEDULE_WAITING_TITLE: 'Your appointment',
    SCHEDULE_WAITING_RECOMMENDATION_CLOTHES:
      'Wear something comfortable, no tights or no thick sleeves',
    SCHEDULE_WAITING_RECOMMENDATION_EAT: 'Do not eat or drink 3 hours before your appointment',
    SCHEDULE_WAITING_CALENDAR_TITLE: 'wellabe: Checkup Appointment',
    POLICY_TITLE: 'Our Policy',
    APPOINTMENT_TODAY_TELECONSULTATION: 'Video consultation',
    PLEASE_WAIT: 'Please wait...',
    'RECOMMENDATIONS.CHECKUP_MANDATORY_TELECONSULTATION.ENTRY_2':
      'Wear something comfortable, no tights or no thick sleeves',
    CHALLENGE_TIPS_HEADER_DUPLICATED_1: 'Tips',
    LOGOUT_ALERT_MESSAGE:
      'Are you sure you want to logout? You can come back to this page logging back in.',
    APPOINTMENT_UPCOMING_TELECONSULTATION: 'Video consultation',
    ERROR_QUESTIONS_EMPTY_SINGLE: 'Please, select one of the answers',
    ERROR_QUESTIONS_EMPTY_MULTIPLE: 'Please, select one or more answers',
    ERROR_QUESTIONS_EMPTY_NUMBER: 'Please, type a number as an answer',
    ERROR_QUESTIONS_EMPTY_STRING: 'Please, input an answer before submitting',
    ERROR_QUESTIONS_EMPTY_BOOLEAN: 'Please, select yes or no before submitting',
    ERROR_QUESTIONS_EMPTY_TIME: 'Please, select a time before submitting',
    ERROR_QUESTIONS_EMPTY_DATE: 'Please, select a date before submitting',
    SCHEDULE_RESULTS_LOADING: 'Your results are ready! We are setting everything up for you.',
    SETTINGS_POLICY_TERMS_TITLE: 'Policy Terms',
    SETTINGS_PRIVACY_STATEMENT_TITLE: 'Data Privacy Statement',
    SETTINGS_IMPRINT_TITLE: 'Imprint',
    SCHEDULE_TIME_INDICATION: 'At',
    SETTINGS_CHANGE_PASSWORD: 'Change password',
    SCHEDULE_REVIEW_CONFIRM_BUTTON: 'Confirm',
    REGISTER_AGREE_SENTENCE: 'By registering in you agree to our',
    PRIVACY_POLICY: 'Privacy policy',
    TERMS_OF_SERVICE: 'Terms of service',
    THIS_OR_THAT: 'or',
    SCHEDULE_APPOINTMENT_SELECT_DATE: 'Select date',
    SCHEDULE_CONFIRM_TITLE: 'Confirm your appointment?',
    VOUCHER_INFO_TITLE: 'What is a voucher?',
    VOUCHER_INFO_DESCRIPTION:
      'The voucher is the code code that gives you permission to schedule an appointment with wellabe. This code is distributed by your company or you can acquire one by contacting us. ',
    SCHEDULE_VIEW_APPOINTMENT_BUTTON: 'My Appointment',
    SCHEDULE_ANSWER_LATER_BUTTON: 'Answer Later',
    SIGN_UP_FORM_PASSWORD: 'Enter your password',
    SIGN_UP_FORGOT_PASSWORD: 'Forgot password / ID',
    PASSWORD_HIDE: 'hide',
    PASSWORD_SHOW: 'show',
    SCHEDULE_QUESTIONS_BUTTON_START: "Ok, Let's go!",
    FORM_SEND: 'Send',
    FORM_ENTER: 'Enter',
    SCHEDULE_EDIT_APPOINTMENT: 'Edit my appointment',
    SCHEDULE_RECOMMENDATIONS_TITLE: 'Tips for your appointment',
    SETTINGS_DOCS_TITLE: 'About wellabe',
    NOTIFICATION_CHALLENGE_AVAILABLE_TITLE: 'Time to start a new challenge!',
    APPOINTMENT_UPCOMING_TIME_DESCRIPTION:
      'Your video consultation is at {{time}}. Join through the link below',
    APPOINTMENT_UPCOMING_AVAILABLE_LINK:
      'Your consultation link will be available on the day of the event.',
    CHECKUP_RISK_FLAGGED:
      'We have identified some risks in your checkup and we recommend you see a doctor as soon as possible.',
    'RECOMMENDATIONS.CHECKUP_MANDATORY_TELECONSULTATION.ENTRY_3':
      'For the best check-up results, do not eat or drink 3 hours before your appointment',
    TELECONSULTATION_ICS_SUMMARY: 'wellabe video consultation',
    CHECKUP_RISK_SUBMIT: 'I understood',
    OPT_OUT_EMAIL_TITLE: 'Should I save my e-mail?',
    OPT_OUT_EMAIL_DESCRIPTION:
      'When choosing not to save your e-mail, we will not save it in our database and we will provide another way for you to log in. This is relevant if you do not wish to have your e-mail related to your checkup data. ',
    NEWS_MINUTES_READ_LABEL: 'minutes read',
    SETTINGS_FEEDBACK_BUTTON: 'Send feedback',
    SETTINGS_FEEDBACK_CONFIRMATION_MESSAGE: 'Thank you for submiting!',
    REGISTER_TERMS_AND_CONDITIONS: 'Terms & Conditions',
    QUESTIONS_BUTTON_GOT_IT: 'Got it',
    BIOMARKER_FOLLOWUP_URGENT_LABEL: 'Have this biomarker URGENTLY controlled !',
    BIOMARKER_FOLLOWUP_LABEL: 'Pay attention to this indicator',
    SETTINGS_FEEDBACK_CARD_BUTTON: 'Send',
    ERROR_FORM_MINLENGTH: '{{0}} should have minimum {{1}} characters',
    ERROR_FORM_EMAIL: 'You should enter a valid e-mail',
    LOADING_UPDATING_APP: 'Your app just updated! We are doing some final improvements for you.',
    BIOMARKER_ATE_RECENTLY_LABEL: 'This value might be high because you ate prior to your checkup',
    ERROR_UNKNOWN_MESSAGE:
      'Something wrong happened. Please try again soon, we are working on it. ',
    APPOINTMENT_DETAILS_MISSED: 'Missed',
    BIOMARKER_EXERCISE_RECENTLY_LABEL:
      'This value might be high because you exercised prior to your checkup',
    SETTINGS_FORCE_UPDATE_LOADING: 'Updating your data. Should take just a few seconds ...',
    SETTINGS_FORCE_UPDATE_BUTTON: 'Refresh data',
    APPOINTMENT_UNAVAILABLE_HEADER: 'No appointment available',
    'RECOMMENDATIONS.CHECKUP_MANDATORY_TELECONSULTATION.ENTRY_4':
      'Join your consultation from a quiet room via a laptop or computer with a microphone using the latest Chrome, Firefox or Safari version',
    CHECKUP_ICS_SUMMARY: 'wellabe Check-up',
    ERROR_FORCE_LOGOUT_MESSAGE: 'You have been logged out',
    SETTINGS_OTHER_TITLE: 'Other',
    SETTINGS_OTHER_LOGOUT_ALL: 'Logout from all devices',
    SETTINGS_OTHER_LOGOUT: 'Logout',
    FORM_LOWERCASE_NUMBERS_ONLY: '{{0}} should use only lowercase characters and numbers',
    BIOMARKER_GO_TO_CHALLENGE_BUTTON: 'Start improving now!',
    RESULTS_OVERALL_BODYAGE_DESCRIPTION: 'is your estimated BodyAge based on your results',
    RESULTS_OVERALL_BODYAGE_HEADER: 'Your overall Bodyage',
    RESULTS_GROUP_BODYAGE_HEADER: 'Body Groups',
    RESULTS_BIOMARKER_HEADER: 'Biomarkers',
    RESULTS_UNAVAILABLE:
      ' Your results are still being processed. Your readings will be available here as soon as your result is available.',
    RESULTS_GROUP_READ_MORE: 'About',
    BIOMARKER_COMPARE_RESULTS_TITLE: 'Your last results',
    CHALLENGES_LOG_BUTTON: 'Log Challenge',
    RESULTS_GROUP_COMPARE_HEADER: 'Compare results',
    RESULTS_GROUP_DESCRIPTION_HEADER: 'What does my result mean?',
    PROFILE_INTRO_PHRASE: 'what can we do for your health today?',
    ACTIVITIES_PAGE_NAME: 'Activities',
    ACTIVITIES_NEW_CATEGORY_LABEL: 'New',
    ACTIVITIES_COMPLETED_CATEGORY_LABEL: 'Completed',
    CHALLENGE_LOG_CARD_COMPLETED_LABEL_DATE: 'Completed on',
    CHALLENGES_SETTINGS_REMINDERS_HEADER: 'Set Reminders',
    RESULTS_RANGE_READING_LABEL: 'Reading',
    ACTIVITIES_ARTICLES_READ_TITLE: 'Articles read',
    ACTIVITIES_QUESTIONNAIRE_TITLE: 'Questions',
    ACTIVITIES_RECOMMENDED_ARTICLES_TITLE: 'Recommended Articles',
    DOCTOR_CONSULTATION_BUTTON: 'Online consultation',
    CHALLENGE_DISSMISS_ALERT_TITLE: 'Dismiss challenge',
    CHALLENGE_DISMISS_ALERT_DESCRIPTION:
      'Do you want to remove this challenge from the recommendations list?',
    DOCTOR_APPOINTMENT_NOTIFICATION_TITLE: 'Online consultation: Get ready!',
    DOCTOR_APPOINTMENT_NOTIFICATION_TEXT: 'A doctor will contact you in 15 minutes!',
    RESULTS_INFO_READING_POSITIVE_TITLE: 'Positive reading',
    RESULTS_INFO_READING_POSITIVE_DESCRIPTION:
      'Your reading is in the positive range, congratulations!',
    RESULTS_INFO_READING_INFORMATIVE_TITLE: 'Informative',
    RESULTS_INFO_READING_INFORMATIVE_DESCRIPTION:
      'This reading alone can not be classified and there are no good or bad ranges.',
    RESULTS_INFO_READING_CAREFUL_TITLE: 'Careful',
    RESULTS_INFO_READING_CAREFUL_DESCRIPTION:
      'Your value is outside the positive range and should be treated careful. Read more information inside each biomarker and start challenges to start improving.',
    RESULTS_INFO_READING_ATTENTION_TITLE: 'Attention',
    RESULTS_INFO_READING_ATTENTION_DESCRIPTION:
      'This reading requires attention and we highly recommend changing your lifestyle in order to improve this result. Please take your challenges seriously and read more information on our daily articles. ',
    APPOINTMENT_DETAILS_PERFORMED: 'Past',
    REGISTER_WITH_EMAIL_LI_1_DUPLICATE:
      'Your e-mail is used to recover your password and remind you about your appointment.',
    APPOINTMENT_UNAVAILABLE: 'There is no appointment available at your location at the moment.',
    'CANCEL_APPOINTMENT.CONFIRM_MESSAGE':
      'After cancelling your appointment, you will be able to book another appointment. Your current appointment time will be available for anyone to book.',
    ONBOARDING_RESULTS_TITLE: 'Results',
    ONBOARDING_RESULTS_SLIDE_1_TITLE: 'Personalized for you',
    ONBOARDING_RESULTS_SLIDE_1_DESCRIPTION:
      'Your new app is personalized just for you and your readings. ',
    ONBOARDING_RESULTS_SLIDE_2_TITLE: 'Change your Lifestyle',
    ONBOARDING_RESULTS_SLIDE_2_DESCRIPTION:
      'Engage in different activities to improve your health. All activities are suggested based on your health profile.',
    ONBOARDING_RESULTS_SLIDE_3_TITLE: 'Simple and intuitive',
    ONBOARDING_RESULTS_SLIDE_3_DESCRIPTION:
      'View all your readings and understand what every value means. Compare your checkups and see your improvement!',
    ONBOARDING_RESULTS_DONE_BUTTON: "I'm ready",
    REGISTER_EMAIL_PLACEHOLDER_DUPLICATED_1: 'Insert your e-mail',
    REGISTER_NEXT_BUTTON_DUPLICATED_1: 'Next',
    REGISTER_NEXT_BUTTON_DUPLICATED_2: 'Next',
    CHALLENGE_LOG_DAY_LABEL: 'Day',
    ACTIVITIES_QUESTIONNAIRE_SUB_HEADER:
      'These questions help us improve your recommendations by understanding you better. Some even unlock biomarkers in the results section!',
    ERROR_TIMEOUT: 'This is taking too long... Please check your internet connection!',
    FORM_PASSWORD_REQUIREMENTS: '{{0}} should have at least one number and one letter',
    CHANGE_PASSWORD_BUTTON: 'Update password',
    CLOSE_BUTTON: 'Close',
    BODYAGE_DESCRIPTION_PAGE_1_TITLE: 'The BodyAge',
    BODYAGE_DESCRIPTION_PAGE_1_CONTENT: 'Your BodyAge was calculated based on your readings',
    BODYAGE_DESCRIPTION_PAGE_2_CONTENT:
      'As you age, many values change and "get older" with you, this means that it is quite normal for a 60 years old to have a higher blood pressure and lower lung volume than a 30 years old.These measurements thus reflect the biological age.',
    BODYAGE_DESCRIPTION_PAGE_3_CONTENT:
      'Based on the values collected by WELLABE, your average age was estimated, also taking into account your physical measurements and gender. This age should serve as a comparison.',
    BODYAGE_DESCRIPTION_PAGE_4_CONTENT:
      'Therefore, a higher body age does not mean that something is wrong with your health. A person a few years older than you, of course, can be in shape and healthy. For example, it is possible that your lung age higher than your real age, even though all readings are good, or showing up in the green range. Your lungs are healthy, but more like a slightly older person.',
    BODYAGE_DESCRIPTION_PAGE_5_CONTENT:
      'Red colour on individual readings, on the other hand, shows the need for action. Pay attention carefully to these values!',
    BODYAGE_DESCRIPTION_PAGE_6_CONTENT:
      "BodyAge alone shouldn't be a cause for concern. It aims to motivate you to do a lot more for a healthier lifestyle and serve as a reference for future measurements. You can use it to compare if your lifestyle has become healthier over time.",
    BODYAGE_DESCRIPTION_PAGE_7_CONTENT:
      'By the way, with WELLABE, you\'re not being compared with the "average person", but with a few thousand users who went through this check before you. This way the BodyAge is calculated based on people like you and your colleagues!',
    ACTIVITY_COMPLETED_TOOLTIP: 'All your completed questions, challenges and articles are here!',
    SETTINGS_EMAIL_OPTIN: 'Enter e-mail address',
    CHECKUP_ICS_DESCRIPTION:
      'Arrive on time to avoid delays, wear something comfortable (no tights or no thick sleeves) and, for the best results, do not eat or drink 3 hours before your appointment.',
    EMAIL_VERIFICATION_UPDATE_CURRENT: 'Your current e-mail address:',
    EMAIL_VERIFICATION_UPDATE_MESSAGE:
      'Enter a different e-mail address to receive your verification code. Your current email address is {{email}}',
    EMAIL_VERIFICATION_UPDATE_SUCCESS: 'E-mail updated, please check your e-mail',
    EMAIL_VERIFICATION_UPDATE_BUTTON: 'Update',
    EMAIL_VERIFICATION_UPDATE_CTA: 'update your e-mail',
    REGISTER_EMAIL_PLACEHOLDER: 'Enter your e-mail',
    EMAIL_VERIFICATION_CTA: 'Verify',
    EMAIL_VERIFICATION_RESEND_CTA: 'request a new one',
    EMAIL_VERIFICATION_DESCRIPTION:
      'We just sent an e-mail with a confirmation code to {{email}}. It may be on your spam folder.',
    EMAIL_VERIFICATION_CODE: 'Enter verification code',
    EMAIL_VERIFICATION_RESEND_SUCCESS: 'Email sent to',
    REGISTER_SUBMIT_BUTTON: 'Register',
    REGISTER_PRINT_BUTTON: 'PRINT SCREEN',
    REGISTER_TAKING_SCREEN_BUTTON: 'Taking screenshot',
    REGISTER_PASSWORD_LABEL: 'Password',
    REGISTER_TOKEN_DESCRIPTION: 'For your own privacy, we generated a unique access token for you.',
    REGISTER_DECIDE_WITHOUT_EMAIL: 'Register without e-mail',
    REGISTER_DECIDE_WITH_EMAIL: 'Register with e-mail',
    REGISTER_DECIDE_EMAIL_TITLE: "It's up to you!",
    REGISTER_WITHOUT_EMAIL_LI_3: 'If you loose or forget your token, your account will be lost',
    REGISTER_WITHOUT_EMAIL_LI_2:
      'You will receive a random token and password that you can use to login',
    REGISTER_WITHOUT_EMAIL_LI_1: 'You will receive a random login token and password',
    REGISTER_WITHOUT_EMAIL_TITLE: '...WITHOUT E-MAIL',
    REGISTER_WITH_EMAIL_LI_3: 'We only send emails related to you and your health',
    REGISTER_WITH_EMAIL_LI_2: 'Receive checkup results and reminders for your appointments',
    REGISTER_WITH_EMAIL_TITLE: 'Registering with your e-mail',
    REGISTER_NEXT_BUTTON: 'Next',
    REGISTER_VOUCHER_FORM_ENTER: 'Enter your voucher',
    SIGN_UP_NEW_ACCOUNT_CTA: 'Register now',
    SIGN_UP_NEW_ACCOUNT: 'New Account?',
    SETTINGS_FEEDBACK_PLACEHOLDER:
      'Here you can tell us your opinion or send us suggestions to improve:',
    FEEDBACK_CARD_TITLE: 'How many stars would you give us?',
    CHALLENGES_LOG_BUTTON_LABEL_ACTIVE: 'Check-in today',
    CHALLENGES_LOG_BUTTON_LABEL_DISABLED: 'See you tomorrow!',
    CHALLENGE_TIPS_HEADER: 'Tips and Info',
    CHALLENGE_DESCRIPTION_HEADER: 'About this challenge',
    CHALLENGE_GOALS_HEADER: 'Unlock these improvements',
    CHALLENGE_HISTORY_HEADER: 'Challenge History',
    CHALLENGE_SETTINGS_NOTIFICATION_HEADER: 'Set up your notifications',
    CHALLENGE_SETTINGS_DAYS_HEADER: 'Set up the days:',
    CHALLENGE_SETTINGS_SAVE_BUTTON: 'Save',
    CHALLENGE_LOG_HISTORY_BUTTON: 'Full History',
    QUESTIONNAIRE_FINAL_TEXT:
      'Thank you for answering this questionnaire. Your answers will help us improve your recommendations.',
    QUESTIONNAIRE_KNOW_MORE_BUTTON: 'Know More',
    QUESTIONNAIRE_FINISH_BUTTON: 'Finish',
    QUESTIONNAIRE_COMPLETED_AT_TEXT: 'You answered this at ',
    CHALLENGE_NOTIFICATION_ADD_TIME_LABEL: 'New',
    DAY_LABEL: 'day',
    CHALLENGE_DAY_LEFT: 'day left',
    REGISTER_SCREENSHOT_CAPTURED: 'Screenshot saved to gallery',
    REGISTER_SCREENSHOT_FAILED: 'Failed to take a screenshot, please write down your credentials. ',
    SIGN_IN_FORM_BL_ID: 'wellabe ID / email',
    APPOINTMENT_DETAILS_PENDING: 'Pending',
    'SCHEDULE_PAGE.SELECT_CHECKUP.TITLE': 'Check-up date',
    FORGOT_PASSWORD_TITLE: 'Forgot your password or ID?',
    EMAIL_VERIFICATION_TITLE: 'Verification Code',
    'LOADING.PLEASE_WAIT': 'Please wait...',
    APPOINTMENT_BOOK_TITLE: 'Book an appointment',
    ACTIVITIES_COMPLETED_QUESTIONNAIRE_TITLE: 'Completed questions',
    CHALLENGE_BUTTON_RESTART: 'Do it one more time!',
    TIMELINE_TAB_TOOLTIP:
      'This is the activities page, where you will find journeys and recommended articles to help improve your health.',
    RESULTS_TAB_TOOLTIP: 'Here you will find your latest results and their comparison.',
    TOOLTIP_TAP_TO_DISMISS: 'Tap to dismiss',
    ACTIVITIES_ARTICLES_CARD_TIP:
      'These articles provide in-depth information about your health profile.',
    CHALLENGE_ONBOARDING_TITLE: 'Challenge tips',
    SETTINGS_COPIED_ID_TOAST: 'wellabe ID copied to clipboard',
    APPOINTMENTS_DETAILS: 'Appointment Details',
    'SCHEDULE_PAGE.SELECT_CHECKUP.DESCRIPTION':
      'Choose a date and time for your in-person health check-up.',
    'GENERAL.DISMISS_BUTTON': 'Dismiss',
    APPOINTMENT_BOOK_DESCRIPTION:
      'Schedule your wellabe check-up or video consultation right from your phone.',
    APPOINTMENTS_DETAILS_DOCTOR: 'Doctor',
    'SCHEDULE_PAGE.SELECT_TELE.TITLE': 'Video consultation',
    'CANCEL_APPOINTMENT.ERROR_HEADER': 'Cancellation not possible',
    APPOINTMENT_BUTTON_START: 'Get started',
    SETTINGS_IMPERSONATE_TITLE: 'Impersonate User',
    SETTINGS_IMPERSONATE_DESCRIPTION:
      "With the wellabe ID, you can access this user's app profile.",
    RESULTS_BIOMARKER_LIST_TIP:
      'Here you will find your health values. You can view detailed information about each biomarker by tapping on its name. ',
    CONTACT_US_BUTTON: 'Need help? Contact us!',
    APPOINTMENT_TAB_PAGE_NAME: 'Appointments',
    'SCHEDULE_PAGE.SELECT_TELE.DESCRIPTION': 'Choose a date and time for the video consultation.',
    APPOINTMENT_UPCOMING_CHECKUP: 'Check-up',
    APPOINTMENT_EDIT_MODAL_TELECONSULTATION_TITLE: 'Video consulation',
    APP_RATING_QUESTION_1: 'Are you enjoying wellabe?',
    APP_RATING_QUESTION_NEGATIVE: 'Would you mind giving us some feedback?',
    APP_RATING_QUESTION_POSITIVE_IOS: 'Would you mind rating us on the App Store?',
    APP_RATING_QUESTION_POSITIVE_ANDROID: 'Would you mind rating us on Google Play Store?',
    APP_RATING_NOT_ENJOYING_BUTTON: 'Not really',
    APP_RATING_YES_FEEDBACK_BUTTON: 'Ok, sure!',
    PROFILE_ONGOING_JOURNEYS: 'Your Journeys',
    X_OF_Y_CHALLENGES: 'of',
    JOURNEY_MAIN_HEADER: 'This journey',
    JOURNEY_CHALLENGES_HEADER: 'Journey challenges',
    JOURNEY_QUIT_BUTTON: 'Quit Journey',
    TOTAL_CHALLENGES: 'challenges',
    ACTIVITIES_RECOMMENDED_JOURNEYS: 'Recommended Journeys',
    ACTIVITIES_RECOMMENDED_JOURNEY_CARD_TIP:
      'These journeys were selected for you based on your health profile. Tap to read more about them and start on your way to better health!',
    PROFILE_JOURNEY_EMPTY: "You haven't started a journey yet! What are you waiting for?",
    NO_ACTIVE_JOURNEY_CARD_CTA: 'View journeys',
    BIOMARKER_INTENSE_EXERCISE_LABEL:
      'This value might be elevated due to a recent intense exercise',
    JOURNEY_QUIT_ALERT_HEADER: 'Do you want to quit?',
    JOURNEY_QUIT_ALERT_MESSAGE:
      "When you quit this Journey, all your progress will be lost. If you don't like a challenge inside a journey, you can dismiss it at the bottom of the challenge page. ",
    ACTIVITIES_COMPLETED_JOURNEYS_TITLE: 'Completed Journeys',
    ACTIVITES_COMPLETED_EMPTY_JOURNEYS:
      'You have not completed a journey yet. Check your recommended journeys to start one now!',
    'WEEKLY_PLAN.TELECONSULTATION_LINK': 'Video consultation via Teleapp',
    'SCHEDULE_PAGE.SELECT_CHECKUP.TELECONSULTATION_DATES_UNAVAILABLE':
      'There is no available video consultation appointment after the selected checkup date. Please select another checkup date.',
    WEEKLY_PLAN_EMPTY:
      "You don't have any ongoing challenges. Start a new challenge to view your Weekly Plan!",
    PROFILE_TAB_WEEKLY_PLAN: 'Weekly Plan',
    WEEKLY_PLAN_SELECTOR_WEEK_LABEL: 'WEEK',
    WEEKLY_PLAN_CHECK_IN: 'Check-in',
    RESULTS_SHARE_PDF_TITLE: 'Share results via e-mail!',
    RESULTS_SHARE_PDF_DESCRIPTION:
      'Enter an e-mail address below to get a PDF with your results. You can enter any e-mail address that you would like to share. This e-mail will not be stored. ',
    RESULTS_SHARE_PDF_EMAIL_PLACEHOLDER: 'Enter e-mail address',
    RESULT_SHARE_PDF_SUBMIT_BUTTON: 'Send PDF',
    RESULTS_SHARE_PDF_SUCCESS_MESSAGE: 'Your results were sent to your e-mail',
    DELETE_ACCOUNT_PAGE_TITLE: 'Delete account',
    DELETE_ACCOUNT_BEFORE_TITLE: 'We value your privacy',
    DELETE_ACCOUNT_BEFORE_DESCRIPTION:
      'At wellabe your privacy is our priority, that is why we store your data anonymously on our database. If you still want to delete your account, consider the following:',
    DELETE_ACCOUNT_BEFORE_LIST_ITEM_1:
      'Deleting your account is permanent. All your data will be lost and it is not possible to recover;',
    DELETE_ACCOUNT_BEFORE_LIST_ITEM_2: 'We will process your request in up to 72 hours;',
    DELETE_ACCOUNT_BEFORE_LIST_ITEM_3:
      "While we haven't processed your request, you can cancel it and keep your account;",
    DELETE_ACCOUNT_BEFORE_LIST_ITEM_4:
      'After your account has been deleted, you will be automatically logged out.',
    DELETE_ACCOUNT_AFTER_TITLE: 'We are processing your request',
    DELETE_ACCOUNT_AFTER_DESCRIPTION:
      'You will be logged out when we complete deleting all your data, this process can take up to 72 hours. You can cancel this process on the button bellow.',
    DELETE_ACCOUNT_CANCEL_TITLE: 'Your account is safe!',
    DELETE_ACCOUNT_CANCEL_DESCRIPTION:
      'Thanks for choosing to keep your wellabe account, we really appreciate your decision! If you would like to send us some feedback, you can reach us any time at hallo@wellabe.de',
    INPUT_LABEL_PASSWORD_CONTINUE: 'Enter your password to continue:',
    DELETE_ACCOUNT_BEFORE_BUTTON: 'Permanently Delete My Account',
    DELETE_ACCOUNT_AFTER_BUTTON: "Don't Delete My Account",
    REQUEST_DATA_PAGE_TITLE: 'Download My Data',
    REQUEST_DATA_PAGE_HEADER: 'Get a copy of your wellabe data',
    REQUEST_DATA_PAGE_SUCCESS_HEADER: 'Your data is being sent!',
    REQUEST_DATA_PAGE_DESCRIPTION:
      'At any time you can request a copy of all your wellabe data. This includes all your results, journeys, challenges, articles, questionnaires and appointment date. Enter your e-mail address to receive a copy of your data.',
    INPUT_LABEL_EMAIL_PASSWORD_CONTINUE: 'Enter your e-mail and password to continue:',
    REQUEST_DATA_PAGE_SUBMIT_BUTTON: 'Get My Data',
    REQUEST_DATA_PAGE_SUCCESS_MESSAGE:
      'Your data is beeing processed and will be sent to your e-mail soon.',
    REQUEST_DATA_PAGE_EMAIL_FIELD: 'Your e-mail',
    REVIEW_ANALYTICS_PAGE_TITLE: 'Review Analytics',
    REVIEW_ANALYTICS_PAGE_HEADER: 'What is Analytics?',
    REVIEW_ANALYTICS_PAGE_DESCRIPTION:
      'On the wellabe app we collect anonymous data and use it to improve our app. We mainly use this data to know what challenges and articles you like to read and also understand what areas of the app needs more improvements. The data used on this analytics is completely anonymous and GDPR compliant. You can turn the analytics on or off at any time by using the toggle below.',
    REVIEW_ANALYTICS_TOGGLE_LABLE: 'Allow Analytics',
    REVIEW_EMAIL_PAGE_TITLE: 'Review E-mail',
    REVIEW_EMAIL_DELETE_HEADER: 'Delete your e-mail',
    REVIEW_EMAIL_DELETE_DESCRIPTION:
      'At wellabe your privacy is our priority, that is why we store your data anonymously in our database, including your e-mail. You can choose to remove your e-mail from your account, but you will loose a few features:',
    REVIEW_EMAIL_DELETE_BUTTON: 'Delete My E-mail',
    REVIEW_EMAIL_DELETE_LIST_ITEM_1:
      'You will not be able to recover your password if you loose it;',
    REVIEW_EMAIL_DELETE_LIST_ITEM_2: 'Login will only be possible using your wellabe ID;',
    REVIEW_EMAIL_DELETE_LIST_ITEM_3:
      'You will not receive important information or news from wellabe;',
    REVIEW_EMAIL_DELETE_LIST_ITEM_4:
      'Your might receive an e-mail, even after deleting it, that was already scheduled for you.',
    REVIEW_EMAIL_REGISTER_HEADER: 'Register your e-mail',
    REVIEW_EMAIL_REGISTER_DESCRIPTION:
      'At wellabe your privacy is our priority, that is why we store your data anonymously in our database, including your e-mail. By register your e-mail, you will improve your wellabe experience:',
    REVIEW_EMAIL_REGISTER_LIST_ITEM_1: 'You will be able to recover your password by e-mail;',
    REVIEW_EMAIL_REGISTER_LIST_ITEM_2: 'You will receive important information about your results;',
    REVIEW_EMAIL_REGISTER_LIST_ITEM_3: 'We will let you know of any offer or news from wellabe.',
    REVIEW_EMAIL_REGISTER_INPUT_LABEL: 'What e-mail would you like to use?',
    REVIEW_EMAIL_REGISTER_BUTTON: 'Register e-mail',
    REVIEW_EMAIL_VERIFY_HEADER: 'Verify your e-mail',
    REVIEW_EMAIL_VERIFY_DESCRIPTION:
      'We sent you a 6 digits code to your e-mail. Please check your e-mail and enter the code below to verify your account!\r\n',
    REVIEW_EMAIL_VERIFY_INPUT_LABEL: 'Enter the verification code below',
    REVIEW_EMAIL_VERIFY_BUTTON: 'Verify E-mail',
    REVIEW_EMAIL_UPDATE_HEADER: 'Update your e-mail',
    REVIEW_EMAIL_UPDATE_DESCRIPTION:
      'You can update your e-mail below, we will send a new verification code to this e-mail.',
    REVIEW_EMAIL_VERIFIED_HEADER: 'E-mail verified',
    REVIEW_EMAIL_VERIFIED_DESCRIPTION:
      'Thank you for verifying your e-mail. You can now enjoy all the benefits of wellabe, without ever missing out!',
    REVIEW_EMAIL_DELETED_HEADER: 'E-mail deleted',
    REVIEW_EMAIL_DELETED_DESCRIPTION:
      'Your e-mail was deleted from our database. You might still receive e-mails that were previously scheduled to be sent soon. If you change your mind, you can register your e-mail on the button below.',
    REVIEW_EMAIL_DELETED_BUTTON: 'Register my e-mail',
    SETTINGS_EMAIL_OPTOUT: 'Delete e-mail',
    REVIEW_EMAIL_VERIFY_UPDATE_EMAIL: 'Change e-mail',
    REVIEW_EMAIL_VERIFY_RESEND_BUTTON: 'Resend code',
    SETTINGS_PRIVACY_TITLE: 'Privacy',
    DIFFICULTY_LABEL_EASY: 'Easy',
    DIFFICULTY_LABEL_NORMAL: 'Normal',
    DIFFICULTY_LABEL_HARD: 'Hard',
    CHALLENGE_DISMISS_BUTTON: 'Hide Challenge',
    RESULTS_SPONSORED_BY: 'Brought to you by',
    CHALLENGE_COMPLETED_MODAL_BUTTON_DISMISS: 'Maybe later!',
    CHALLENGE_COMPLETED_MODAL_TITLE: 'Way to go!',
    CHALLENGE_COMPLETED_MODAL_DESCRIPTION:
      'Continue improving your health by starting your next challenge! Keep going!',
    CHALLENGE_COMPLETED_MODAL_BUTTON_CONTINUE: "Let's do it!",
    CHALLENGE_COMPLETED_MODAL_BUTTON_NO_CHALLENGE: 'Great!',
    CHALLENGE_COMPLETED_MODAL_DESCRIPTION_NO_CHALLENGE:
      'You are almost there! Finish your ongoing challenges to complete your Journey!',
    SCHEDULE_NODATE_ON_WAITING_LIST:
      "You have been added to our waiting list and we'll notify you once new appointments are available.",
    'SCHEDULE_PAGE.APPOINTMENT_UNAVAILABLE.CHECKUP':
      'The selected check-up appointment is no longer available.',
    JOURNEY_TOOLTIP_CHALLENGES:
      'These are the challenges of this Journey. Starting a challenge will automatically start the Journey!',
    CHALLENGE_TOOLTIP_DIFFICULTY:
      'Changing the difficulty will increase or reduce the duration of this challenge!',
    CHALLENGE_TOOLTIP_REMINDER:
      "Get notified daily so you don't forget to track this challenge's progress.",
    CHALLENGE_TOOLTIP_DISMISS:
      "Can't do this challenge? You can dismiss this challenge and start the next one!",
    WEEKLY_PLAN_EMPTY_CTA: "Let's do it!",
    JOURNEY_COMPLETED_MODAL_TITLE: 'You just completed a Journey! Super!',
    JOURNEY_COMPLETED_MODAL_DESCRIPTION:
      'You can start a new one or just take a break for a couple of days to relax. Journeys are meant to be done regularly, at your own pace. Keep improving your health!',
    JOURNEY_COMPLETED_MODAL_BUTTON_DISMISS: 'Great, thanks!',
    SETTINGS_RESET_TOOLTIPS_SUCCESS: 'Tooltips were enabled again in the app!',
    SETTINGS_RESTART_TOOLTIPS_BUTTON: 'Reset tooltips',
    TIMELINE_WEEKLY_TOOLTIP: 'You can view your Ongoing Journeys on your Weekly Plan!',
    JOURNEY_ONGOING_CHALLENGE_LOCKED: 'Start the journey to import this challenge progress',
    CHALLENGE_QUIT_MESSAGE:
      'By quitting the challenge you will lose your complete progress and you will have to start over. Are you sure you want to quit?',
    CHALLENGE_LOG_BUTTON_SINGLE_EDIT: 'Save',
    CHALLENGE_LOG_BUTTON_SINGLE: 'Submit Value',
    CHALLENGE_LOG_BUTTON_INCREMENTAL: 'Add Value',
    CHALLENGE_LOG_MODAL_SINGLE_LABEL: 'Log single value',
    CHALLENGE_LOG_MODAL_INCREMENTAL_LABEL: 'Log your Progress',
    CHALLENGE_LOG_MODAL_INCREMENTAL_EDIT_TITLE: 'Total value',
    CHALLENGE_LOG_MODAL_SINGLE_EDIT_TITLE: 'Submitted Value',
    CHALLENGE_LOG_MODAL_BUTTON_SUBMITTED: 'Submitted',
    CHALLENGE_ADVANCED_LOG_INCREMENTAL_VALUE_LEFT: 'left today',
    CHALLENGE_HISTORY_INCREMENTAL_FAILED: 'Almost there!',
    CHALLENGE_HISTORY_INCREMENTAL_SUCCESS: 'Great work!',
    CHALLENGE_START_LABEL: 'Start',
    CHALLENGE_RESTART_LABEL: 'Restart',
    CHALLENGE_HISTORY_QUIT_ON: 'Quit on',
    CHALLENGE_HISTORY_COMPLETED_ON: 'Completed on ',
    CHALLENGE_HISTORY_NO_DATE: 'No date record',
    SCHEDULE_WAITING_LIST_TITLE: "You're now on the waiting list",
    'SCHEDULE_PAGE.APPOINTMENT_UNAVAILABLE.TELECONSULTATION':
      'The selected video consultation appointment is no longer available.',
    SCHEDULE_VOUCHER_DESCRIPTION:
      'To book your appointment, please enter your voucher. The voucher is provided to you by your wellabe service provider.',
    SCHEDULE_VOUCHER_TITLE: 'Enter your voucher ',
    FORM_CODE_INPUT_VERIFYING: 'Verifying, one moment...',
    FORM_CODE_INPUT_INVALID: "The code you've entered is not valid. Make sure you typed correctly.",
    FORM_CODE_INPUT_SUCCESS: 'All good!',
    SCHEDULE_SAFETY_QUESTIONS_TITLE: 'Health Profile',
    SCHEDULE_SAFETY_QUESTIONS_DESCRIPTION:
      'Before booking your appointment, we need to know a little bit about your health. ',
    SCHEDULE_SAFETY_QUESTIONS_BUTTON: 'View Questions',
    SCHEDULE_SAFETY_QUESTIONS_SUCCESS_TITLE: 'Health Profile Updated!',
    SCHEDULE_APPOINTMENT_CONFIRMED: 'Appointment confirmed!',
    SCHEDULE_APPOINTMENT_CONFIRMED_DESCRIPTION_N:
      'Your appointment is confirmed! View a confirmation on your Weekly Plan or go back to your Results.',
    SCHEDULE_APPOINTMENT_CONFIRMED_BACK_BUTTON: 'Back to results',
    SCHEDULE_APPOINTMENT_CONFIRMED_WEEKLY_BUTTON: 'View on Weekly Plan',
    SCHEDULE_PAGE_TITLE: 'Schedule Appointment',
    QUESTIONS_X_OF_Y: 'Question',
    EMAIL_VERIFICATION_SENDING_CODE: 'Sending new code...',
    SCHEDULE_USERDATA_DESCRIPTION_EXTRA:
      'The following information is necessary to create your profile and allow us to understand your results.',
    SCHEDULE_NODATE_DESCRIPTION:
      'Unfortunately all appointments were already booked. We hope to have more appointments available soon.',
    SCHEDULE_NODATE_CTA: 'Use another voucher',
    SCHEDULE_LOADING_APPOINTMENTS: 'Loading appointments',
    WEEKLY_PLAN_APPOINTMENT_LABEL: 'wellabe Appointment',
    RESULTS_APPOINTMENT_CONFIRMED: 'You have a confirmed appointment',
    APPOINTMENT_CHANGE_TIME_BUTTON: 'Change appointment time',
    APPOINTMENT_CANCEL_BUTTON: 'Cancel Appointment',
    SCHEDULE_SAFETY_QUESTIONS_SUCCESS_BUTTON_DUPLICATED_1: 'Book an Appointment',
    RESULTS_BOOK_APPOINTMENT: 'Book Appointment',
    SCHEDULE_EDIT_DESCRIPTION: '\u2800',
    APPOINTMENT_CANCEL_WARNING:
      'Are you sure you want to cancel your appointment? You can always book a new appointment later, although we cannot guarantee that there will still be free spots.',
    REGISTER_ACCESS_TOKEN: 'Welcome Aboard',
    REGISTER_GENERATING_CREDENTIALS: 'Generating your secure credentials',
    PROFILE_ERROR_UPDATING_TITLE: 'Oops! Something went wrong',
    PROFILE_ERROR_UPDATING_DESCRIPTION:
      'We were unable to update your profile. Check your internet connection and try again in one moment',
    PROFILE_ERROR_UPDATING_BUTTON: 'Update my Profile',
    PROFILE_UPDATING_TITLE: 'Updating my profile',
    PROFILE_UPDATING_DESCRIPTION: 'It should take just a couple of seconds...',
    SCHEDULE_QUESTIONS_X_QUESTIONS: 'questions',
    SCHEDULE_LOADING_QUESTIONNAIRES: 'Preparing your basic questionnaires',
    SCHEDULE_WAITING_CALENDAR: 'Add to my calendar',
    SCHEDULE_LOADING_APPOINTMENT: 'Checking appointment details',
    SHCEDULE_WAITING_LIST_DESCRIPTION:
      'We will let you know when more time slots are available. You still can select other dates should you find a perfect time for your appointment.',
    SCHEDULE_SAFETY_QUESTIONS_SUCCESS_BUTTON: 'View available dates',
    'SCHEDULE_PAGE.BOOKING_SUCCESS': 'Appointment confirmed',
    LOADING_COMPONENT_DEFAULT_ERROR:
      'Currently you have no results - answer questionnaires to gain insights into yourself.',
    LOADING_COMPONENT_TRY_AGAIN_BUTTON: 'Try again',
    APPOINTMENT_CANCEL_CLOSE_BUTTON: 'Go back',
    SCHEDULE_EDIT_APPOINTMENT_TITLE: 'Change my appointment',
    SCHEDULE_EDIT_APPOINTMENT_SHORT: 'Change',
    APPOINTMENT_REMOVE_WAITING_MESSAGE:
      "You'll no longer be notified if new appointments are available.",
    SCHEDULE_NODATE_REMOVE_LIST_CTA: 'Do not notify',
    BUTTON_OK_THANK_YOU: 'Ok, thank you!',
    PROFILE_QUESTIONS_TITLE: 'Profile Info',
    PROFILE_QUESTIONS_SUCCESS_TITLE: 'Your profile was updated successfully!',
    PROFILE_QUESTIONS_SUCCESS_DESCRIPTION:
      'Have your voucher ready!  In the next step you will be asked four questions to complete your health profile before being able to book your appointment directly.',
    EDIT_APPOINTMENT_UNAVAILABLE_RELOAD_REQUIRED:
      'Oops, something went wrong. Please try reloading again in a moment. If the error persists, reach out to customer service (089/21 54 70 524).',
    SETTINGS_RESET_ACCOUNT_ALERT_TITLE: 'Reset Account Activity',
    SETTINGS_RESET_ACCOUNT_ALERT_DESCRIPTION:
      'Want to have another fresh start? Resetting your account will remove all your activity, making it the same as the day you got your results. Your challenges, journeys, questionnaires and articles will be removed. Your results will not be affected. To confirm, please input your password on the input below. This cannot be undone. ',
    BUTTON_CONFIRM: 'Confirm',
    SETTINGS_RESET_ACCOUNT_LOADING: 'Resetting your account...',
    SETTINGS_RESET_ACCOUNT_LABEL: 'Reset Account',
    SETTINGS_RESET_ACCOUNT_SUCCESS: 'All done. Enjoy a new fresh start!',
    SCHEDULE_DATE_WAITING_LIST_DESCRIPTION_PART_1: "Couldn't find a good date? ",
    APPOINTMENTS_PAST: 'Past appointments',
    REQUEST_NOTIFICATION_MODAL_TITLE: 'App Notifications',
    REQUEST_NOTIFICATION_MODAL_DESCRIPTION:
      'We need your permission to allow sending notifications to your device. We use notifications to remind you when you have an upcoming checkup and help you keep track of your challenges. ',
    REQUEST_NOTIFICATION_MODAL_ACCEPT: 'Enable Notifications',
    REQUEST_NOTIFICATION_MODAL_DISMISS: 'Maybe later',
    CHALLENGE_LOG_YESTERDAY_LABEL: 'Did you do the challenge yesterday?',
    CHALLENGE_LOG_YESTERDAY_BUTTON: 'Log yesterday',
    APPOINTMENT_JOIN_WAITING_LIST_MESSAGE:
      'All done! We will let you know when more time slots are available.',
    SCHEDULE_DATE_WAITING_LIST_DESCRIPTION:
      'If you want to be notified when more slots are available,',
    SCHEDULE_DATE_WAITING_LIST_CTA: 'Join the waiting list',
    APPOINTMENT_SELECT_DESCRIPTION:
      'Choose your desired appointment date below and confirm at the bottom.',
    WELLABE_TAG_ID: 'wellabe ID: {{wellabeID}}',
    'APPOINTMENT_EDIT_MODAL.CHECKUP_TITLE': 'Reschedule Check-up',
    SCHEDULE_DATE_WAITING_LIST_DESCRIPTION_PART_2:
      " and we'll let you know when new slots become available.",
    'APPOINTMENT_EDIT_MODAL.TELECONSULTATION_DESCRIPTION':
      'Choose a date and time for the video consultation.',
    'WAITING_APPOINTMENTS.TITLE': 'Upcoming Appointments',
    'APPOINTMENT_EDIT_MODAL.SELECT_DATE': 'Reschedule',
    'WAITING_APPOINTMENTS.OPEN_QUESTIONNAIRES': 'Personalize your experience',
    'EDIT_APPOINTMENT.ERROR_HEADER': 'Rescheduling not available',
    'WAITING_APPOINTMENTS.CANCEL_APPOINTMENT': 'Cancel all appointments',
    WELLABE_ID_LABEL: 'wellabe ID',
    REGISTER_WELLABE_ID_DESCRIPTION:
      'We will generate a wellabe ID for you to login. Make sure to keep it safe and sound.',
    REGISTER_WELLABE_ID_HEADER: 'wellabe ID',
    'EDIT_APPOINTMENT.NO_DATE_ERROR': 'There are no more available dates for this appointment.',
    'WAITING_APPOINTMENTS.ERROR_LOADING':
      'Something went wrong while loading your appointments. Tap below to retry.',
    'APPOINTMENT_EDIT_MODAL.TELECONSULTATION_DESCRIPTION_ALTERNATE':
      'Choose a new date and time for the video consultation. Only free slots after your checkup on {{date}} are displayed.',
    SCHEDULE_VOUCHER_PHONE: '089 / 21 54 70 524',
    APPOINTMENTS_ADD_CALENDAR: 'Add to Calendar',
    IMPROVE_NOW_CARD_TAG: 'Journey',
    IMPROVE_NOW_CARD_BUTTON_OPEN: 'View',
    RESULTS_CHECKUP_SUMMARY_HEADER: 'Checkup Summary',
    CHECKUP_REVIEWS_VIEW_MORE: 'View previous checkup summaries',
    CHECKUP_REVIEWS_TITLE: 'Checkup Summary',
    CHECKUP_REVIEWS_ALL_TITLE: 'All Checkup Summaries',
    RESULTS_CHECKUP_SUMMARY_DESCRIPTION: 'View check-up summary',
    'APPOINTMENT_EDIT_MODAL.CHECKUP_DESCRIPTION_ALTERNATE':
      'Choose a new date and time for the check-up. Only slots before your consultation on {{date}} are displayed.',
    RESULTS_OVERALL_MEANING_DESCRIPTION: 'What does that mean?',
    RESULTS_BODYAGE_DESCRIPTION: 'Estimated body age based on your results',
    RESULTS_YEARS: 'years',
    APPOINTMENT_CONFIRMATION_TELE_ADDRESS: 'Your video consultation link will be available soon',
    APPOINTMENT_CONFIRMATION_TELE_INFO:
      'Click the link above to join the video consultation. Please use the latest version of Chrome, Firefox or Safari',
    TELECONSULTATION_ICS_DESCRIPTION:
      'To join your video consultation, use the link {{0}}. Make sure you are using the latest browsers (Chrome, Safari or Firefox). If you have any issues, please contact support@wellabe.de',
    RECOMMENDATIONS_TITLE_TELE: 'What to know for your video consultation',
    RECOMMENDATIONS_ENTRY_1_TELE:
      'Join a few minutes before to make sure everything works as expected',
    RECOMMENDATIONS_ENTRY_2_TELE:
      'Make sure your microphone is enabled and you are using the latest Chrome, Firefox or Safari version',
    RECOMMENDATIONS_ENTRY_3_TELE:
      'If you have any troubles, our Doctor will contact you via phone using the number you provided earlier',
    RESET_PASS_EMAIL: 'Enter your Email',
    RESET_PASS_WELLABE_ID: 'Enter your wellabe ID',
    RESET_PASS_ALTERNATIVELY_LABEL: 'Alternatively,',
    RESET_PASS_USE_WELLABE_ID: 'use your wellabe ID',
    RESET_PASS_USE_EMAIL: 'use your email address',
    RESET_PASS_NOTE: 'If you need assistance, please contact customer service',
    YOUR_WELLABE_ID: 'Your wellabe ID',
    APPOINTMENTS_DETAILS_MODAL_REEESCHEDULE: 'Not going to make it? You can always',
    'QUESTIONNAIRES.SELECT_ANSWER': 'Select an answer',
    'QUESTIONNAIRES.SELECT_TIME': 'Select a time',
    'QUESTIONNAIRES.SELECT_DATE': 'Select a date',
    'QUESTIONNAIRES.SELECT_DATE_TIME': 'Select a date and time',
    'QUESTIONNAIRES.TYPE_STRING': 'Type your answer',
    'QUESTIONNAIRES.TYPE_NUMBER': 'Type a number',
    'QUESTIONNAIRES.TYPE_ANSWER': 'Select an Answer',
    'QUESTIONNAIRE.VALUE_PLACEHOLDER': 'Value...',
    'QUESTIONNAIRES.QUESTIONS_PROGRESS': 'Question {{number}} of {{total}}',
    'QUESTIONNAIRES.START': 'TAKE QUESTIONNAIRE',
    'QUESTIONNAIRES.NUMBER_QUESTIONS': '{{number}} questions',
    'QUESTIONNAIRES.PAGE_TITLE': 'Questionnaires',
    'QUESTIONNAIRES.NEW_BIOMARKER.BUTTON': 'View new biomarker',
    'QUESTIONNAIRES.NEW_BIOMARKER.DESCRIPTION':
      'As you progress through questionnaires, new biomarkers will appear on the Results screen. Find out more and learn how to keep improving by checking it out!',
    'QUESTIONNAIRES.NEW_BIOMARKER.TITLE': 'New biomarker available',
    'QUESTIONNAIRES.LOADING_QUESTIONNAIRES': 'Loading Questionnaire',
    HEADER_FAQ: 'F.A.Q.',
    HEADER_SIGNOUT: 'Sign Out',
    SELECT_OK_TEXT: 'Ok',
    SELECT_CANCEL_TEXT: 'Cancel',
    HOME_STORE_TITLE: 'Stay connected, even on the go \ud83d\udeb4\u200d\u2642\ufe0f.',
    HOME_STORE_DESCRIPTION:
      'Download our app to get personalized content based on your health profile!',
    FOOTER_ADDRESS: 'wellabe.de\r\nAgnes-Pockels-Bogen 1\r\n80992 Munchen',
    HOME_HERO_TITLE: 'You are only a few steps away from having a healthier lifestyle.',
    HOME_HERO_LOGIN: 'Login',
    HOME_HERO_SIGNUP: 'Sign Up',
    FOOTER_TWITTER_LINK: 'https://twitter.com/wellabe_de',
    FOOTER_FACEBOOK_LINK: 'https://www.facebook.com/wellabe.de/',
    FOOTER_LINKEDIN_LINK: 'https://www.linkedin.com/company/wellabe/',
    HOME_STORE_PLAY_ALT: 'Get\u00a0it\u00a0on\u00a0Google\u00a0Play',
    HOME_STORE_APPLE_ALT: 'Get\u00a0it\u00a0on\u00a0App\u00a0Store',
    HEADER_LOGIN: 'Login',
    SIGN_UP_TITLE: "Let's sign you in",
    SIGN_UP_DESCRIPTION:
      'Sign in with your email address or wellabe ID to schedule and manage appointments and view your lab results.',
    SIGN_UP_FORGOT_PASSWORD_LABEL: 'Forgot your password/ID?',
    SIGN_UP_FORGOT_PASSWORD_BUTTON: 'Click here',
    SIGN_UP_LOGIN: 'Login',
    SIGN_UP_ID_PLACEHOLDER: 'Email or wellabe ID',
    SIGN_UP_PASSWORD_PLACEHOLDER: 'Password',
    RESET_PASS_CONFIRMATION_TITLE: 'Email sent!',
    RESET_PASS_PHONE: '+49 (089) 21 54 70 524',
    SCHEDULE_VOUCHER_NOTE:
      'If you have any questions, please contact your local representative or call us at',
    SCHEDULE_RECOMMENDATIONS_DESCRIPTION:
      'Check out some of our tips before you schedule your appointment.',
    'QUESTIONNAIRE.TYPE_STRING': 'Type your answer',
    'QUESTIONNAIRE.TYPE_NUMBER': 'Type a number',
    REGISTER_PASSWORD_DESCRIPTION_1: 'Please keep those safe.',
    REGISTER_PASSWORD_DESCRIPTION_2: "You'll need it to log in from now on.",
    REGISTER_PASSWORD_CONTINUE: 'Continue without saving',
    REGISTER_PASSWORD_SAVE_CONTINUE: 'Save credentials & Next',
    REGISTER_CREDENTIALS: 'Your credentials',
    'WAITING_APPOINTMENTS.RESCHEDULE_APPOINTMENT': 'Reschedule your appointment',
    'WAITING_APPOINTMENTS.ADDITIONAL_OPTIONS': 'Additional options',
    APPOINTMENT_ACCESS_LINK: 'Access link',
    APPOINTMENT_MEET_US_AT: 'Meet us at',
    BUTTON_VIEW: 'View',
    'WAITING_APPOINTMENTS.BEFORE_APPOINTMENT_TITLE': 'Before your appointment',
    'WAITING_APPOINTMENTS.BEFORE_APPOINTMENT_DESCRIPTION':
      'Personalize your experience by taking a few questionnaires.',
    SCHEDULE_QUESTIONS_TODO: "To-do's",
    SCHEDULE_QUESTIONS_COMPLETED: 'Completed',
    BUTTON_TAKE: 'Take',
    SCHEDULE_QUESTIONS_EMPTY: "We couldn't find questionnaires here",
    'WAITING_APPOINTMENTS.BEFORE_APPOINTMENT_TITLE_DESKTOP':
      'Personalize your experience by taking a few questionnaires.',
    'WAITING_APPOINTMENTS.RESCHEDULE_APPOINTMENTS': 'Reschedule Appointments',
    REGISTER_CHOOSE_TITLE: 'How would you like to register?',
    PROFILE_QUESTIONS_ERROR: "Couldn't load questions right now.",
    PROFILE_QUESTIONS_RETRY: 'Try again',
    ONBOARDING_RESULTS_PAGE_TITLE: 'You results are ready!',
    ONBOARDING_RESULTS_PAGE_DESCRIPTION:
      'Download the wellabe app to view your results including an explanation or download the PDF report below. The PDF file is password protected. You will need to use your year of birth and height in centimeters as password.',
    ONBOARDING_RESULTS_PAGE_NOTE:
      'If you were born in 1979 and are 165 cm tall, your password would be 1979165.',
    ONBOARDING_RESULTS_SCHEDULE_NEW_LABEL: 'You can also',
    ONBOARDING_RESULTS_SCHEDULE_NEW_LINK: 'schedule a new appointment',
    ONBOARDING_RESULTS_DOWNLOAD_PDF: 'Download PDF',
    CREDENTIALS_PDF_TITLE: 'Your wellabe Credentials',
    CREDENTIALS_PDF_PHRASE_1: 'This file is a backup for your wellabe ID and Password.',
    CREDENTIALS_PDF_PASSWORD_LABEL: 'Your password',
    CREDENTIALS_PDF_PHRASE_2: 'It is not possible to recover these credentials.',
    CREDENTIALS_PDF_PHRASE_3: 'Keep it safe!',
    FAQ_TITLE: 'Frequently asked questions',
    FAQ_QUESTION_1_TITLE: '1. What do I need to know for my appointment?',
    FAQ_QUESTION_1_ANSWER:
      'Please arrive on time for the check-up and bring your wellabe ID with you.<br /><br />We recommend not eating or drinking anything but water for three hours prior to your appointment, in order to obtain reliable and unbiased results.<br /><br />We suggest wearing comfortable, loose-fitting clothing, as your blood pressure is measured both for your arms and calves.',
    FAQ_QUESTION_2_TITLE: '2. What should I do if I am late for my appointment?',
    FAQ_QUESTION_3_TITLE: "3. Something's come up: How can I reschedule/cancel my appointment?",
    FAQ_QUESTION_3_ANSWER:
      'You can reschedule and cancel your appointment at any time in the app (<a href="https://itunes.apple.com/sc/app/wellabe/id1179194194?mt=8">iOS </a>\u00a0or <a href="https://play.google.com/store/apps/details?id=de.bodylabs.app">Android</a>) or on this website. <br /><br /> For the app: First open the app. You will find the button \u2018My Appointment\u2019 under the questionnaires. Here you will find the button \u2018Edit my appointment\u2019, where you can both rebook. <br /> <img src="assets/images/faq/question_3_1.png"> To edit your appointment on the website, log in there. Your appointment will then be displayed immediately. You now have the possibility to cancel your appointment.',
    FAQ_QUESTION_4_TITLE: '4. Where can I download the app?',
    FAQ_QUESTION_4_ANSWER:
      'You can find our app for <a href="https://itunes.apple.com/sc/app/wellabe/id1179194194?mt=8">iOS </a>\u00a0on the App Store or for <a href="https://play.google.com/store/apps/details?id=de.bodylabs.app">Android</a> on the Google Play Store. The app is called \u2018wellabe\u2019 and can be recognized by its orange blob. <br /><img src="assets/images/faq/question_4.png">',
    FAQ_QUESTION_5_TITLE: '5. How can I register in the app?',
    FAQ_QUESTION_5_ANSWER:
      'You log in to the app with the same credentials that you used to register when you booked your appointment, i.e. your assigned wellabe-ID and the password you chose.',
    FAQ_QUESTION_6_TITLE: '6. What can I do if I forgot my password?',
    FAQ_QUESTION_6_ANSWER:
      'You can reset your password in the app. All you need to do is enter your wellabe ID and email address under \'Forgot password\' in the app. We will then send you an e-mail with a link to reset your password.\u00a0 <br /><br /> However, this is only possible for participants who have registered with an e-mail address. If you registered for your appointment without an e-mail address, you will find your password saved as a PDF on the computer with which you booked the appointment.<br /><br /> If you cannot find this file, please send an email to <a href="support@wellabe.de%20">support@wellabe.de</a> or contact us at +4989215470524 with your wellabe ID and the exact date and time of your appointment.',
    FAQ_QUESTION_7_TITLE: '7. Can I trust the Check up\u00b4s results?',
    FAQ_QUESTION_7_ANSWER:
      'Our testing procedures are based on scientifically standardized test procedures, nevertheless  mistakes can never be completely excluded.<br /> <img src="assets/images/faq/question_7.png">',
    FAQ_QUESTION_8_TITLE:
      "8. I entered the correct wellabe ID and password in the app, but I still can't log in!",
    FAQ_QUESTION_8_ANSWER:
      'Please make sure that you have downloaded the latest version of the app and confirm the accuracy of your password by showing it with the grey eye. If this does not work, please request a new password.<br /><br /> Should this not solve the problem, please contact us. You can reach us by e-mail at <a href="mailto:support@wellabe.de">support@wellabe.de</a> or by phone at +4989215470524. Please have your wellabe ID and the product name of your smartphone ready.',
    FAQ_QUESTION_9_TITLE: '9. What should I do if the website / app no longer responds?',
    FAQ_QUESTION_9_ANSWER:
      'Should technical problems occur in the app or on the website, please log out, close the app/website and reopen it after a short wait. If the problems persist, please send us an e-mail to <a href="mailto:support@wellabe.de">support@wellabe.de</a> or contact us at +4989215470524.',
    FAQ_QUESTION_10_TITLE: '10. Can I use the app on my computer?',
    FAQ_QUESTION_10_ANSWER:
      'Currently you can only use the app on smartphones with <a href="https://itunes.apple.com/sc/app/wellabe/id1179194194?mt=8">iOS </a>\u00a0or <a href="https://play.google.com/store/apps/details?id=de.bodylabs.app">Android</a>. If this is not an option for you, we will gladly provide you with a PDF with your values upon completion of the health check.',
    PROFILE_QUESTIONS_SEX_NOTICE: 'Due to medical recommendations, we require your biological sex.',
    FAQ_QUESTION_2_ANSWER:
      'If you are more than three minutes late, please contact us by phone at +49 (0)89 215 470 524 to avoid unnecessary waiting times for your colleagues.',
    ONBOARDING_RESULTS_PAGE_DESCRIPTION_SHORT:
      "Download your results in a PDF file. The PDF file is password protected, so you'll need to use your age and height together as the access code.",
    EMAIL_VERIFICATION_UPDATE_DESCRIPTION: 'Think there was a typo?',
    EMAIL_VERIFICATION_RESEND_DESCRIPTION: 'Didn\u2019t receive an email?',
    EMAIL_VERIFICATION_KEEP: 'Keep current email address',
    SCHEDULE_WAITING_CALENDAR_CHECKUP: 'wellabe Check-up',
    SCHEDULE_WAITING_CALENDAR_TELECONSULTATION: 'wellabe Teleconsultation',
    BACK_BUTTON: 'Back',
    TAB_TODAY: 'Today',
    RESULTS_OVERALL_BODYAGE_TITLE: 'Calculated body age',
    RESULTS_CHECKUP_SUMMARY_TITLE: 'Last Checkup Summary',
    RESULTS_BROUGHT_TO_YOU: 'Brought to you by',
    RESULTS_BODYAGE_TITLE: "Group's body age",
    RESULTS_INFO_READING_INFORMATIVE_HEADING: 'Colors and captions',
    CHECKUP_RISK_FLAGGED_TITLE: 'About your results',
    RESULTS_BODYAGE_PAGE_TITLE: 'Estimated body ages',
    RESULTS_AGE_PER_GROUP_TITLE: 'Age per bodygroup',
    RESULTS_AGE_PER_GROUP_ABOUT_TITLE: 'About the estimation',
    RESULTS_AGE_PER_GROUP_ABOUT_DESCRIPTION:
      'As you age, many values change and "get older" with you, this means that it is quite normal for a 60 years old to have a higher blood pressure and lower lung volume than a 30 years old. These measurements thus reflect the biological age.\r\n\r\nA higher body age does not mean that something is wrong with your health. A person a few years older than you, of course, can be in shape and healthy. For example, it is possible that your lung age higher than your real age, even though all readings are good. Your lungs are healthy, but more like a slightly older person.',
    BIOMARKER_LAST_READING_ON: 'Last reading on',
    BIOMARKER_YOUR_RESULTS: 'Your result',
    COOKIES_MESSAGE:
      "<h1>Cookie Policy </h1>\r\nWe use cookies to enable you to use the website comfortably and to analyze which content is of interest to our users. We believe it is important that you know how we handle your data and that you can decide how we handle your data. You can find information on the handling of your personal data in our data protection declaration.  By clicking on the Accept button, you agree to the use of all optional cookies. You can revoke your consent at any time. You can find more information <a target='_blank' href='https://web.wellabe.fit/terms?type=privacy'>here</a>. By clicking on reject only necessary cookies are used.",
    COOKIES_DECLINE: 'Accept only required cookies',
    COOKIES_ALLOW: 'Accept All Cookies',
    SETTINGS_OTHER_LOGOUT_ACCOUNT: 'Account',
    SETTINGS_OTHER_CLIPBOARD: 'wellabe ID copied',
    SETTINGS_CHANGE_PASSWORD_DESCRIPTION:
      "Your account security depends on a strong password that's not easily guessable.",
    REQUEST_DATA_PAGE_PASSWORD: 'Your email will not be stored in our database.',
    DELETE_ACCOUNT_CANCEL_RESPONSE: 'Your request has been canceled',
    ACTIVITIES_QUESTIONNAIRES_TITLE: 'Questionnaires',
    ACTIVITIES_QUESTIONNAIRES_DESCRIPTION:
      'Help\u00a0your\u00a0doctor\u00a0learn\u00a0a\u00a0bit\u00a0more\u00a0about\u00a0yourself\u00a0and\u00a0improve\u00a0your in-app\u00a0suggestions',
    ACTIVITIES_QUESTIONNAIRES_CTA: 'All\u00a0questionnaires',
    ACTIVITIES_ARTICLES_TITLE: 'Articles',
    ACTIVITIES_ARTICLES_CTA: 'All\u00a0articles',
    ACTIVITIES_ARTICLE_LABEL: 'Article',
    ACTIVITIES_JOURNEYS_TITLE: 'Journeys',
    ACTIVITIES_COMPLETED_TITLE: 'Completed Activities',
    ACTIVITIES_COMPLETED_EMPTY: "We couldn't find any completed item",
    ACTIVITIES_JOURNEY_LABEL: 'Journey',
    SETTINGS_FEEDBACK_QUESTION: 'How would you rate us?',
    INPUT_LABEL_EMAIL_CONTINUE: 'Enter your new email address',
    ACTIVITIES_EMPTY: 'Oops, nothing to see here',
    SETTINGS_FEEDBACK_IMPROVE: 'How can we improve?',
    SETTINGS_FEEDBACK_THOUGHTS: 'Have any thoughts?',
    IMPERSONATE_HEADER: 'Impersonate User',
    IMPERSONATE_CONFIRM: 'Impersonate',
    IMPERSONATE_IMPERSONATING: 'Impersonating',
    IMPERSONATE_CHANGE_USER: 'Change\u00a0User',
    IMPERSONATE_STOP_IMPERSONATING: 'Stop\u00a0Impersonating',
    IMPERSONATE_LABEL: 'Impersonate',
    'FORM_VALIDATION_MESSAGES.WEAK_PASSWORD.DESCRIPTION': 'Your password must contain:',
    'FORM_VALIDATION_MESSAGES.WEAK_PASSWORD.MIN_LENGTH': 'At least 8 characters',
    'FORM_VALIDATION_MESSAGES.WEAK_PASSWORD.SPECIAL': '1 special character',
    'FORM_VALIDATION_MESSAGES.WEAK_PASSWORD.UPPER_CASE': '1 upper case',
    'FORM_VALIDATION_MESSAGES.WEAK_PASSWORD.NUMBER': '1 number',
    JOURNEY_PROGRESS: 'Progress',
    JOURNEY_PROGRESS_LABEL: 'Progress',
    JOURNEY_FIRST_CHALLENGE: 'View first challenge',
    JOURNEY_FIRST_CHALLENGE_LABEL:
      "Upon starting your first challenge, you'll automatically join this journey. Complete all challenges to finish a journey.",
    JOURNEY_ABOUT_THIS: 'About this journey',
    JOURNEY_ALL_CHALLENGES: 'All Challenges',
    JOURNEY_MOTIVATIONAL_QUOTE: 'Motivational Quote',
    'FORM_VALIDATION_MESSAGES.WEAK_PASSWORD': 'Check password',
    'FORM_VALIDATION_MESSAGES.EMAIL': 'Check email',
    'FORM_VALIDATION_MESSAGES.WEAK_PASSWORD.CHARACTER': '1 letter',
    SCHEDULE_APPOINTMENT_DATE: 'Date',
    SCHEDULE_APPOINTMENT_TIME: 'Time',
    JOURNEY_CHALLENGES_TITLE: 'Challenges',
    TODAYS_GOALS_PAGE: 'Today',
    TODAYS_ACTIVE_JOURNEYS: '{{activeJourneys}} active journeys',
    TODAYS_GOAL_SECTION: 'Today goals',
    TODAYS_DONE_CARD: "You're done for the day! \ud83d\udc4c",
    TODAYS_CHECKUP_ON: 'Check-up on {{date}}',
    TODAYS_TELE_ON: 'Teleconsultation on {{date}}',
    ACTIVITIES_CHALLENGE_LABEL: 'Challenge',
    CHALLENGE_REWARD_HEADER: 'Goal',
    CHALLENGE_START_CHALLENGE: 'Start Challenge',
    CHALLENGE_DURATION: 'Duration',
    GENERAL_DAYS: 'days',
    CHALLENGE_PROGRESS_COMPLETED: 'Done',
    CHALLENGE_PROGRESS_NOT_LOGGED: 'Pending',
    CHALLENGE_TODAY_PROGRESS: "Today's progress",
    CHALLENGE_PROGRESS: 'Total Progress',
    CHALLENGE_TRACK_PROGRESS: 'Track Progress',
    CHALLENGE_OPTIONS: 'Options',
    CHALLENGE_TRACK_BOOLEAN_MESSAGE: 'Mark this challenge as completed today?',
    CHALLENGE_LOG_HEADER: 'Daily Track',
    CHALLENGE_LOG_DESCRIPTION: 'Track your daily progress on the {{title}} the field below.',
    CHALLENGE_LOG_BUTTON_SUBMIT: 'Submit',
    GENERAL_MORE: 'More',
    HEADER_FAQ_LINK: 'https://www.wellabe.de/faq',
    CHALLENGE_PROGRESS_YESTERDAY_LABEL:
      'Forgot\u00a0to\u00a0track\u00a0this\u00a0challenge\u00a0yesterday?',
    CHALLENGE_PROGRESS_YESTERDAY_CTA: "Track\u00a0yesterday's progress",
    BUTTON_SHOW: 'SHOW',
    BUTTON_HIDE: 'HIDE',
    TODAY_FEEDBACK_TITLE: 'Enjoying wellabe?',
    TODAY_FEEDBACK_MESSAGE: 'Let us know by sharing your suggestions and feedback.',
  };

  public static readonly DE_TRANSLATIONS = {
    COOKIES_MESSAGE: `<h1>Privatsphäre verwalten </h1>\r\n Wir verwenden Cookies, um dir die einfache Nutzung der Website zu ermöglichen und um zu verstehen, welche Inhalte für unsere Besucher von Interesse sind. Es ist uns wichtig, dass du weißt, wie wir mit deinen Daten umgehen, und dass du selbst entscheiden kannst, wie wir mit ihnen verfahren. Weitere Informationen zu unserem Umgang mit deinen persönlichen Daten findest du in unserer Datenschutzerklärung.  Mit dem Klick auf die Schaltfläche "Alle akzeptieren" stimmen Sie der Verwendung aller optionalen Cookies zu. Sie können Ihre Zustimmung jederzeit widerrufen. Weitere Informationen finden Sie unter <a target='_blank' href='https://www.wellabe.de/de/phc/termsconditions'>hier</a>. Wenn Sie auf "nur funktionale Cookies" klicken, werden nur notwendige Cookies für die Bereitstellung der Webseite verwendet.`,
    COOKIES_DECLINE: 'Nur Funktionale Cookies',
    COOKIES_ALLOW: 'Alle Akzeptieren',
  };

  public static readonly CS_TRANSLATIONS = {
    COOKIES_MESSAGE: `<h1>Cookies na wellabe</h1>\r\n Soubory cookie používáme k tomu, abychom vám umožnili pohodlně používat webové stránky a analyzovat obsah, který naše uživatele zajímá. Jsme přesvědčeni, že je důležité, abyste věděli, jak s vašimi údaji nakládáme, a abyste mohli rozhodnout, jak s vašimi údaji nakládáme. Informace o nakládání s vašimi osobními údaji najdete v našem prohlášení o ochraně osobních údajů. Kliknutím na tlačítko Přijmout souhlasíte s používáním všech volitelných souborů cookie. Svůj souhlas můžete kdykoli odvolat. Více informací naleznete zde. Kliknutím na odmítnout se použijí pouze nezbytné soubory cookie.
    `,
    COOKIES_DECLINE: 'Pouze nezbytné',
    COOKIES_ALLOW: 'Ano, souhlasím',
  };

  public static readonly SK_TRANSLATIONS = {
    COOKIES_MESSAGE: `<h1>Súbory cookie vo wellabe</h1>\r\n Súbory cookie používame na to, aby sme vám umožnili pohodlne používať webové stránky a analyzovať, ktorý obsah našich používateľov zaujíma. Sme presvedčení, že je dôležité, aby ste vedeli, ako nakladáme s vašimi údajmi, a aby ste mohli rozhodnúť, ako s vašimi údajmi nakladáme. Informácie o spracovaní vašich osobných údajov nájdete v našom vyhlásení o ochrane údajov. Kliknutím na tlačidlo Prijať súhlasíte s používaním všetkých voliteľných súborov cookie.Svoj súhlas môžete kedykoľvek odvolať. Viac informácií nájdete tu. Kliknutím na odmietnuť sa používajú len potrebné súbory cookie.
    `,
    COOKIES_DECLINE: 'Nur Funktionale Cookies',
    COOKIES_ALLOW: 'Alle Akzeptieren',
  };
}
