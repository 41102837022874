// tslint:disable-next-line:class-name
// eslint-disable-next-line @typescript-eslint/naming-convention
export class ANALYTICS_TRACKING {
  public static readonly ACTIONS = {
    SUBMIT_LOGIN: 'Submit Login',
    TOGGLE_VISIBILITY: 'Toggle Visibility',
    TOGGLE_SELECT: 'Toggle Select',
    RESET_PASSWORD: 'Reset Password',
    SUBMIT_PROFILE_QUESTIONS: 'Submit Profile Questions',
    SAVE_ANSWER: 'Save Answer',
    SUBMIT_VOUCHER: 'Submit Voucher',
    SUBMIT_CACHED_VOUCHER: 'Submit Cached Voucher',
    SUBMIT_APPOINTMENT: 'Submit Appointment',
    SUBMIT_EDIT_APPOINTMENT: 'Submit Edit Appointment',
    CHECK_APPOINTMENT_AVAILABLE: 'Check for Available Appointment',
    APPOINTMENT_AVAILABLE: 'Exists An Available Appointment',
    REQUEST_WAITING_LIST_REMOVAL: 'Request Waiting List Removal',
    REQUEST_WAITING_LIST_JOIN: 'Request Waiting List Join',
    SUBMIT_REGISTRATION: 'Submit Registration',
    SCREENSHOT: 'Screenshot',
    CALENDAR_SAVE: 'Calendar Save',
    TOGGLE_MODAL: 'Toggle Modal',
    REQUEST_CANCEL_APPOINTMENT: 'Request Cancel Appointment',
    REQUEST_APPOINTMENT_UPDATEABLE: 'Request Appointment Updateable',
    SUBMIT_QUESTION: 'Submit Question',
    WEEKLY_PLAN_NAVIGATION: 'Weekly Plan Navigation',
    VIEW_JOURNEYS: 'View Journeys',
    LOG_CHALLENGE: 'Log Challenge',
    REQUEST_SHARE_RESULTS: 'Request Share Results',
    TOGGLE_ALERT: 'Toggle Alert',
    REQUEST_QUIT_JOURNEY: 'Request Quit Journey',
    MAXIMIZE_IMAGE: 'Maximize Image',
    OUTBOUND: 'Outbound',
    SUBMIT_EMAIL_VERIFICATION: 'Submit E-mail Verification',
    SUBMIT_EMAIL_UPDATE: 'Submit E-mail Update',
    REQUEST_EMAIL_VERIFICATION_CODE: 'Request E-mail Verification Code',
    CHANGE_DIFFICULTY: 'Change Difficulty',
    START_CHALLENGE: 'Start Challenge',
    RESTART_CHALLENGE: 'Restart Challenge',
    REQUEST_START_CHALLENGE: 'Request Start Challenge',
    REQUEST_RESTART_CHALLENGE: 'Request Restart Challenge',
    REQUEST_QUIT_CHALLENGE: 'Request Quit Challenge',
    SUBMIT_CHALLENGE_REMINDERS: 'Submit Challenge Reminders',
    MODIFY_CHALLENGE_REMINDERS: 'Modify Challenge Reminders',
    REQUEST_DISMISS_CHALLENGE: 'Request Dismiss Challenge',
    TOGGLE_CHALLENGE_HISTORY: 'Toggle Challenge History',
    COPY_CONTENT: 'Copy Content',
    REQUEST_REFRESH_DATA: 'Request Refresh Data',
    REQUEST_LANGUAGE_CHANGE: 'Request Language Change',
    REQUEST_RESET_ACCOUNT: 'Request Reset Account',
    REQUEST_IMPERSONATE: 'Request Impersonate',
    QUIT_QUESTIONNAIRE: 'Quit questionnaire',
    CANCEL_IMPERSONATE: 'Cancel Impersonate',
    REQUEST_LOGOUT: 'Request Logout',
    SUBMIT_CHANGE_PASSWORD: 'Submit Change Password',
    SUBMIT_FEEDBACK: 'Submit Feedback',
    SUBMIT_DOWNLOAD_DATA_FORM: 'Submit Download Data Form',
    REQUEST_DELETE_EMAIL: 'Request Delete E-mail',
    SUBMIT_REGISTER_EMAIL: 'Submit Register E-mail',
    TOGGLE_ANALYTICS: 'Toggle Analytics',
    SUBMIT_DELETE_ACCOUNT: 'Submit Delete Account',
    SUBMIT_CANCEL_DELETE_ACCOUNT: 'Submit Cancel Delete Account',
    SUBMIT_DELETE_EMAIL: 'Submit Delete E-mail',
    REQUEST_RESEND_EMAIL_VERIFICATION_CODE: 'Request Resend E-mail Verification Code',
    PUSH_NOTIFICATION_OPENED: 'Open Push Notification',
    OUTBOUND_APPLE_STORE: 'Outbound Apple Store Link',
    OUTBOUND_PLAY_STORE: 'Outbound Play Store Link',
    HEALTH_SUMMARY_MODAL: 'Open Group Details',
    ADD_BIOMARKER_VALUE: 'Add Biomarker Value',
    BIOMARKER_SEARCHED: 'Biomarker Searched',
    SPONSOR_COURSE_CTA: 'Clicked sponsor course CTA',
    SPONSOR_ARTICLE_CTA: 'Clicked sponsor article CTA',
    BIOMARKER_TYPE_SEARCH: 'SRB search',
    APPOINTMENT_MODAL: 'Opened appointment modal',
    OPENED_QUESTIONNAIRE: 'Accessed questionnaire',
    SELECTED_TELECONSULTATION: 'Selected teleconsultation on webregistration',
    SELECTED_CHECKUP: 'Selected checkup on webregistration',
  };
}
