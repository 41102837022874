import { Component, ViewChild } from '@angular/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Components } from '@ionic/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-walkthrough-flyover',
  templateUrl: './walkthrough-flyover.component.html',
  styleUrls: ['./walkthrough-flyover.component.scss'],
})
export class WalkthroughFlyoverComponent {
  @ViewChild('onboardingSlides', { static: true }) onboardingSlides: Components.IonSlides;

  isLastSlide = false;

  constructor(private modalController: ModalController) {}

  async nextSlide() {
    if (this.onboardingSlides) {
      if (await this.onboardingSlides.isEnd()) {
        this.modalController.dismiss();
      } else {
        await this.onboardingSlides.slideNext();
      }
    }
  }

  async updateIsEnd() {
    this.isLastSlide = await this.onboardingSlides.isEnd();
  }
}
