import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { environment } from '../../../../environments/environment';
import { AppModeService } from '../../../core/services/app-mode/app-mode.service';
import { TranslationsService } from '../../../core/services/translations/translations.service';
import { AppModeQuery } from '../../../core/services/app-mode/app-mode.query';

@Component({
  selector: 'app-platform-fab',
  templateUrl: './platform-fab.component.html',
  styleUrls: ['./platform-fab.component.scss'],
})
export class PlatformFabComponent implements OnInit {
  public show = false;

  constructor(
    private alertController: AlertController,
    private appModeService: AppModeService,
    private appModeQuery: AppModeQuery,
    private translationsService: TranslationsService
  ) {}

  public async ngOnInit() {
    const { platform } = await Device.getInfo();
    const isWeb = platform === 'web';

    if (environment.currentEnv === 'development' && isWeb) {
      this.show = true;
    }
  }

  async openPlatformOptions() {
    const platformValue = this.appModeQuery.getPlatformValue();

    const alert = await this.alertController.create({
      header: this.translationsService.getTranslation('GENERAL.CHOOSE_PLATFORM'),
      cssClass: 'c-ion-alert',
      buttons: [
        {
          text: this.translationsService.getTranslation('GENERAL.APP'),
          handler: () => {
            if (platformValue === 'app') {
              return;
            }

            this.appModeService.updateAppModePlatform('app');

            // tiemout to workaround akita's debounce
            setTimeout(() => {
              window.location.reload();
            }, 600);
          },
        },
        {
          text: this.translationsService.getTranslation('GENERAL.WEB_REGISTRATION'),
          handler: () => {
            if (platformValue === 'web') {
              return;
            }

            this.appModeService.updateAppModePlatform('web');

            // tiemout to workaround akita's debounce
            setTimeout(() => {
              window.location.reload();
            }, 600);
          },
        },
        {
          text: this.translationsService.getTranslation('CANCEL_BUTTON'),
          role: 'cancel',
          handler: () => {},
        },
      ],
    });

    alert.present();
  }
}
