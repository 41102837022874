import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { PlatformState, AppModeStore } from './app-mode.store';

@Injectable({ providedIn: 'root' })
export class AppModeQuery extends Query<PlatformState> {
  platform$ = this.select((state) => state.platform);

  constructor(protected store: AppModeStore) {
    super(store);
  }

  getPlatformValue() {
    return this.getValue().platform;
  }
}
