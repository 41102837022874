import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import { Storage } from '@ionic/storage';
import { LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications';
import { DataProviderService } from '../data-provider/data-handler.service';
import { StorageService } from '../storage/storage.service';
import { TranslationsService } from '../translations/translations.service';
import { ChallengeModel } from '../../../shared/models/challenge/challenge.model';
import { ChallengeReminder } from '../../../shared/interfaces/challenges/activities.interface';
import { OfflineSyncService } from '../offline-sync/offline-sync.service';
import { PushNotificationsService } from '../push-notifications/push-notifications.service';
import { AppModeService } from '../app-mode/app-mode.service';

export interface NotificationClickParameters {
  action: number; // action_id
  challenge: number; // challenge_id
}

@Injectable({
  providedIn: 'root',
})
export class LocalNotificationsService {
  engagementPhrases = {
    titles: [],
    phrases: [],
  };

  iosNotificationLimit = 30;

  onNotificationClick: BehaviorSubject<NotificationClickParameters> = new BehaviorSubject(null);

  private schedulling = false;

  private lastClickedChallenge: NotificationClickParameters = null;

  constructor(
    public dataProvider: DataProviderService,
    public storageProvider: StorageService,
    public translations: TranslationsService,
    public platform: Platform,
    public storage: Storage,
    public offlineSync: OfflineSyncService,
    private pushService: PushNotificationsService,
    private appModeService: AppModeService
  ) {}

  /**
   * Create all app notifications, including challenge reminders and engagment notifications
   */
  public async setAllNotifications(challenges: Array<ChallengeModel>) {
    // Push and Local share the same permissions
    if (this.schedulling || this.pushService.hasPermission === false) {
      return;
    }

    this.schedulling = true;
    // TODO: Save notifications in order to update them individually
    const notifications: Array<LocalNotificationSchema> = [];

    if (challenges) {
      this.addChallengesNotifications(notifications, challenges);
    }

    this.addEngagementNotifications(notifications);

    // this.addChallengeReminder(notifications);

    // this.storageProvider.saveData();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const cancelNotifications = await this.cancelAll();

    // iOS can only schedule 64 notifications.
    if (this.platform.is('ios')) {
      notifications.splice(
        this.iosNotificationLimit,
        notifications.length - this.iosNotificationLimit
      );
    }

    this.schedulling = true;
    LocalNotifications.schedule({ notifications }).finally(() => {
      this.schedulling = false;
    });
  }

  /**
   * Cancell all notifications. Returns a Promise from LocalNotification Plugin.
   */
  public async cancelAll() {
    try {
      const pendingNotifications = await LocalNotifications.getPending();
      if (!pendingNotifications?.notifications) {
        return;
      }

      await LocalNotifications.cancel({
        notifications: pendingNotifications.notifications,
      });
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  /**
   * Adds a notification remiding of available challenges. This is only set if the user is not currently enrroled in any challenge
   */
  addChallengeReminder(notifications: Array<LocalNotificationSchema>) {
    if (
      this.dataProvider.data.user.challenges &&
      this.dataProvider.data.user.challenges.length === 0 &&
      this.dataProvider.data.user.availableChallenges !== undefined &&
      this.dataProvider.data.user.availableChallenges.length > 0
    ) {
      const availableChallenge =
        this.dataProvider.data.user.availableChallenges[
          Math.floor(Math.random() * this.dataProvider.data.user.availableChallenges.length)
        ];

      if (availableChallenge) {
        const notify = this.createNotificationTemplate(
          this.translations.phrases.NOTIFICATION_CHALLENGE_AVAILABLE_TITLE,
          availableChallenge.title,
          moment().add(1, 'day').set({ hour: 19, minute: 0 }).toDate(),
          notifications[notifications.length - 1].id + 1
        );

        notifications.unshift(notify);
      }
    }
  }

  /**
   * Adds three engagement notifications to the start of notifications array. These notifications have their date set to
   * two, 7 and 14 days from now.
   * @param notifications - Notification array in which the notifications should be pushed to
   */
  addEngagementNotifications(notifications: Array<LocalNotificationSchema>) {
    if (this.dataProvider.userStep === 'app') {
      this.updateEngadgementPhrases();
      let startingId = 10;
      if (notifications.length > 0) {
        startingId = notifications[notifications.length - 1].id + 10;
      }
      /* let notification2days = this.createRandomNotification(startingId++);
            notification2days.at = (moment(notification2days.at).add(2, 'days')).toDate(); */

      // eslint-disable-next-line no-plusplus
      const notification1week = this.createRandomNotification(startingId++);
      notification1week.schedule = {
        at: moment(notification1week.schedule.at).add(7, 'days').toDate(),
      };

      // eslint-disable-next-line no-plusplus
      const notification2week = this.createRandomNotification(startingId++);
      notification2week.schedule = {
        at: moment(notification2week.schedule.at).add(14, 'days').toDate(),
      };

      notifications.unshift(notification1week, notification2week);
    }
  }

  /**
   * Returns a notification object with a random title and message. Both title and phrase are fetched from the engagementPhrases object.
   */
  createRandomNotification(id: number) {
    const randomTitle =
      this.engagementPhrases.titles[
        Math.floor(Math.random() * this.engagementPhrases.titles.length)
      ];
    const randomPhrase =
      this.engagementPhrases.phrases[
        Math.floor(Math.random() * this.engagementPhrases.phrases.length)
      ];

    const notify = this.createNotificationTemplate(
      randomTitle,
      randomPhrase,
      moment().set({ hour: 10, minute: 0 }).clone().toDate(),
      id
    );

    return notify;
  }

  /**
   * Returns an object that can be used to set a Notification
   *
   * @param title - Title of the notification
   * @param text - Notification Message
   * @param at - Date object with the time to be notified
   * @param id - Unique ID for the notification
   */

  createNotificationTemplate(title, body, at, id) {
    const notify: LocalNotificationSchema = {
      id,
      title,
      body,
      schedule: { at },
    };

    return notify;
  }

  /**
   * Populate the engagementPhrases object with phrases from offline translations
   */
  updateEngadgementPhrases() {
    // NOTIFICATION_ENGAGEMENT_TITLE_1
    // NOTIFICATION_ENGAGEMENT_PHRASE_1

    this.engagementPhrases = {
      titles: [],
      phrases: [],
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.translations.phrases) {
      if (key.search('NOTIFICATION_ENGAGEMENT_TITLE') !== -1) {
        this.engagementPhrases.titles.push(this.translations.phrases[key]);
      } else if (key.search('NOTIFICATION_ENGAGEMENT_PHRASE') !== -1) {
        this.engagementPhrases.phrases.push(this.translations.phrases[key]);
      }
    }
  }

  public async init() {
    const isNative = await this.appModeService.getIsMobileDevice();

    if (!isNative) {
      return;
    }

    LocalNotifications.addListener('localNotificationActionPerformed', (notificationAction) => {
      if (typeof notificationAction.notification.extra === 'undefined') {
        return;
      }

      this.lastClickedChallenge = notificationAction.notification.extra;
      this.onNotificationClick.next(this.lastClickedChallenge);
    });
  }

  public clearNotificationClick() {
    this.onNotificationClick.next(null);
  }

  private addChallengesNotifications(
    notifications: Array<LocalNotificationSchema>,
    challenges: Array<ChallengeModel>
  ) {
    let i = 0;

    const scheduleUntil = moment().add(1, 'week');

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in challenges) {
      const challenge: ChallengeModel = challenges[key];

      const actions = challenge.reminders;
      // Skip iteration if notification is disabled

      const activeActivity = challenge.getActiveActivity();
      if (activeActivity && actions) {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const j in actions) {
          const action: ChallengeReminder = actions[j];

          const actionMoment = moment(action.action_date);

          if (actionMoment.isAfter(moment()) && actionMoment.isBefore(scheduleUntil)) {
            const notify: LocalNotificationSchema = {
              id: i,
              title: challenge.data.title,
              body: '',
              extra: {
                action: action.id,
                challenge: challenge.data.challenge_id,
              },
              schedule: {
                at: moment.utc(action.action_date).clone().toDate(),
              },
            };

            notifications.push(notify);

            // iOS can only set 64 notifications at a time
            if (this.platform.is('ios') && notifications.length >= this.iosNotificationLimit) {
              break;
            }

            i += 1;
          }
        }
      }
    }
  }
}
