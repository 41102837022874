import { AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { OfflineSyncService } from '../offline-sync/offline-sync.service';
import { DataProviderService } from '../data-provider/data-handler.service';
import { AppModeService } from '../app-mode/app-mode.service';

@Injectable({ providedIn: 'root' })
export class DevelopmentModeService {
  constructor(
    public alertCtrl: AlertController,
    public syncProvider: OfflineSyncService,
    private dataProvider: DataProviderService,
    private appModeService: AppModeService
  ) {}

  public isDevelopmentMode() {
    return this.dataProvider.data.user.info.developer;
  }

  public devAddContent(type: string) {
    let promise = null;

    const mode = this.appModeService.getIonicModeStyle();
    promise = new Promise((resolve, reject) => {
      this.alertCtrl
        .create({
          mode,
          header: `View custom ${type}`,
          message: `If you wish to view any ${type}, please type the ID of the ${type} on the field bellow`,
          inputs: [
            {
              name: 'content_id',

              placeholder: `${type} ID`,
            },
          ],
          buttons: [
            {
              text: 'Cancel',

              role: 'cancel',

              handler: () => {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject(false);
              },
            },
            {
              text: 'View',

              handler: (data) => {
                const content = {
                  content_id: parseInt(data.content_id, 10),
                };

                resolve(content);
              },
            },
          ],
        })
        .then((alert) => alert.present());
    });

    return promise;
  }
}
