import { InjectionToken } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../../../../environments/environment';
import { AppSettings } from '../../../app.settings';

/*
  Documentation: https://docs.rollbar.com/docs/angular
 */

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarTransform(payload) {
  if (payload && payload.body && payload.body.trace && payload.body.trace.frames) {
    const { frames } = payload.body.trace;

    for (const frame of frames) {
      frame.filename = frame.filename.replace(
        AppSettings.APP_ROOT,
        AppSettings.ROLLBAR_SOURCEMAP_URL_PREFIX
      );
    }
  }
}

export function rollbarFactory() {
  return new Rollbar({
    accessToken: '9747994f028d407db4f8eb6bea1a5eac',
    payload: { environment: environment.rollbar.environment },
    enabled: environment.rollbar.enabled,
    captureIp: false,
    captureUncaught: true,
    captureUnhandledRejections: true,
    transform: rollbarTransform,
  });
}
