import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionnaireStore, QuestionnaireState } from './questionnaires.store';
import { QuestionnaireInProgress, Question, Questionnaire } from './questionnaires.interface';

@Injectable({ providedIn: 'root' })
export class QuestionnairesQuery extends QueryEntity<QuestionnaireState> {
  inProgressQuestionnaires$ = this.select((state) => state.entities);

  constructor(protected store: QuestionnaireStore) {
    super(store);
  }

  questionnaireByHandle(identifier: string): Observable<QuestionnaireInProgress> {
    return this.selectEntity((questionnaire) => questionnaire.handle === identifier);
  }

  questionnaireById(identifier: string): Observable<QuestionnaireInProgress> {
    return this.selectEntity((questionnaire) => questionnaire.questionnaire_id === identifier);
  }

  question(questionnaire_id: string, question_id: string): Observable<Question> {
    return this.questionnaireById(questionnaire_id).pipe(
      map((questionnaire) =>
        questionnaire.questions.find((question) => {
          return question.question_id === question_id;
        })
      )
    );
  }

  getQuestionnaireByHandle(identifer: string) {
    const getAll = this.getAll();

    return getAll && getAll.length > 0
      ? getAll.find((entity) => entity.handle === identifer)
      : null;
  }

  getQuestionnaireById(identifier: string): QuestionnaireInProgress {
    return this.getEntity(identifier);
  }

  getQuestion(questionnaire_id: string, question_id: string) {
    return this.getEntity(questionnaire_id).questions.find(
      (question) => question.question_id === question_id
    );
  }

  // the location to redirect on questionnaire completion
  getReferenceUrl(questionnaire: Questionnaire) {
    switch (questionnaire.reference_type) {
      case 'article':
        return `/activities/article/${questionnaire.reference_id}`;

      case 'biomarker':
      default:
        return `/tabs/results/biomarker-group/0/biomarker/${questionnaire.reference_id}`;
    }
  }
}
