import { Injectable } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';
import { ClipboardService } from 'ngx-clipboard';

@Injectable({
  providedIn: 'root',
})
export class ClipboardDataService {
  constructor(private clipboardService: ClipboardService) {}

  /**
   * Try to get browser related clipboard data to retrieve the clipboard text value,
   * if it does not work, it tries to take the data from Clipboard capacitor plugin
   *
   * @param event clipboard event, commonly from (paste) event on input elements
   * @returns a string as a promise
   */

  async getClipboardTextValue(event: ClipboardEvent): Promise<string> {
    try {
      if (typeof event?.clipboardData?.getData === 'function') {
        const clipboardContent = event.clipboardData.getData('text/plain');
        return clipboardContent;
      }

      const clipboardSafariValue = await navigator.clipboard?.readText();
      return clipboardSafariValue;
    } catch {
      const clipboardData = await Clipboard.read();
      return clipboardData.value;
    }
  }

  /**
   * Copies a value to the clipboard using ngx-clipboard package
   * @param value string value to based passed to the service
   */
  copyTextToClipboard(value: string) {
    this.clipboardService.copy(value);
  }
}
