import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() hasClose = true;

  @Input() center = false;

  @Input() short = false;

  @Input() mode: 'normal' | 'input' = 'normal';

  @Output() onDismiss: EventEmitter<void> = new EventEmitter();

  constructor(public modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
    this.onDismiss.emit();
  }
}
