export interface Settings {
  defaultLang: LanguageSettings;

  // If development mode is toggled
  developmentMode: boolean;

  // wellabe ID of the user being impersonated
  impersonate: string;
}

export interface LanguageSettings {
  language_id: string;
  name: string;
  locale: string;
  default?: boolean;
}

// tslint:disable-next-line:class-name
// eslint-disable-next-line @typescript-eslint/naming-convention
export class USER_SETTINGS {
  public static readonly DEFAULT_SETTINGS: Settings = {
    defaultLang: {
      name: 'English',
      language_id: '2',
      locale: 'en',
      default: true,
    },
    developmentMode: false,
    impersonate: null,
  };

  public static readonly DEFAULT_SYNC_DATE_VALUES = {
    news: 0,
    checkups: 0,
    surveys: 0,
    challenge: 0,
    article: 0,
    availableChallenges: 0,
  };
}
