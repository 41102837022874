import { Component, NgZone, OnDestroy } from '@angular/core';

import { Platform } from '@ionic/angular';

import { Subscription } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';

import { enableAkitaProdMode } from '@datorama/akita';
import { StepHandlerService } from './core/services/step-handler/step-handler.service';
import { StorageService } from './core/services/storage/storage.service';
import { AppSettings } from './app.settings';
import { DataProviderService } from './core/services/data-provider/data-handler.service';
import { TranslationsService } from './core/services/translations/translations.service';
import { LocalNotificationsService } from './core/services/local-notifications/local-notifications.service';
import { CompatibilityService } from './core/services/compatibility/compatibility.service';
import { ApiService } from './core/api/api';
import { PushNotificationsService } from './core/services/push-notifications/push-notifications.service';
import { UserService } from './core/services/user/user.service';
import { UserFlagService } from './core/services/user-flag/user-flag.service';
import { AnalyticsService } from './core/services/analytics/analytics.service';
import { environment } from '../environments/environment';
import { ImpersonateService } from './core/services/impersonate/impersonate.service';
import { AppModeService } from './core/services/app-mode/app-mode.service';
import { CookieConsentService } from './core/services/cookie-consent/cookie-consent.service';
import { StatusbarService } from './core/services/statusbar/statusbar.service';
import { EventsService } from './core/services/events/events.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnDestroy {
  private stepSubscription: Subscription;

  constructor(
    private platform: Platform,
    private stepHandler: StepHandlerService,
    private storage: StorageService,
    private dataProvider: DataProviderService,
    private translation: TranslationsService,
    private notificationService: LocalNotificationsService,
    private compatibilityService: CompatibilityService,
    private appModeService: AppModeService,
    private statusbarService: StatusbarService,
    private api: ApiService,
    private pushService: PushNotificationsService,
    private userService: UserService,
    private userFlagProvider: UserFlagService,
    private analyticsTrackerService: AnalyticsService,
    private eventsService: EventsService,
    private zone: NgZone,
    private impersonateService: ImpersonateService,
    private cookieConsentService: CookieConsentService,
    private userProvider: UserService
  ) {
    if (environment.production) {
      enableAkitaProdMode();
    }

    this.initializeApp();
  }

  ngOnDestroy() {
    if (this.stepSubscription) {
      this.stepSubscription.unsubscribe();
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.storage.init().then((userData) => {
        // Setup translations
        if (this.dataProvider.data) {
          if (
            this.dataProvider.data.translations &&
            this.dataProvider.data.translations.length !== 0
          ) {
            this.translation.setPhrases(this.dataProvider.data.translations);
          }
          if (this.dataProvider.data.docs && this.dataProvider.data.docs.length !== 0) {
            this.translation.setDocs(this.dataProvider.data.docs);
          }

          this.translation.setAvailableLanguages();
        }

        this.stepSubscription = this.eventsService.viewStepSubject$.subscribe(() => {
          this.stepHandler.goToInitialPage();
        });

        this.userService
          .getMetadata(true)
          .then(() => {
            this.eventsService.appLanguageSubject$.next();
            return this.translation.setDefaultLanguage();
          })
          .finally(() => {
            this.userService.getAppPhrases().catch();
          });

        if (userData) {
          this.loggedInSetup();
        } else {
          this.loggedOutSetup();
        }
      });

      // Setup hardware backbutton (Android)
      this.bindBackButtonEvents();

      // Setup notifications
      this.notificationService.init();

      // Binds Resume and Pause events
      this.bindPlatformEvents();

      // Set device platform info
      this.appModeService.init().then(() => {
        this.analyticsTrackerService.init();

        // Set cookie consent banner for web versions
        if (this.appModeService.isWeb()) {
          this.cookieConsentService.init();
        }

        this.statusbarService.setStatusBarStyle();
      });
    });
  }

  /*
   *  Setup for when user is logged in
   */
  private loggedInSetup() {
    this.compatibilityService.applyPatches();

    this.api.setImpersonateHeader(this.dataProvider.data.settings.impersonate);

    this.dataProvider.setProviderReady();

    this.pushService.initialize();
    this.pushService.onPermissionGranted.subscribe(() => {
      this.notificationService.setAllNotifications(this.dataProvider.data.user.challenges);
    });

    this.userService.getMetadata(true).then(() => {
      // can only impersonate after metadata is loaded
      this.impersonateService.setup();
    });

    this.userProvider.getUser();

    if (!this.userFlagProvider.getFlag('disable_analytics')) {
      this.analyticsTrackerService.loadConfig();
      this.analyticsTrackerService.setUserId(this.userService.getUserInfo().wellabe_id);
    }

    setTimeout(() => {
      SplashScreen.hide();
    }, 295);
  }

  private loggedOutSetup() {
    this.analyticsTrackerService.disableAnalytics();
    this.dataProvider.data.appVersion = AppSettings.APP_VERSION;
    this.dataProvider.setProviderReady();

    setTimeout(() => {
      SplashScreen.hide();
    }, 295);
  }

  /**
   * Overrides the back button handler in order to dismiss popovers if they present.
   */
  private bindBackButtonEvents() {
    // TODO: Handle back button
    /* this.events.subscribe('dev-back-button', () => {
      this.backButtonPressed();
    });
    this.platform.registerBackButtonAction(() => {
      this.backButtonPressed();
    }); */
  }

  /**
   * Bind events such as Pause and Resume
   */
  private bindPlatformEvents() {
    this.platform.resume.subscribe(() => {
      this.onResume();
    });
  }

  /**
   * Called when app is resumed
   */
  private onResume(): void {
    this.zone.run(() => {
      this.eventsService.appResumeSubject$.next();
    });
  }

  // TODO: Handle Back button
  private backButtonPressed() {
    /*     const activeNavs = this.navController.();
    let nav = null;
    if (activeNavs && activeNavs.length > 0) {
      nav = activeNavs[activeNavs.length - 1];
    }

    // Can we go back || does the view has a valid popover element
    const lastView = nav.getViews()[nav.getViews().length - 1];
    if ((nav && nav.canGoBack()) || lastView.instance.popover || lastView.isOverlay) {
      nav.pop().catch(err => {
        console.error('[BackButton] Nav.pop() is disabled ', err);
      });
    } else {
      // TODO: Exit on back button
      // this.platform.exitApp(); // Exit from app
    }

    this.events.publish('app:back-button'); */
  }
}
