import PackageInfo from '../../package.json';

export class AppSettings {
  public static APP_VERSION = PackageInfo.version;

  public static ROLLBAR_CLIENT = {
    javascript: {
      code_version: AppSettings.APP_VERSION,
      source_map_enabled: true,
      guess_uncaught_frames: true,
    },
  };

  // eslint-disable-next-line no-restricted-globals
  public static APP_ROOT = location.href
    // eslint-disable-next-line no-restricted-globals
    .replace(location.hash, '')
    .replace('index.html', '')
    .replace('?', '');

  public static ROLLBAR_SOURCEMAP_URL_PREFIX = 'js://';
}
