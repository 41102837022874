import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { WarningDoctorMessageComponent } from './warning-doctor-message.component';
import { ComponentsModule } from '../../components/components.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ComponentsModule, PipesModule],
  declarations: [WarningDoctorMessageComponent],
})
export class WarningDoctorMessageModule {}
