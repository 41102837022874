import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LocalNotificationsService } from '../local-notifications/local-notifications.service';
import { DataProviderService } from '../data-provider/data-handler.service';
import { StorageService } from '../storage/storage.service';
import { TranslationsService } from '../translations/translations.service';
import { ChallengeApiService } from '../../api/challenge-api.service';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { ChallengeModel } from '../../../shared/models/challenge/challenge.model';
import {
  PostChallengeActivityActionBody,
  PostChallengeActivityActionResponse,
} from '../../../shared/interfaces/api/challenge-api.interface';
import Utils from '../../../shared/utils/utils';
import { ChallengeActivity } from '../../../shared/interfaces/challenges/activities.interface';

import { DevelopmentModeService } from '../development-mode/development-mode.service';
import { EventsService } from '../events/events.service';

@Injectable({
  providedIn: 'root',
})
export class ChallengeActionsService {
  constructor(
    private notificationsProvider: LocalNotificationsService,
    public dataProvider: DataProviderService,
    public storage: StorageService,
    private translations: TranslationsService,
    private eventsService: EventsService,
    private challengeApiService: ChallengeApiService,
    private developmentMode: DevelopmentModeService,
    private errorDisplay: ErrorDialogService,
    private analytics: AnalyticsService
  ) {}

  public logChallenge(challenge: ChallengeModel, value: number = null, previousDay = false) {
    const logPromise = new Promise((resolve, reject) => {
      const activeActivity = challenge.getActiveActivity();

      if (challenge == null || challenge === undefined || activeActivity == null) {
        reject(this.translations.phrases.ERROR_UNKNOWN_MESSAGE);
        return;
      }

      const logDay = previousDay
        ? moment().startOf('day').subtract('day', 1).toString()
        : moment().startOf('day').toString();

      const logAction: PostChallengeActivityActionBody = {
        action_date: logDay,
        challenge_activity_id: activeActivity.challenge_activity_id,
        status: true,
        value,
      };

      this.challengeApiService
        .postChallengeAction(logAction)
        .then((action) => {
          this.analytics.trackEvent(
            `Request Log ${Utils.capitalizeFirstLetter(challenge.data.log_type)} Challenge`,
            `Successfully Log ${challenge.data.analytics}`,
            `Challenges - ${Utils.capitalizeFirstLetter(challenge.data.status.name)}`
          );

          const updatedActivity = challenge.getActiveActivity();

          if (
            this.isActionAlreadyLogged(updatedActivity, action) &&
            this.developmentMode.isDevelopmentMode()
          ) {
            this.errorDisplay.showError('Developer: Action logged locally only', 'info');
          }

          challenge.logChallenge(action);

          if (challenge.isCompleted()) {
            this.processCompletedChallenge(challenge);
          }

          this.storage.saveData();

          this.eventsService.challengeLogActionSubject$.next();

          resolve(action);
        })
        .catch((err) => {
          this.analytics.trackEventError(
            `Request Log ${Utils.capitalizeFirstLetter(challenge.data.log_type)} Challenge`,
            err,
            true,
            `Challenges - ${Utils.capitalizeFirstLetter(challenge.data.status.name)}`
          );

          reject(err);
        });
    });
    return logPromise;
  }

  public updateLog(
    challenge: ChallengeModel,
    value: number = null,
    shouldUpdate = false,
    previousDay = false
  ) {
    const logPromise = new Promise((resolve, reject) => {
      let activeActivity = challenge.getActiveActivity();

      if (activeActivity == null) {
        activeActivity = challenge.getTodayActivity();
      }

      if (challenge == null || challenge === undefined || activeActivity == null) {
        reject(this.translations.phrases.ERROR_UNKNOWN_MESSAGE);
        return;
      }

      const body = {
        value,
        update: shouldUpdate,
      };

      const { actions } = activeActivity;
      let action = null;

      if (actions && actions.length > 0) {
        if (previousDay) {
          const yesterday = moment().startOf('day').subtract(1, 'day');

          action = actions.find((act) => {
            return moment(act.action_date).startOf('day').isSame(yesterday);
          });
        } else {
          const today = moment().startOf('day');

          action = actions.find((act) => {
            return moment(act.action_date).startOf('day').isSame(today);
          });
        }
      } else {
        reject(this.translations.phrases.ERROR_UNKNOWN_MESSAGE);
        return;
      }

      if (!action) {
        reject(this.translations.phrases.ERROR_UNKNOWN_MESSAGE);
        return;
      }

      this.challengeApiService
        .putChallengeActivityAction(action.challenge_action_id, body)
        .then((serverAction: any) => {
          this.analytics.trackEvent(
            `Request Edit Log ${Utils.capitalizeFirstLetter(challenge.data.log_type)} Challenge`,
            `Successfully Edit Value ${challenge.data.analytics}`,
            `Challenges - ${Utils.capitalizeFirstLetter(challenge.data.status.name)}`
          );

          challenge.updateLoggedValue(serverAction);

          if (challenge.isCompleted()) {
            this.processCompletedChallenge(challenge);
          }

          this.storage.saveData();
          resolve(serverAction);
        })
        .catch((err) => {
          this.analytics.trackEventError(
            `Request Edit Log ${Utils.capitalizeFirstLetter(challenge.data.log_type)} Challenge`,
            err,
            true,
            `Challenges - ${Utils.capitalizeFirstLetter(challenge.data.status.name)}`
          );

          reject(err);
        });
    });
    return logPromise;
  }

  private processCompletedChallenge(challenge: ChallengeModel) {
    try {
      this.removeLocalChallenge(challenge);

      this.eventsService.challengeCompletedSubject$.next(challenge.data);

      this.notificationsProvider.setAllNotifications(this.dataProvider.data.user.challenges);

      this.storage.saveData();
    } catch (err) {
      this.errorDisplay.trackError('[ChallengeProvider] Error completing challenge', err);
    }
  }

  private removeLocalChallenge(challenge: ChallengeModel) {
    this.dataProvider.data.user.challenges = this.dataProvider.data.user.challenges.filter(
      (element) => element.data.challenge_id !== challenge.data.challenge_id
    );
  }

  private isActionAlreadyLogged(
    challengeActivity: ChallengeActivity,
    loggedAction: PostChallengeActivityActionResponse
  ) {
    if (challengeActivity == null) {
      return false;
    }
    const similarActions = challengeActivity.actions.filter((element) => {
      return element.challenge_action_id === loggedAction.challenge_action_id;
    });

    return similarActions.length > 0;
  }
}
