/* eslint-disable prefer-promise-reject-errors */
import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import {
  PostChallengeJourneyBody,
  ChallengeParameters,
} from '../../../shared/interfaces/api/journey-api.interface';
import { StorageService } from '../storage/storage.service';
import { DataProviderService } from '../data-provider/data-handler.service';
import { LocalNotificationsService } from '../local-notifications/local-notifications.service';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { JourneyApiService } from '../../api/journey-api.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { ChallengeModel } from '../../../shared/models/challenge/challenge.model';
import { ANALYTICS_TRACKING } from '../../../shared/interfaces/analytics.interface';
import Utils from '../../../shared/utils/utils';
import { ChallengeLogModalPage } from '../../../shared/overlays/challenge-log-modal/challenge-log-modal.page';
import { TranslationsService } from '../translations/translations.service';
import { ChallengeActionsService } from '../challenge-actions/challenge-actions.service';
import { AppModeService } from '../app-mode/app-mode.service';
import { EventsService } from '../events/events.service';

@Injectable({
  providedIn: 'root',
})
export class ChallengesService {
  private loggingChallenge = false;

  constructor(
    private dataProvider: DataProviderService,
    private storageService: StorageService,
    private localNotifications: LocalNotificationsService,
    private eventsService: EventsService,
    private errorDialog: ErrorDialogService,
    private journeysApi: JourneyApiService,
    private modalController: ModalController,
    private analytics: AnalyticsService,
    private alertController: AlertController,
    private translations: TranslationsService,
    private challengeActionsService: ChallengeActionsService,
    private appModeService: AppModeService
  ) {}

  /**
   * Return an ongoing challenge if exists or NULL if none exists.
   */
  public getChallenge(challenge_id: number) {
    if (this.dataProvider.data.user != null && this.dataProvider.data.user.challenges) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.dataProvider.data.user.challenges.length; i++) {
        if (
          this.dataProvider.data.user.challenges[i].data.challenge_id &&
          this.dataProvider.data.user.challenges[i].data.challenge_id === challenge_id
        ) {
          return this.dataProvider.data.user.challenges[i];
        }
        if (this.dataProvider.data.user.challenges[i].data.challenge_id === challenge_id) {
          return this.dataProvider.data.user.challenges[i];
        }
      }
    }

    return null;
  }

  /**
   * Gets ongoing challenges
   *
   */
  public getChallenges(): Promise<ChallengeModel[]> {
    const setupPromise: Promise<ChallengeModel[]> = new Promise((resolve, reject) => {
      this.journeysApi
        .getOngoingChallenges()
        .then((challenges) => {
          return challenges.map((challenge) => new ChallengeModel(challenge));
        })
        .then(
          (challenges: Array<ChallengeModel>) => {
            this.dataProvider.data.user.challenges = challenges;
            this.localNotifications.setAllNotifications(this.dataProvider.data.user.challenges);

            this.storageService.saveData();

            this.eventsService.challengeOngoingSubject$.next();

            resolve(this.dataProvider.data.user.challenges);
          },
          (err) => {
            reject(err);
          }
        );
    });

    return setupPromise;
  }

  public getCompletedModal(journeyId, content): { component: string; props: any } {
    let modal: { component: string; props: any } = null;

    this.eventsService.journeyStatusUpdateSubject$.next(journeyId);

    if (content.challenge) {
      modal = {
        component: `completed-challenge`,
        props: { journeyId, content: content.challenge },
      };
    } else if (content.completedJourney) {
      modal = {
        component: `completed-journey`,
        props: { journeyId },
      };

      // Open Completed Journey ModalJourneyCompletedPage
    } else {
      modal = {
        component: `completed-challenge`,
        props: { journeyId, content: null },
      };

      // Open Completed Challenge Modal with no more available challenges
    }

    return modal;
  }

  public getLocalOngoingChallenge(challenge_id: number): ChallengeModel {
    const ongoingChallenge = this.dataProvider.data.user.challenges;
    const localChallenge = ongoingChallenge.filter((challenge) => {
      return challenge.data.challenge_id === challenge_id;
    });

    return localChallenge.length > 0 ? localChallenge[0] : null;
  }

  public getLocalOngoingChallenges(): ChallengeModel[] {
    const ongoingChallenges = this.dataProvider.data.user.challenges;

    return ongoingChallenges || [];
  }

  public quitChallenge(challenge: ChallengeModel) {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      if (challenge == null || challenge === undefined) {
        reject(null);
        return;
      }

      const activeActivity = challenge.getActiveActivity();

      if (activeActivity == null) {
        reject(null);
        return;
      }

      // Challenge found, just update it to backend
      this.journeysApi
        .putChallengeJourney(activeActivity.challenge_activity_id, {
          quit: true,
        })
        .then(
          (updatedActivity: any) => {
            challenge.quit(updatedActivity);

            this.removeLocalChallenge(challenge);
            this.storageService.saveData();

            this.localNotifications.setAllNotifications(this.dataProvider.data.user.challenges);

            this.eventsService.challengeQuitSubject$.next(challenge.data.challenge_id);

            this.analytics.trackEvent(
              ANALYTICS_TRACKING.ACTIONS.QUIT_QUESTIONNAIRE,
              `Successfully quit Challenge ${challenge.data.analytics}`,
              `Challenges - ${Utils.capitalizeFirstLetter(challenge.data.status.name)}`
            );

            resolve(challenge);
          },
          (err) => {
            reject(err);
          }
        );
    });

    return promise;
  }

  public startChallenge(challenge: ChallengeModel, journeyId: number): Promise<any> {
    const analyticsInfo: { action: string; type: string } = {
      action: ANALYTICS_TRACKING.ACTIONS.REQUEST_START_CHALLENGE,
      type: 'Start',
    };

    if (challenge.isCompleted()) {
      analyticsInfo.action = ANALYTICS_TRACKING.ACTIONS.REQUEST_RESTART_CHALLENGE;
      analyticsInfo.type = 'Restart';
    }

    const promise = new Promise((resolve, reject) => {
      if (challenge == null || this.dataProvider.data.user.challenges === null) {
        reject(null);
        return;
      }

      /* Checks if challenge is already ongoing and log on rollbar */
      const isAlreadyOngoing = this.getLocalOngoingChallenge(challenge.data.challenge_id) !== null;
      if (isAlreadyOngoing) {
        this.errorDialog.trackError('Trying to start an already ongoing challenge', challenge);
      }

      const challengeSettings: PostChallengeJourneyBody = {
        reminder_days: JSON.stringify(challenge.data.reminder_days),
        reminder_time: JSON.stringify(challenge.data.reminder_time),
        duration: challenge.data.duration,
      };

      // Send challenge to backend
      this.journeysApi
        .postChallengeJourney(journeyId, challenge.data.challenge_id, challengeSettings)
        .then(
          (newActivity) => {
            challenge.start(newActivity);

            // TODO: This data handling should be moved to another provider
            const userChallenges = this.dataProvider.data.user.challenges;
            userChallenges.push(challenge);

            // TODO: Only set notifications for this particular challenge
            this.localNotifications.setAllNotifications(this.dataProvider.data.user.challenges);

            this.storageService.saveData();

            this.analytics.trackEvent(
              analyticsInfo.action,
              `Successfully ${analyticsInfo.type} Challenge ${challenge.data.analytics}`,
              `Challenges - ${Utils.capitalizeFirstLetter(challenge.data.status.name)}`
            );

            this.eventsService.challengeStartSubject$.next(challenge.data.challenge_id);
            resolve(challenge);
          },
          (err) => {
            this.analytics.trackEventError(
              analyticsInfo.action,
              err,
              true,
              `Challenges - ${Utils.capitalizeFirstLetter(challenge.data.status.name)}`
            );
            reject(err);
          }
        );
    });

    return promise;
  }

  public updateChallenge(
    challenge: ChallengeModel,
    params: ChallengeParameters
  ): Promise<ChallengeModel> {
    const promise: Promise<ChallengeModel> = new Promise((resolve, reject) => {
      if (challenge == null || challenge === undefined) {
        reject(null);
        return;
      }

      const activeActivity = challenge.getActiveActivity();
      if (activeActivity == null) {
        reject(null);
        return;
      }

      // If nothing has changed, return success
      if (
        JSON.stringify(activeActivity.reminder_days) === params.reminder_days &&
        JSON.stringify(activeActivity.reminder_time) === params.reminder_time
      ) {
        resolve(challenge);
        return;
      }

      this.journeysApi.putChallengeJourney(activeActivity.challenge_activity_id, params).then(
        (success) => {
          challenge.updateActiveCheckup(success);
          challenge.createReminders();
          this.localNotifications.setAllNotifications(this.dataProvider.data.user.challenges);
          this.storageService.saveData();

          this.eventsService.challengeUpdateSubject$.next();

          resolve(challenge);
        },
        (err) => {
          reject(err);
        }
      );
    });

    return promise;
  }

  async logChallenge(challenge: ChallengeModel, journeyId: number, previousDay = false) {
    if (this.loggingChallenge) {
      return;
    }

    challenge.addJourneyId(journeyId);

    switch (challenge.data.log_type) {
      case 'boolean':
        this.logBoolean(challenge, previousDay);
        break;
      case 'incremental':
      case 'single':
        this.openLogModal(challenge, previousDay);
        break;
      default:
        break;
    }

    const previousDayTextAppend: string = previousDay ? ' (Yesterday)' : '';

    this.analytics.trackEvent(
      `Log ${Utils.capitalizeFirstLetter(
        challenge.data.log_type
      )} Challenge ${previousDayTextAppend}`,
      `Log Challenge ${challenge.data.analytics}`
    );
  }

  private async logBoolean(challenge: ChallengeModel, previousDay = false) {
    if (
      (challenge.loggedToday && previousDay === false) ||
      (challenge.loggedYesterday && previousDay === true)
    ) {
      return;
    }

    if (this.loggingChallenge) {
      return;
    }

    const mode = this.appModeService.getIonicModeStyle();
    const alert = await this.alertController.create({
      mode,
      header: this.translations.phrases.CHALLENGE_TRACK_PROGRESS,
      message: this.translations.phrases.CHALLENGE_TRACK_BOOLEAN_MESSAGE,
      buttons: [
        {
          text: this.translations.phrases.ALERT_BUTTON_CANCEL,
          role: 'cancel',
        },
        {
          text: this.translations.phrases.BUTTON_CONFIRM,
          handler: () => {
            this.loggingChallenge = true;

            this.challengeActionsService
              .logChallenge(challenge, null, previousDay)
              .then()
              .catch((err) => {
                this.errorDialog.showError(err, 'info');
              })
              .finally(() => {
                this.loggingChallenge = false;
              });
          },
        },
      ],
    });

    await alert.present();
  }

  private async openLogModal(challenge: ChallengeModel, previousDay = false) {
    const modal = await this.modalController.create({
      component: ChallengeLogModalPage,
      mode: 'md',
      componentProps: { challenge, previousDay },
      cssClass: 'c-ion-modal',
    });

    modal.present();
  }

  /* Private Methods */

  /**
   * Remove local challenge from provider
   *
   */
  private removeLocalChallenge(challenge: ChallengeModel) {
    this.dataProvider.data.user.challenges = this.dataProvider.data.user.challenges.filter(
      (element) => element.data.challenge_id !== challenge.data.challenge_id
    );
  }
}
