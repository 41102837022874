import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { DataProviderService } from '../data-provider/data-handler.service';

import { environment } from '../../../../environments/environment';
import { RollbarService } from '../rollbar/rollbar.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  /**
   * Overrides app exceptions and sends data to Rollbar and TrackingProvider.
   * @param error - error object to be tracked on Rollbar and Analytics
   */
  handleError(error) {
    const rollbar = this.injector.get(RollbarService);
    const provider = this.injector.get(DataProviderService);

    let user = null;
    let payload: any = null;

    try {
      user = {
        id: provider.data.user.info.wellabe_id,
        username: provider.data.user.info.wellabe_id,
      };
      // eslint-disable-next-line no-empty
    } catch (err) {}

    payload = {
      person: user,
      client: AppSettings.ROLLBAR_CLIENT,
    };

    if (this.shouldReport(error)) {
      rollbar.configure({
        captureIp: false,
        payload,
        scrubFields: ['user_ip'],
      });
      rollbar.critical(error);
    }

    if (environment.console.enabled) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  shouldReport(error) {
    if (!environment.rollbar.enabled) return false;
    const message = error?.error?.message || error?.message;

    if (!message) return false;

    const chunkLoadError = message.includes('ChunkLoadError');
    if (chunkLoadError) return false;

    const timeoutError = message.includes('ERROR_TIMEOUT');
    if (timeoutError) return false;

    const emailOnTheWay = message.includes('The email is on the way, next retry in 1 minute.');
    if (emailOnTheWay) return false;

    const noInternet = message.includes('ERROR_NO_INTERNET');
    if (noInternet) return false;

    const nullOrMissing = message.includes('Item sent with null or missing arguments.');
    if (nullOrMissing) return false;

    return true;
  }
}
