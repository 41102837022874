import { Injectable } from '@angular/core';
import { ApiService } from './api';

@Injectable({
  providedIn: 'root',
})
export class CobrandingApiService {
  constructor(private apiService: ApiService) {}

  getCobrandedPartner(voucher: string) {
    return this.apiService.get(`/app/partner?voucher=${voucher}`);
  }

  getPartnerExtraContent() {
    return this.apiService.get('/app/partner/extras');
  }
}
