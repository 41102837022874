import { Injectable } from '@angular/core';

import { ApiService } from './api';

import {
  MetadataLanguage,
  MetadataPhrase,
} from '../../shared/interfaces/api/metadata-api.interface';

@Injectable({
  providedIn: 'root',
})
export class MetadataApiService {
  constructor(private apiService: ApiService) {}

  getLanguages(): Promise<MetadataLanguage[]> {
    return this.apiService.get(`/metadata/languages`).toPromise();
  }

  getPhrasesByType(type: string): Promise<MetadataPhrase> {
    return this.apiService.get(`/metadata/phrases/${type}`).toPromise();
  }
}
