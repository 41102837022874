import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Questionnaire, QuestionnaireInProgress, Question } from './questionnaires.interface';
import { QuestionnaireStore } from './questionnaires.store';
import { QuestionnaireApiService } from '../../../core/api/questionnaire-api.service';
import { QuestionnairesQuery } from './questionnaires.query';

@Injectable({ providedIn: 'root' })
export class QuestionnairesService {
  constructor(
    private questionnaireQuery: QuestionnairesQuery,
    private questionnaireStore: QuestionnaireStore,
    private questionnaireService: QuestionnaireApiService
  ) {}

  /*
   * These routes route can have the possible queryParams for pre_checkup:
   * true => show questions that can be shown pre_checkup
   * false => show questions that can't be shown pre_checkup
   * null => show all questions
   */
  getAvailableQuestionnaires(pre_checkup = null): Observable<Questionnaire[]> {
    return this.questionnaireService.getQuestionnaires('available', pre_checkup);
  }

  getCompletedQuestionnaires(pre_checkup = null): Observable<Questionnaire[]> {
    return this.questionnaireService.getQuestionnaires('completed', pre_checkup);
  }

  getQuestionnaire(
    parameter: string,
    type: 'handle' | 'id' = 'id',
    voucher = null
  ): Observable<QuestionnaireInProgress> {
    return this.questionnaireService.getQuestionnaire(parameter, type, voucher).pipe(
      tap((res: QuestionnaireInProgress) => {
        const questions = [res.next_question];
        if (res.previous_question) {
          questions.push(res.previous_question);
        }

        const questionnaireInProgress = { ...res, questions };
        this.questionnaireStore.upsert(
          questionnaireInProgress.questionnaire_id,
          questionnaireInProgress
        );
      })
    );
  }

  postQuestion(
    questionnaire_id: string,
    question_id: string,
    answer: any,
    voucher: string = null
  ): Observable<Question> {
    // this is a workaround to sanitize the answer field, as the app changes the
    // structure on the line 42 of the question-answer.component
    // this.answer = value.answer.value;
    let answerValue = answer;
    if (answer && answer.value) {
      answerValue = answer.value;
    }

    return this.questionnaireService
      .postQuestion(questionnaire_id, question_id, { value: answerValue }, voucher)
      .pipe(
        tap((res: Question) => {
          const updatedQuestion =
            this.questionnaireQuery
              .getQuestionnaireById(questionnaire_id)
              .questions.find((question) => question.question_id === question_id) || {};

          const questions = this.questionnaireQuery
            .getQuestionnaireById(questionnaire_id)
            .questions.filter((question) => question.question_id !== question_id);

          questions.push({
            ...updatedQuestion,
            ...res,
          });

          const hasNextQuestion = res.next_question;
          const hasNextQuestionInCache =
            hasNextQuestion &&
            questions.find((question) => question.question_id === res.next_question.question_id);

          if (!hasNextQuestionInCache && hasNextQuestion) {
            questions.push(res.next_question);
          }

          this.questionnaireStore.upsert(questionnaire_id, {
            next_question: res,
            questions,
          });
        })
      );
  }

  getQuestion(
    questionnaire_id: string,
    question_id: string,
    voucher: string = null
  ): Observable<Question> {
    return this.questionnaireService.getQuestion(questionnaire_id, question_id, voucher).pipe(
      tap((res: Question) => {
        const previousId = res.previous_question ? res.previous_question.question_id : null;
        const questions = this.questionnaireQuery
          .getQuestionnaireById(questionnaire_id)
          .questions.filter((question) => {
            return question.question_id !== question_id && question.question_id !== previousId;
          });

        if (res.previous_question) {
          questions.push(res.previous_question);
        }

        this.questionnaireStore.upsert(questionnaire_id, { questions: [...questions, res] });
      })
    );
  }
}
