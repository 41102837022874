import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { Storage } from '@ionic/storage';
import { USER_SETTINGS } from '../../../shared/interfaces/settings.interface';
import { AccountStepType } from '../../../shared/interfaces/account.interface';
import { OFFLINE_SYNC_TYPES } from '../../../shared/types/offline-sync.types';
import { AppSettings } from '../../../app.settings';
import { OfflineData } from '../../../shared/interfaces/offline-data.interface';
import { Data } from '../../../shared/interfaces/data-provider.interface';
import { EventsService } from '../events/events.service';

@Injectable({
  providedIn: 'root',
})
export class DataProviderService {
  // Badges
  badges = {
    news: 0,
    challenges: 0,
  };

  public data: Data;

  private defaultSettings = USER_SETTINGS.DEFAULT_SETTINGS;

  private defaultSyncDateValues = USER_SETTINGS.DEFAULT_SYNC_DATE_VALUES;

  // The data we need to sync when connection is back
  private offlineData: OfflineData[];

  // Handles loaded state. Used mostly for deeplinking and live-reload
  private isLoaded = false;

  private oProvidedLoded = new BehaviorSubject(this.isLoaded);

  constructor(public storage: Storage, private eventsService: EventsService) {
    this.setDataToNull();

    this.setOfflineDataToNull();

    this.initOfflineData();
  }

  get userStep(): AccountStepType {
    if (this.data && this.data.user && this.data.user.info && this.data.user.info.step != null) {
      return this.data.user.info.step;
    }
    return null;
  }

  /// ///////////////////////////////////
  //          PUBLIC FUNCTIONS        //
  /// ///////////////////////////////////

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public setOfflineData(_type: OFFLINE_SYNC_TYPES, _data: any) {
    let noOldValue = true;

    let index = 0;

    switch (_type) {
      case OFFLINE_SYNC_TYPES.UPDATE_SETTINGS:
        for (const types of this.offlineData) {
          // If there's already an update_settings call waiting to be synced
          if (types.type === OFFLINE_SYNC_TYPES.UPDATE_SETTINGS) {
            types.data = _data;

            noOldValue = false;

            break;
          }
        }

        if (noOldValue) {
          this.offlineData.push({ type: _type, data: _data });
        }

        break;

      case OFFLINE_SYNC_TYPES.POST_ACTION:
      case OFFLINE_SYNC_TYPES.DELETE_SURVEYS:
      case OFFLINE_SYNC_TYPES.POST_SURVEYS:
        this.offlineData.push({ type: _type, data: _data });

        break;

      case OFFLINE_SYNC_TYPES.POST_TRACKING:
        // eslint-disable-next-line no-param-reassign
        _data.when = new Date();

        this.offlineData.push({ type: _type, data: _data });

        break;

      case OFFLINE_SYNC_TYPES.DELETE_FAVORITE:
      case OFFLINE_SYNC_TYPES.POST_FAVORITE:
        for (const types of this.offlineData) {
          // If there's already an favorite with the article_id waiting to be synced
          if (
            types.type === OFFLINE_SYNC_TYPES.POST_FAVORITE ||
            types.type === OFFLINE_SYNC_TYPES.DELETE_FAVORITE
          ) {
            if (types.data.article_id === _data.article_id) {
              this.offlineData.splice(index, 1);

              noOldValue = false;

              break;
            }
          }

          index += 1;
        }

        if (noOldValue) {
          this.offlineData.push({ type: _type, data: _data });
        }

        break;

      case OFFLINE_SYNC_TYPES.DELETE_CHALLENGE:
      case OFFLINE_SYNC_TYPES.POST_CHALLENGE:
        for (const types of this.offlineData) {
          // If there's already an favorite with the content_id waiting to be synced
          if (
            types.type === OFFLINE_SYNC_TYPES.POST_CHALLENGE ||
            types.type === OFFLINE_SYNC_TYPES.DELETE_CHALLENGE
          ) {
            if (types.data.challenge_id === _data.challenge_id) {
              this.offlineData.splice(index, 1);

              noOldValue = false;

              break;
            }
          }

          index += 1;
        }

        if (noOldValue) {
          this.offlineData.push({ type: _type, data: _data });
        }

        break;

      case OFFLINE_SYNC_TYPES.UPDATE_CHALLENGE:
        for (const types of this.offlineData) {
          // If there's already an update_settings call waiting to be synced
          if (types.type === OFFLINE_SYNC_TYPES.UPDATE_CHALLENGE) {
            types.data = _data;

            noOldValue = false;

            break;
          }
        }

        if (noOldValue) {
          this.offlineData.push({ type: _type, data: _data });
        }

        break;

      case OFFLINE_SYNC_TYPES.UPDATE_ACTION:
        for (const types of this.offlineData) {
          // If there's already an update_settings call waiting to be synced
          if (types.type === OFFLINE_SYNC_TYPES.UPDATE_ACTION) {
            types.data = _data;

            noOldValue = false;

            break;
          }
        }

        if (noOldValue) {
          this.offlineData.push({ type: _type, data: _data });
        }

        break;

      case OFFLINE_SYNC_TYPES.ARTICLE_READ:
        for (const types of this.offlineData) {
          // If there's already an article_read with the same ID call waiting to be synced
          if (types.type === OFFLINE_SYNC_TYPES.ARTICLE_READ) {
            if (types.data.article_id === _data.article_id) {
              types.data = _data;

              noOldValue = false;

              break;
            }
          }
        }

        if (noOldValue) {
          this.offlineData.push({ type: _type, data: _data });
        }

        break;
      case OFFLINE_SYNC_TYPES.ARTICLE_HIDE:
        for (const types of this.offlineData) {
          // If there's already an article_read with the same ID call waiting to be synced
          if (types.type === OFFLINE_SYNC_TYPES.ARTICLE_HIDE) {
            if (types.data.article_id === _data.article_id) {
              types.data = _data;

              noOldValue = false;

              break;
            }
          }
        }

        if (noOldValue) {
          this.offlineData.push({ type: _type, data: _data });
        }

        break;

      case OFFLINE_SYNC_TYPES.POST_DEVICE:
        for (const types of this.offlineData) {
          // If there's already an article_read with the same ID call waiting to be synced
          if (types.type === OFFLINE_SYNC_TYPES.POST_DEVICE) {
            noOldValue = false;
            break;
          }
        }

        if (noOldValue) {
          this.offlineData.push({ type: _type, data: _data });
        }

        break;

      case OFFLINE_SYNC_TYPES.PUT_FAVORITE:
        for (const types of this.offlineData) {
          // If there's already an favorite with the article_id waiting to be synced
          if (types.type === OFFLINE_SYNC_TYPES.PUT_FAVORITE) {
            if (types.data.article_id === _data.article_id) {
              this.offlineData.splice(index, 1);

              noOldValue = false;

              break;
            }
          }

          index += 1;
        }

        if (noOldValue) {
          this.offlineData.push({ type: _type, data: _data });
        }

        break;

      default:
        break;
    }

    this.saveOfflineData();
  }

  public saveOfflineData() {
    this.storage.set('_offline', JSON.stringify(this.offlineData));
  }

  public initOfflineData() {
    this.storage.get('_offline').then(
      (data) => {
        if (data) {
          this.offlineData = JSON.parse(data);
          if (this.offlineData.length > 0) {
            this.eventsService.offlineSyncSubject$.next();
          }
        }
      },
      () => {}
    );
  }

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public setOfflineDataToNull() {
    this.offlineData = [];
  }

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public getOfflineData() {
    return this.offlineData;
  }

  public popOfflineData() {
    this.offlineData.pop();
    this.saveOfflineData();
  }

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public setDataToNull() {
    const translations = {
      docs: [],
      phrases: [],
    };

    const settings = this.getDefaultSettings();

    let devEndpointFlag = false;
    let devModeFlag = false;

    if (this.data) {
      if (this.data.translations) {
        translations.phrases = this.data.translations;
      }

      if (this.data.docs) {
        translations.docs = this.data.docs;
      }

      if (this.data.settings.defaultLang) {
        settings.defaultLang = this.data.settings.defaultLang;
      }

      /* Save development endpoint flag */
      devEndpointFlag = this.data.isDevelopmentEndpoint;

      /* Save development flag */
      devModeFlag = this.data.settings.developmentMode;
    }

    const data: Data = {
      authentication: null,

      user: {
        challenges: [],
        questionnaires: [],
        completedQuestionnaires: [],
        availableChallenges: [],
        ongoingJourneys: [],
        recommendedJourneys: [],
        completedJourneys: [],

        blob: [],

        providers: [],

        news: null,
        read: [],

        favorites: [],

        info: null,
        messages: {
          pendingArticles: false,
        },

        checkups: [
          {
            notes: null,
          },
        ],

        tutorial: {
          checkups: 0,
          checkupsDetail: 0,
          challenges: 0,
          feed: 0,
          feedDetail: 0,
        },

        flags: null,
      },

      notifications: null,

      favorites: [],

      completed: [],

      translations: translations.phrases,

      docs: translations.docs,

      pushToken: null,

      sync: {
        syncDate: this.getDefaultSyncDataValues(),

        actions: [],
      },

      metadata: {
        languages: [],
        categories: [],
      },

      settings,

      lastSignIn: 0,

      isDevelopmentEndpoint: false,

      appVersion: AppSettings.APP_VERSION,
    };

    /* Set saved flags */
    data.isDevelopmentEndpoint = devEndpointFlag;
    data.settings.developmentMode = devModeFlag;

    this.setData(data);
  }

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public setData(_data: any) {
    this.data = _data;
  }

  public isProviderReady() {
    return this.oProvidedLoded;
  }

  public setProviderReady() {
    ('setProviderReady');

    this.isLoaded = true;
    this.oProvidedLoded.next(this.isLoaded);
  }

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public getData() {
    return this.data;
  }

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public getDefaultSettings() {
    return this.defaultSettings;
  }

  public getDefaultLocale() {
    return this.data.settings.defaultLang.locale;
  }

  public getUserLocale() {
    return this.data.user.info.language.locale;
  }

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public getDefaultSyncDataValues() {
    return this.defaultSyncDateValues;
  }

  public getMomentDateLocale(date: string, dateFormat: string) {
    const dateLocale = moment(date);

    const output = dateLocale
      .locale(this.data.settings.defaultLang.locale)
      .format(dateFormat)
      .replace(/^\./, '');

    let outputString = '';

    if (output.charAt(0) === '.') {
      outputString = output.substr(1);
    } else {
      outputString = output;
    }

    return outputString;
  }

  // TODO: Add interface
  public updateUser(userMutableData: any) {
    Object.assign(this.data.user, userMutableData);
  }

  /// ///////////////////////////////////
  //         PRIVATE FUNCTIONS        //
  /// ///////////////////////////////////
}
