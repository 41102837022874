/* eslint-disable no-console */
import * as moment from 'moment';

import { ExtraItemFlagResponse } from '../../interfaces/api/extra-item-api.interface';
import {
  ChallengeActivity,
  ChallengeReminder,
  ActivityAction,
} from '../../interfaces/challenges/activities.interface';
import {
  ChallengeData,
  ChallengeStatus,
  ChallengeMutableData,
} from '../../interfaces/challenges/challenge.interface';

export interface ChallengeGroupData {
  analytics: string;
  description: string; // HTML
  group_id: number;
  icon: string;
  name: string;
  pivot: {
    challenge_id: number;
    group_id: number;
  };
}

export interface ChallengeExtraData {
  analytics: string;
  extra_id: number;
  media: Array<string>;
  pivot: {
    challenge_id: number;
    extra_id: number;
    display_after: number;
  };
  title: string;
  flags: Array<ExtraItemFlagResponse>;
}

export interface ChallengeExtraDataComplete {
  extra_id: number;
  analytics: string;
  title: string;
  media: string[];
  text: string;
}

export interface ChallengeTipData {
  tip_id: number;
  title: string;
  pivot: {
    challenge_id: number;
    display_after: number;
    tip_id: number;
  };
}

export interface ChallengeImprovementData {
  group: {
    analytics: string;
    group_id: number;
    icon: string;
    name: string;
  };
  group_id: number;
  improvement_id: number;
  pivot: {
    challenge_id: number;
    improvement_id: number;
  };
  title: string;
}

export class ChallengeModel {
  public active_checkup: ChallengeActivity;

  public data: ChallengeData;

  public hasNewExtraItem = false;

  public loggedToday = false;

  public loggedYesterday = false;

  public reminders: ChallengeReminder[] = [];

  public todaysProgress = 0;

  public todaysValue = 0;

  public yesterdaysValue = 0;

  // TO-DO: Improve constructor parameters
  constructor(challengeData?: ChallengeData, processedData?: any) {
    if (challengeData) {
      this.data = challengeData;
    }

    if (processedData) {
      this.reminders = processedData.reminders || [];
    }

    this.prepare();
  }

  //
  /**
   *  Create the reminders for this challenge.
   *  This method sets the reminder property of this challenge
   *
   *  Todo: Break method into smaller pure functions
   */
  public createReminders(weekPeriod: number = 2) {
    if (
      this.data == null ||
      typeof this.data === undefined ||
      this.active_checkup == null ||
      this.active_checkup === undefined ||
      this.active_checkup.reminder_days.length === 0 ||
      this.active_checkup.reminder_time.length === 0
    ) {
      return;
    }

    const completedActions = this.active_checkup.actions.filter((action) => action.completed);

    const remainingDays = this.data.status.duration - completedActions.length;

    // This is just an approximation to avoid creating duplicate reminders
    const remainingReminders = remainingDays * this.active_checkup.reminder_time.length;

    const challengeReminders: ChallengeReminder[] = [];

    // Add actions for each week
    // eslint-disable-next-line no-plusplus
    for (let week = 0; week < weekPeriod + 1; week++) {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const keyDays in this.active_checkup.reminder_days) {
        const weekday: any = this.active_checkup.reminder_days[keyDays];

        const dayOfReminder = moment()
          .day(weekday + week * 7)
          .clone();

        if (this.shouldRemind(dayOfReminder, this.data.log_type)) {
          // Create a reminder for each time on this day
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const keyTime in this.active_checkup.reminder_time) {
            const timeOfReminder = this.active_checkup.reminder_time[keyTime];

            const day = moment(dayOfReminder.toISOString());

            const hourMinute = timeOfReminder.split(':');

            const whenToRemind = day.set({
              // tslint:disable-next-line:radix
              hour: parseInt(hourMinute[0], 10),
              // tslint:disable-next-line:radix
              minute: parseInt(hourMinute[1], 10),
              second: 0,
            });

            const now = moment();

            if (challengeReminders.length < remainingReminders && whenToRemind.isAfter(now)) {
              const notifyObj = {
                id: null,
                action_date: whenToRemind.utc().toISOString(),
              };
              challengeReminders.push(notifyObj);
            }
          }
        }
      }
    }

    this.reminders = challengeReminders;
  }

  public complete() {
    const currentActivity = this.getActiveActivity();
    this.setCompletedStatus(currentActivity);
    this.setActivityAsCompleted(currentActivity);
    this.resetModel();

    console.log('[ChallengeModel] Completed Challenge', this.data);
  }

  public getActionProgress() {
    return this.todaysProgress;
  }

  public getActiveActivity() {
    if (this.active_checkup) {
      return this.active_checkup;
    }

    return this.updateActiveActivity();
  }

  public getTodayActionValue() {
    return this.todaysValue;
  }

  public getYesterdayActionValue() {
    return this.yesterdaysValue;
  }

  public isCompleted(): boolean {
    return !!(this.data && this.data.status && this.data.status.name === 'completed');
  }

  public isLoggedToday() {
    if (this.isOngoing()) {
      const activeActivity = this.getActiveActivity();
      if (activeActivity && activeActivity.actions && activeActivity.actions.length > 0) {
        const lastLog = moment(
          activeActivity.actions[activeActivity.actions.length - 1].action_date
        );
        const daysSinceLastLog = lastLog.startOf('day').diff(moment().startOf('day'), 'day');
        if (daysSinceLastLog === 0) {
          this.loggedToday = true;
          return true;
        }
      }
    } else {
      this.loggedToday = false;
    }
    return false;
  }

  public wasLoggedYesterday() {
    if (this.isOngoing()) {
      const activeActivity = this.getActiveActivity();

      // safe check
      if (!activeActivity || !activeActivity.actions) {
        this.loggedYesterday = false;
        return false;
      }

      const today = moment().startOf('day');
      const yesterday = moment().startOf('day').subtract(1, 'day');

      // if activity started today, it doesn't need to log yesterday
      if (moment(activeActivity.created_at).startOf('day').isSame(today)) {
        this.loggedYesterday = true;
        return true;
      }

      if (activeActivity.actions.length > 0) {
        const yesterdayAction = activeActivity.actions.filter((action) => {
          return moment(action.action_date).startOf('day').isSame(yesterday);
        });

        if (yesterdayAction.length > 0) {
          this.loggedYesterday = true;
          return true;
        }
      }
    }

    this.loggedYesterday = false;
    return false;
  }

  public isOngoing(): boolean {
    return !!(this.data && this.data.status && this.data.status.name === 'ongoing');
  }

  public isPending(): boolean {
    return !!(this.data && this.data.status && this.data.status.name === 'pending');
  }

  public addJourneyId(journeyId: number) {
    this.data.journey_id = journeyId;
  }

  public hasMoreThanOneLoggedAction() {
    return !!(
      this.active_checkup &&
      this.active_checkup.actions &&
      this.active_checkup.actions.length > 1
    );
  }

  public hasOneLoggedAction() {
    return !!(
      this.active_checkup &&
      this.active_checkup.actions &&
      this.active_checkup.actions.length === 1
    );
  }

  public journeyName(): string {
    const { activities } = this.data;
    const activeCheckup = this.isCompleted()
      ? this.getLastCompletedActivity(activities)
      : this.getActiveActivity();
    if (activeCheckup && activeCheckup.journey) {
      return activeCheckup.journey.title;
    }
    return '';
  }

  /**
   * Logs an action (API) and update the challenge progress
   *
   */
  public logChallenge(action: ActivityAction) {
    this.addLoggedAction(action);
    this.updateProgress();
    this.process();

    if (this.shouldCompleteChallenge()) {
      this.complete();
    }
  }

  public quit(activity: ChallengeActivity) {
    this.removeActiveActivity();
    this.addPastActivity(activity);
    this.setPendingStatus();
    this.resetModel();
  }

  /**
   * Starts the challenge.
   *
   */
  public start(newActivity: ChallengeActivity) {
    this.addNewActivity(newActivity);
    this.setOngoingStatus(newActivity);
    this.updateActiveActivity();
    this.createReminders();
    this.process();
  }

  public updateLoggedValue(action: ActivityAction) {
    const challengeActivities = this.data.activities;
    const actionActivity = this.isCompleted()
      ? this.getLastCompletedActivity(challengeActivities)
      : this.getActiveActivity();

    if (actionActivity == null) {
      return;
    }

    this.updateAction(action, actionActivity);
    this.updateProgress();

    if (this.shouldCompleteChallenge()) {
      this.complete();
    }

    if (this.shouldSetAsOngoing()) {
      this.setOngoingStatus(actionActivity);
      this.setActivityAsOngoing(actionActivity);
      this.updateProgress();

      console.log('[ChallengeModel] set as Ongoing');
    }
  }

  /* Missing refactor: */

  public getTodayActivity() {
    const activity = this.getLastActiveActivity();

    const { actions } = activity;

    if (actions && actions.length > 0) {
      const lastDate = actions[actions.length - 1].action_date;

      if (moment(lastDate).startOf('day').isSame(moment().startOf('day'))) {
        return activity;
      }
    }
    return null;
  }

  public getLastActiveActivity() {
    if (this.active_checkup) {
      return this.active_checkup;
    }
    if (this.data && this.data.activities) {
      return this.data.activities[0];
    }

    return null;
  }

  public hasFlag(flagName: string) {
    let hasFlag = false;

    if (this.data.flags) {
      hasFlag =
        this.data.flags.filter((flag) => {
          return flag.flag === flagName;
        }).length >= 1;
    }
    return hasFlag;
  }

  public updateNewExtra() {
    if (this.data && this.data.status && this.data.extras) {
      const progress = (this.data.status.progress / this.data.status.duration) * 100;

      for (const extra of this.data.extras) {
        if (extra.pivot && parseInt(extra.pivot.display_after, 10) <= progress) {
          if (extra.flags) {
            let opened = false;
            for (const flag of extra.flags) {
              if (flag.flag === 'opened') {
                opened = true;
                break;
              }
            }
            if (!opened) {
              this.hasNewExtraItem = true;
              return;
            }
          } else {
            this.hasNewExtraItem = true;
            return;
          }
        } else {
          break;
        }
      }
    }
    this.hasNewExtraItem = false;
  }

  public updateActiveActivity() {
    if (!this.isOngoing()) {
      return null;
    }

    const challengeActivities = this.data.activities ? this.data.activities : [];

    const ongoingActivities = challengeActivities.filter((activity) => {
      return activity.status === 'ongoing';
    });

    const lastOngoingActivity = ongoingActivities.length > 0 ? ongoingActivities[0] : null;

    this.active_checkup = lastOngoingActivity;

    return lastOngoingActivity;
  }

  public updateProgress() {
    if (this.data && this.data.status) {
      const challengeActivities = this.data.activities;
      const activeActivity = this.isCompleted()
        ? this.getLastCompletedActivity(challengeActivities)
        : this.getActiveActivity();

      const completedActions = activeActivity.actions.filter((action) => {
        return action.completed;
      });

      this.data.status.progress = completedActions.length;
    }

    this.updateTodaysValue();
    this.updateYesterdaysValue();
    this.updateActionProgress();
  }

  public updateActiveCheckup(newActivity) {
    if (this.data.activities) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.data.activities) {
        if (this.data.activities[key].status === 'ongoing') {
          this.data.activities[key] = newActivity;
          this.active_checkup = newActivity;
          return newActivity;
        }
      }
    }
    return null;
  }

  public updateData(data: ChallengeData) {
    this.data = data;
  }

  /**
   * Process challenge data that can change depends on the challenge progress
   *
   */
  public process() {
    this.updateNewExtra();
    this.isLoggedToday();
    this.wasLoggedYesterday();
    this.updateTodaysValue();
    this.updateYesterdaysValue();
    this.updateActionProgress();
  }

  private updateAction(action: ActivityAction, actionActivity: ChallengeActivity) {
    const { actions } = actionActivity;

    if (actions.length === 0) {
      return;
    }

    const index = actions.findIndex(
      (act) => act.challenge_action_id === action.challenge_action_id
    );

    if (index >= 0) {
      actions[index] = action;
      console.log('[ChallengeModel] Updated action object');
    }
  }

  /* Private methods */

  /**
   * Add a logged action to the actions array. This mutates the actions array.
   *
   */
  private addLoggedAction(action: ActivityAction): void {
    const currentActivity = this.getActiveActivity();

    if (currentActivity == null) {
      return;
    }

    const allActions = currentActivity.actions.concat([action]);

    const sortedActions = allActions.sort((a, b) => {
      const aDate = moment(a.action_date).toDate();
      const bDate = moment(b.action_date).toDate();

      // eslint-disable-next-line no-nested-ternary
      return aDate > bDate ? 1 : aDate < bDate ? -1 : 0;
    });

    currentActivity.actions = sortedActions;
  }

  /**
   *  Add a new activity to the current activities of this challenge
   *
   */
  private addNewActivity(challengeActivity: ChallengeActivity): void {
    let activities = this.data.activities ? this.data.activities : [];

    activities = [challengeActivity].concat(activities);

    this.updateChallengeData({ activities });
  }

  private addPastActivity(activity: ChallengeActivity) {
    if (this.data && this.data.activities) {
      this.data.activities.unshift(activity);
    }
  }

  private getLastCompletedActivity(activities: ChallengeActivity[]) {
    if (activities == null || activities === []) {
      return null;
    }

    const completedActivities = activities.filter((activity) => activity.status === 'completed');

    return completedActivities.length > 0 ? completedActivities[0] : null;
  }

  private prepare() {
    if (typeof this.data.reminder_days === 'string') {
      this.data.reminder_days = JSON.parse(this.data.reminder_days);
    }
    if (typeof this.data.reminder_time === 'string') {
      this.data.reminder_time = JSON.parse(this.data.reminder_time);
    }

    this.updateActiveActivity();
    this.createReminders();
    this.process();
  }

  private removeActiveActivity() {
    this.active_checkup = null;
  }

  private resetModel() {
    this.reminders = [];
    this.loggedToday = false;
    this.hasNewExtraItem = false;
    this.active_checkup = null;
  }

  private setActivityAsCompleted(challengeActivity: ChallengeActivity): void {
    if (challengeActivity) {
      // eslint-disable-next-line no-param-reassign
      challengeActivity.status = 'completed';
    }
  }

  private setActivityAsOngoing(challengeActivity: ChallengeActivity): void {
    if (challengeActivity) {
      // eslint-disable-next-line no-param-reassign
      challengeActivity.status = 'ongoing';
    }
  }

  private setCompletedStatus(challengeActivity: ChallengeActivity): void {
    const status: ChallengeStatus = {
      name: 'completed',
      duration: challengeActivity.duration,
      progress: challengeActivity.duration,
      completed_at: moment().toString(),
    };

    this.updateChallengeData({ status });
  }

  /**
   * Set the challenge status as ongoing, resetting the progress
   */
  private setOngoingStatus(challengeActivity: ChallengeActivity): void {
    const status: ChallengeStatus = {
      name: 'ongoing',
      duration: challengeActivity.duration,
      progress: 0,
      completed_at: null,
    };

    this.updateChallengeData({ status });
  }

  private setPendingStatus(): void {
    const status: ChallengeStatus = {
      name: 'pending',
      duration: null,
      progress: 0,
      completed_at: null,
    };

    this.updateChallengeData({ status });
  }

  private shouldCompleteChallenge() {
    if (
      this.isOngoing() &&
      this.data &&
      this.data.status &&
      this.data.status.progress >= this.data.status.duration
    ) {
      return true;
    }

    return false;
  }

  /**
   * Checks either a reminder should be created given a reminderDate (day).
   */
  private shouldRemind(reminderDate: moment.Moment, logType: string): boolean {
    const dateClone = reminderDate.clone().startOf('day');
    const today = moment().startOf('day');

    const shouldCreateReminder = !!(
      logType === 'incremental' ||
      !this.loggedToday ||
      (this.loggedToday && dateClone.diff(today, 'day') >= 1)
    );

    return shouldCreateReminder;
  }

  private shouldSetAsOngoing() {
    if (
      this.isCompleted() &&
      this.data &&
      this.data.status &&
      this.data.status.progress < this.data.status.duration
    ) {
      return true;
    }
    return false;
  }

  /**
   * Update challenge data, ideally only this method should be called for mutating the challenge data
   */
  private updateChallengeData(newData: ChallengeMutableData) {
    Object.assign(this.data, newData);
  }

  private updateActionProgress(): void {
    switch (this.data.log_type) {
      case 'boolean':
      case 'single':
        this.todaysProgress = this.loggedToday ? 100 : 0;
        break;
      case 'incremental':
        // eslint-disable-next-line no-case-declarations
        const valueToday = this.getTodayActionValue();

        console.log(`[ChallengeModel] getActionProgress ${valueToday}`);
        if (
          Number.isNaN(valueToday) ||
          Number.isNaN(this.data.log_threshold) ||
          this.data.log_threshold === 0 ||
          this.data.log_threshold == null ||
          this.data.log_threshold === undefined
        ) {
          return;
        }

        // eslint-disable-next-line no-case-declarations
        const progress = (valueToday / this.data.log_threshold) * 100;

        // Cap value at 1000
        this.todaysProgress = Math.min(Math.max(progress, 0), 100);
        break;
      default:
        break;
    }
  }

  private updateTodaysValue() {
    const activeActivity = this.getActiveActivity();

    console.log(`[ChallengeModel] updateTodaysValue`, activeActivity);
    if (activeActivity) {
      const { actions } = activeActivity;
      if (actions.length === 0) {
        this.todaysValue = 0;
      }

      const lastAction = actions[actions.length - 1];

      if (
        lastAction &&
        moment(lastAction.action_date).startOf('day').isSame(moment().startOf('day'))
      ) {
        const lastValue = lastAction.value;
        this.todaysValue = lastValue;
      }
    }
  }

  private updateYesterdaysValue() {
    const activeActivity = this.getActiveActivity();

    // console.log(`[ChallengeModel] updateYesterdaysValue`, activeActivity);
    if (activeActivity) {
      const { actions } = activeActivity;
      if (actions.length === 0) {
        this.yesterdaysValue = 0;
      }

      const yesterday = moment().startOf('day').subtract(1, 'day');

      const yesterdayAction = activeActivity.actions.filter((action) => {
        return moment(action.action_date).startOf('day').isSame(yesterday);
      });

      if (yesterdayAction.length) {
        const lastValue = yesterdayAction[0].value;
        this.yesterdaysValue = lastValue;
      }
    }
  }
}
