import { Injectable } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { filter, first, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppModeStore, PlatformType } from './app-mode.store';
import { AppModeQuery } from './app-mode.query';

@Injectable({
  providedIn: 'root',
})
export class AppModeService {
  isInitialized$ = new BehaviorSubject<boolean>(false);

  private platform: DeviceInfo['platform'];

  private devicePlatform: DeviceInfo['platform'];

  private initialized = false;

  constructor(private appModeStore: AppModeStore, private appModeQuery: AppModeQuery) {}

  async init() {
    const { platform } = await Device.getInfo();

    this.devicePlatform = platform;

    const platformType = this.appModeQuery.getPlatformValue();

    this.setPlatformSubscription();

    if (platformType || this.platform) {
      this.isInitialized$.next(true);
      return;
    }

    const url = window.location.href;

    const isAppRoute = url.includes('/app.');

    const isLocal =
      environment.currentEnv === 'development' ||
      url.includes(':8100') ||
      url.includes('localhost') ||
      url.includes(':4200');

    const isNative = platform !== 'web';

    const isWebE2EEnvironment = environment.currentEnv === 'development-web';

    const isAppE2EEnvironment = environment.currentEnv === 'development-app';

    // Turns platform to web on web-registration E2E test environment
    if (isWebE2EEnvironment) {
      this.updateAppModePlatform('web');
    } else if (isAppE2EEnvironment) {
      // Turns platform to web on app E2E test environment
      this.updateAppModePlatform('app');
    } else if (!isAppRoute && !isLocal && !isNative) {
      // Check if the platform is suitable for web-registration in production
      this.updateAppModePlatform('web');
    } else {
      // Standard setting for app on production and local testing
      this.updateAppModePlatform('app');
    }

    this.isInitialized$.next(true);
  }

  async waitForInitialization() {
    if (this.initialized) return true;

    // Wait for setup to complete before proceeding
    return this.isInitialized$
      .pipe(
        filter((value) => value === true),
        first(),
        tap(() => {
          this.initialized = true;
        })
      )
      .toPromise();
  }

  /**
   * Subscription set at service init to set the saved platform value as the platform to be converted to.
   * Ignores if it is the first entry and there is no platform on the store yet.
   */
  setPlatformSubscription() {
    this.appModeQuery.platform$.subscribe((platformType) => {
      if (!platformType || !this.devicePlatform) {
        return;
      }

      if (platformType === 'web') {
        this.platform = this.devicePlatform;
      } else {
        this.platform = this.devicePlatform === 'web' ? 'ios' : this.devicePlatform;
      }
    });
  }

  getPlatform() {
    return this.platform;
  }

  isWeb() {
    return this.platform === 'web';
  }

  isNative() {
    return this.platform === 'android' || this.platform === 'ios';
  }

  getIonicModeStyle(): 'ios' | 'md' {
    return this.platform === 'android' ? 'md' : 'ios';
  }

  async getIsMobileDevice(): Promise<boolean> {
    const { platform } = await Device.getInfo();

    return platform !== 'web';
  }

  getIsForcingMobile() {
    const platformType: PlatformType = this.appModeQuery.getPlatformValue();

    return platformType === 'app';
  }

  updateAppModePlatform(platform: PlatformType) {
    this.appModeStore.update({ platform });
  }
}
