import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppointmentsQuery } from './appointments.query';
import { AppointmentsStore } from './appointments.store';
import { AppointmentApiService } from '../../../core/api/appointment-api.service';
import { BookingApiService } from '../../../core/api/booking-api.service';
import { PutAppointmentBody } from '../../../shared/interfaces/api/package-api.interface';
import { CobrandingQuery, CobrandingService } from '../../cobranding/state';

@Injectable({ providedIn: 'root' })
export class AppointmentsService {
  constructor(
    private appointmentsQuery: AppointmentsQuery,
    private appointmentsStore: AppointmentsStore,
    private appointmentApi: AppointmentApiService,
    private bookingApi: BookingApiService,
    private cobrandingService: CobrandingService,
    private cobrandingQuery: CobrandingQuery
  ) {}

  getPastAppointments() {
    return this.appointmentApi.getAppointments('past').pipe(
      tap((past) => {
        this.appointmentsStore.update({ past });
      })
    );
  }

  getUpcomingAppointments() {
    return this.appointmentApi.getAppointments('upcoming').pipe(
      tap((upcoming) => {
        this.appointmentsStore.update({ upcoming });
      })
    );
  }

  putAppointment(currentAppointmentId: number, payload: PutAppointmentBody) {
    return this.bookingApi.putAppointment(currentAppointmentId, payload).pipe(
      tap((newAppointment) => {
        this.appointmentsStore.update((state) => {
          // Replaces old appointment
          const upcoming = state.upcoming.map((appointment) =>
            appointment.appointment_id === currentAppointmentId ? newAppointment : appointment
          );

          return { ...state, upcoming };
        });
      })
    );
  }

  // remove all appointments from package
  // e.g. checkup and teleconsultation
  removeAppointmentFromStore(appointmentId: number) {
    const packagedId = this.appointmentsQuery.upcomingAppointmentPackagedId(appointmentId);

    this.appointmentsStore.update((state) => {
      const upcoming = state.upcoming.filter((appointment) => {
        if (!appointment.package) {
          return false;
        }

        return appointment.package.package_id !== packagedId;
      });

      return { ...state, upcoming };
    });
  }

  checkCurrentPartnerAppointment() {
    return this.getUpcomingAppointments().pipe(
      switchMap((response) => {
        if (response.length === 0) {
          return this.getPastAppointments();
        }

        return of(response);
      }),
      switchMap((appointments) => {
        if (appointments.length === 0) {
          this.cobrandingService.resetStore();
          return of();
        }

        const storedVoucher = this.cobrandingQuery.getCurrentVoucher();
        const voucher = appointments[appointments.length - 1].package.voucher || '';

        if (!voucher || voucher === storedVoucher) {
          return of();
        }

        return this.cobrandingService.getPartnerDetails(voucher);
      })
    );
  }
}
