export interface TeleConsultationMessage {
  type: TeleConsultationMessageType;
  data: any;
}

export enum TeleConsultationMessageType {
  IMPERSONATE,
  HANDSHAKE,
  QUERY,
  ERROR,
}
