import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResultsState, ResultsStore } from './results.store';
import { BiomarkerGroup } from '../../../shared/interfaces/api/results-api.interface';
import { BiomarkerGroupComparsionDataProp } from '../components/biomarker-group-comparison-card/biomarker-group-comparison-card.interface';

@Injectable({
  providedIn: 'root',
})
export class ResultsQuery extends QueryEntity<ResultsState, BiomarkerGroup> {
  loading$ = this.selectLoading();

  error$ = this.selectError();

  groups$ = this.selectAll();

  reviews$ = this.select((state) => state.reviews);

  healthSummary$ = this.select((state) => state.healthSummary);

  overallAge$: Observable<number> = this.selectAll().pipe(
    map((entities) => {
      if (!entities) {
        return null;
      }

      const groups = entities;
      const allAges = groups.map((group) =>
        group.ages && group.ages.length ? group.ages[group.ages.length - 1] : null
      );
      const validAges = allAges.filter((age) => age && age.value !== null);

      let overallAge = null;

      if (validAges.length > 0) {
        const totalAge = validAges.reduce((accumulator, current) => accumulator + current.value, 0);
        overallAge = Math.round(totalAge / validAges.length);
      }

      return overallAge;
    })
  );

  constructor(protected store: ResultsStore) {
    super(store);
  }

  getReviews() {
    return this.getValue().reviews;
  }

  getAgesNormalized() {
    return this.select((state) => {
      if (
        !state.healthSummary ||
        !state.healthSummary.ages ||
        state.healthSummary.ages.length < 1
      ) {
        return null;
      }

      const ages = state.healthSummary.ages.filter((age) => age.value !== null);
      const values = ages.map((age) => age.value);

      const total = values.reduce((prev, cur) => prev + cur, 0);
      const overallAge = Math.round(total / values.length);

      const max = values.reduce((prev, cur) => (prev > cur ? prev : cur), 0);
      const min = values.reduce((prev, cur) => (prev < cur ? prev : cur), 200);
      const diffFromMax = Math.abs(max - state.healthSummary.user.age);
      const diffFromMin = Math.abs(state.healthSummary.user.age - min);

      // if our range is close to zero, it means two things:
      // 1. all our group ages are equal
      // 2. we are doing a division by 0
      // as such, a minimum range is set here
      let range = 2 * Math.max(diffFromMax, diffFromMin);
      if (range < 0.1) {
        range = 1;
      }
      const minRange = state.healthSummary.user.age - range;

      const overallGroup = {
        icon: null,
        value: overallAge,
        percentage: (state.healthSummary.user.calculated_age - minRange) / (2 * range),
        date: null,
        isOverall: true,
      };

      const mappedAges: BiomarkerGroupComparsionDataProp[] = ages.map((age) => {
        const percentage = (age.value - minRange) / (2 * range);
        return {
          percentage,
          icon: age.group.icon,
          value: age.value,
          date: age.calculated_at,
          isOverall: false,
        };
      });

      return [overallGroup, ...mappedAges];
    });
  }
}
