import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {
  BiomarkerGroup,
  HealthSummary,
} from '../../../shared/interfaces/api/results-api.interface';
import {
  CheckupApiResponse,
  CheckupReview,
} from '../../../shared/interfaces/api/checkup-api.interface';

export interface ResultsState extends EntityState<BiomarkerGroup> {
  reviews: CheckupReview[];
  notes: CheckupApiResponse;
  healthSummary: HealthSummary;
}

export function createInitialState(): ResultsState {
  return {
    reviews: [],
    notes: null,
    healthSummary: null,
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({
  name: 'results',
  idKey: 'group_id',
  resettable: true,
  cache: {
    ttl: 30000,
  },
})
export class ResultsStore extends EntityStore<ResultsState, BiomarkerGroup> {
  constructor() {
    super(createInitialState());
    this.setHasCache(false, { restartTTL: true });
  }
}
