import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import * as moment from 'moment';
import { Device } from '@ionic-native/device/ngx';
import { Subscription } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { PostUserDeviceBody, PutUserBody } from '../../../shared/interfaces/api/user-api.interface';
import { ArticleData } from '../../../shared/interfaces/api/article-api.interface';
import { NetworkService } from '../network/network.service';
import { UserApiService } from '../../api/user-api.service';
import { JourneyApiService } from '../../api/journey-api.service';
import { DeveloperApiService } from '../../api/developer-api.service';
import { OFFLINE_SYNC_TYPES } from '../../../shared/types/offline-sync.types';
import { DataProviderService } from '../data-provider/data-handler.service';

import 'moment-timezone';

import { ArticleApiService } from '../../api/article-api.service';
import { QuestionnaireApiService } from '../../api/questionnaire-api.service';
import { CONTENT_TYPES, CONTENT_TYPES_SYNC_TIME } from '../../../shared/types/content.types';
import { AccountApiService } from '../../api/account-api.service';
import { EventsService } from '../events/events.service';
import { Data } from '../../../shared/interfaces/data-provider.interface';

@Injectable({
  providedIn: 'root',
})
export class OfflineSyncService {
  devLoadedChallenge = null;

  private hasSyncedAlreay = false;

  private offlineSyncSubscription: Subscription;

  constructor(
    public dataProvider: DataProviderService,
    public storageService: StorageService,
    public networkService: NetworkService,
    public platform: Platform,
    public network: Network,
    public device: Device,
    private userApiService: UserApiService,
    private eventsService: EventsService,
    private articleApiService: ArticleApiService,
    private questionnaireApiService: QuestionnaireApiService,
    private journeyApiService: JourneyApiService,
    private accountApiService: AccountApiService,
    private developerApiService: DeveloperApiService
  ) {
    this.network.onConnect().subscribe(() => {
      if (dataProvider.getOfflineData().length !== 0 && !this.hasSyncedAlreay) {
        this.syncOfflineData();

        this.hasSyncedAlreay = true;
      }
    });

    this.network.onDisconnect().subscribe(() => {
      this.hasSyncedAlreay = false;
    });

    if (!this.offlineSyncSubscription) {
      this.setOfflineSyncSubscription();
    }
  }

  /// ///////////////////////////////////
  //          PUBLIC FUNCTIONS        //
  /// ///////////////////////////////////

  public postDevice() {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      if (this.dataProvider.data.authentication == null) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(false);
        return;
      }

      const requestData: PostUserDeviceBody = {
        cordova: this.device.cordova,
        isVirtual: this.device.isVirtual,
        manufacturer: this.device.manufacturer,
        model: this.device.model,
        platform: this.device.platform,
        serial: this.device.serial,
        uuid: this.device.uuid,
        version: this.device.version,
        token: this.dataProvider.data.pushToken,
      };

      if (!this.platform.is('cordova')) {
        resolve(true);
      } else if (!this.networkService.online()) {
        this.dataProvider.setOfflineData(OFFLINE_SYNC_TYPES.POST_DEVICE, requestData);

        reject();
      } else {
        this.userApiService.postUserDevice(requestData).then(
          (success) => {
            resolve(success);
          },
          (err) => {
            reject(err);
          }
        );
      }
    });

    return promise;
  }

  /*
    * Description:
    * Inputs:
        Data should contain the settings object with all the fields bellow:
        data: {
            settings : {
                "language_id": string,
            }
        }
    * Outputs:
    */
  public updateSettings(data: Data = this.dataProvider.data) {
    let promise = null;
    const requestData: PutUserBody = {};

    if (data && data.settings && data.settings.defaultLang.language_id) {
      requestData.language_id = +data.settings.defaultLang.language_id;
    }

    requestData.timezone_name = moment.tz.guess();

    promise = new Promise((resolve, reject) => {
      if (!this.networkService.online()) {
        this.dataProvider.setOfflineData(OFFLINE_SYNC_TYPES.UPDATE_SETTINGS, data);

        reject();
      } else {
        this.accountApiService.putUser(requestData).then(
          (success) => {
            resolve(success);
          },
          (err) => {
            reject(err);
          }
        );
      }
    });

    return promise;
  }

  /// ///////////////////////////////////
  //     CONTENT GETTERS / POSTERS    //
  /// ///////////////////////////////////

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public getContents(avoidSyncTimeCheck: boolean): Promise<ArticleData[]> {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      if (
        this.hasReachedUpdateTime(CONTENT_TYPES.NEWS) ||
        avoidSyncTimeCheck ||
        this.dataProvider.data.user.news == null
      ) {
        this.articleApiService.getRecommendedArticles().then(
          (success) => {
            this.dataProvider.data.sync.syncDate.news = new Date().getTime();

            resolve(success);
          },
          (err) => {
            reject(err);
          }
        );
      } else if (this.dataProvider.data.user.news) {
        resolve(this.dataProvider.data.user.news);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject([]);
      }
    });

    return promise;
  }

  /*
   * Description:
   * Inputs: data -> the content_id
   * Outputs:
   */
  public postSingleContentRead(content): Promise<any> {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      const requestData = {
        authentication: this.dataProvider.data.authentication,
        article_id: content.article_id,
      };

      if (!this.networkService.online()) {
        this.dataProvider.setOfflineData(OFFLINE_SYNC_TYPES.ARTICLE_READ, requestData);

        reject();
      } else {
        this.articleApiService.setArticleReadById(requestData.article_id).then(
          (success) => {
            resolve(success);
          },
          (err) => {
            reject(err);
          }
        );
      }
    });

    return promise;
  }

  /// ///////////////////////////////////
  //          OTHER FUNCTIONS         //
  /// ///////////////////////////////////

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public syncOfflineData() {
    const index = this.dataProvider.getOfflineData().length - 1;

    switch (this.dataProvider.getOfflineData()[index].type) {
      case OFFLINE_SYNC_TYPES.UPDATE_SETTINGS:
        this.updateSettings(this.dataProvider.getOfflineData()[index].data).then(
          () => {
            this.dataProvider.popOfflineData();

            if (this.dataProvider.getOfflineData().length !== 0) {
              this.syncOfflineData();
            }
          },
          () => {}
        );

        break;

      case OFFLINE_SYNC_TYPES.ARTICLE_READ:
        this.postSingleContentRead(this.dataProvider.getOfflineData()[index].data).then(
          () => {
            this.dataProvider.popOfflineData();

            if (this.dataProvider.getOfflineData().length !== 0) {
              this.syncOfflineData();
            }
          },
          () => {}
        );

        break;

      case OFFLINE_SYNC_TYPES.POST_DEVICE:
        this.postDevice().then(
          () => {
            this.dataProvider.popOfflineData();

            if (this.dataProvider.getOfflineData().length !== 0) {
              this.syncOfflineData();
            }
          },
          () => {}
        );
        break;

      default:
        break;
    }
  }

  public getSingleContent(content: { content_id: number }, type = 'article') {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      let getPromise: Promise<any> = null;
      if (type === 'article') {
        getPromise = this.articleApiService.getDeveloperArticleById(content.content_id);
      } else if (type === 'questionnaire') {
        getPromise = this.questionnaireApiService
          .getQuestionnaire(String(content.content_id))
          .toPromise();
      } else if (type === 'journey') {
        getPromise = this.journeyApiService.getJourney(content.content_id);
      } else if (type === 'challenge') {
        getPromise = this.developerApiService.getDeveloperChallenge(content.content_id).toPromise();
      }
      if (getPromise == null) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Wrong content type');
        return;
      }

      getPromise
        .then((success) => {
          if (type === 'challenge') {
            this.devLoadedChallenge = success;
          }

          resolve(success);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  }

  /// ///////////////////////////////////
  //         PRIVATE FUNCTIONS        //
  /// ///////////////////////////////////

  /*
   * Description:
   */

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  private hasReachedUpdateTime(type: CONTENT_TYPES) {
    let timeSinceLastRefresh = 0;

    switch (type) {
      case CONTENT_TYPES.NEWS:
        timeSinceLastRefresh = new Date().getTime() - this.dataProvider.data.sync.syncDate.news;

        if (timeSinceLastRefresh > CONTENT_TYPES_SYNC_TIME.NEWS) {
          return true;
        }
        break;

      case CONTENT_TYPES.SURVEY:
        timeSinceLastRefresh = new Date().getTime() - this.dataProvider.data.sync.syncDate.surveys;

        if (timeSinceLastRefresh > CONTENT_TYPES_SYNC_TIME.SURVEY) {
          return true;
        }
        break;

      case CONTENT_TYPES.CHALLENGE:
        timeSinceLastRefresh =
          new Date().getTime() - this.dataProvider.data.sync.syncDate.challenge;

        if (timeSinceLastRefresh > CONTENT_TYPES_SYNC_TIME.CHALLENGE) {
          return true;
        }
        break;

      case CONTENT_TYPES.ARTICLE:
        timeSinceLastRefresh = new Date().getTime() - this.dataProvider.data.sync.syncDate.article;

        if (timeSinceLastRefresh > CONTENT_TYPES_SYNC_TIME.ARTICLE) {
          return true;
        }
        break;

      case CONTENT_TYPES.CHECKUP:
        timeSinceLastRefresh = new Date().getTime() - this.dataProvider.data.sync.syncDate.checkups;

        if (timeSinceLastRefresh > CONTENT_TYPES_SYNC_TIME.CHECKUP) {
          return true;
        }
        break;
      case CONTENT_TYPES.AVAILABLE_CHALLENGES:
        timeSinceLastRefresh =
          new Date().getTime() - this.dataProvider.data.sync.syncDate.availableChallenges;

        if (timeSinceLastRefresh > CONTENT_TYPES_SYNC_TIME.NEWS) {
          return true;
        }
        break;
      default:
        break;
    }

    return false;
  }

  private setOfflineSyncSubscription() {
    this.offlineSyncSubscription = this.eventsService.offlineSyncSubject$.subscribe(() => {
      this.syncOfflineData();
    });
  }
}
