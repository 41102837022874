/* eslint-disable max-classes-per-file */

// eslint-disable-next-line @typescript-eslint/naming-convention
export class CONTENT_TYPES {
  public static readonly NEWS = 'news';

  public static readonly SURVEY = 'survey';

  public static readonly CHALLENGE = 'challenge';

  public static readonly ARTICLE = 'article';

  public static readonly CHECKUP = 'checkup';

  public static readonly AVAILABLE_CHALLENGES = 'availableChallenges';

  public static readonly UNDEFINED = 'undefined';
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export class CONTENT_TYPES_SYNC_TIME {
  public static readonly NEWS = 1000; // 10 minutes

  public static readonly SURVEY = 60 * 1000; // One Minute

  public static readonly CHALLENGE = 60 * 1000; // One Minute

  public static readonly ARTICLE = 60 * 1000; // One Minute

  public static readonly CHECKUP = 60 * 60 * 1000; // One hour
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export class SURVEY_ANSWER_TYPES {
  public static readonly MULTIPLE = 'multiple';

  public static readonly SINGLE = 'single';

  public static readonly DATETIME = 'date_time';

  public static readonly DATE = 'date';

  public static readonly TIME = 'time';

  public static readonly BOOLEAN = 'boolean';

  public static readonly NUMBER = 'number';

  public static readonly INTEGER = 'integer';

  public static readonly LIKERT = 'likert';

  public static readonly STRING = 'string';
}
