import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export type PlatformType = 'web' | 'app';

export interface PlatformState {
  platform: PlatformType;
}

export function createInitialState() {
  const emptyState = {
    platform: undefined,
  };

  return emptyState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'app-mode',
})
export class AppModeStore extends Store<PlatformState> {
  constructor() {
    super(createInitialState());
  }
}
