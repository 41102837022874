import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppModeService } from '../../services/app-mode/app-mode.service';

@Injectable({
  providedIn: 'root',
})
export class WebGuard implements CanActivate {
  constructor(private appModeService: AppModeService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.appModeService.isWeb()) {
      this.router.navigate(['/home'], { queryParamsHandling: 'merge' });
      return false;
    }

    return true;
  }
}
