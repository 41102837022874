import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Storage } from '@ionic/storage';
import { persistState } from '@datorama/akita';
import { debounceTime } from 'rxjs/operators';
import { ChallengeModel } from '../../../shared/models/challenge/challenge.model';
import { DataProviderService } from '../data-provider/data-handler.service';
import { IonicStorageService } from './ionic-storage.service';
import { AppointmentsStore } from '../../../modules/appointments/state';
import { ResultsStore } from '../../../modules/results/state/results.store';

const THROTTLE_TIME = 400;
const THROTTLE_ATTEMPTS = 15;
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  // Used to throttle saving data
  lastRequestToSave = null;

  // Number of attempts made without being saved by the throttling
  saveAttempts = 0;

  akitaStorage: {
    destroy(): void;
    clear(): void;
    clearStore(storeName?: string): void;
  } = null;

  constructor(
    public dataProvider: DataProviderService,
    public ionicStorage: IonicStorageService,
    public storage: Storage,
    public platform: Platform,
    private resultsStore: ResultsStore,
    private appointmentsStore: AppointmentsStore
  ) {}

  /**
   * Fetch locally saved data and loads it into place
   */
  public init() {
    let promise = null;

    this.akitaStorage = persistState({
      preStorageUpdateOperator: () => debounceTime(500),
      storage: this.ionicStorage,
    });

    promise = new Promise((resolve, reject) => {
      const t0 = performance.now();
      this.storage.get('_userData').then(
        (success) => {
          if (success) {
            this.dataProvider.setData(JSON.parse(success));

            if (this.dataProvider.data.user == null) {
              this.dataProvider.setDataToNull();
            }

            // Minor fix for keeping app stable when user updates
            if (typeof this.dataProvider.data.settings === 'undefined') {
              this.dataProvider.data.settings = this.dataProvider.getDefaultSettings();
            }

            if (typeof this.dataProvider.data.sync.syncDate === 'undefined') {
              this.dataProvider.data.sync.syncDate = this.dataProvider.getDefaultSyncDataValues();
            }

            // Compatibility < 0.2.20
            if (typeof this.dataProvider.data.user.messages === 'undefined') {
              this.dataProvider.data.user.messages = {
                pendingArticles: false,
              };
            }

            if (this.dataProvider.data.user.checkups.constructor !== Array) {
              const { checkups } = this.dataProvider.data.user;

              this.dataProvider.data.user.checkups = <any>[checkups];
            }

            // Moved appVersion to provider to optimize startup time
            if (typeof this.dataProvider.data.appVersion !== 'string') {
              this.dataProvider.data.appVersion = '0.8.1';
            }
          }

          /* In case user is logged In */
          if (
            this.dataProvider.data.authentication != null &&
            this.dataProvider.data.user.info != null
          ) {
            if (this.dataProvider.data.authentication != null && this.dataProvider.data.user) {
              this.setupData();
              const t1 = performance.now();
              // eslint-disable-next-line no-console
              console.log('Time initializing storageProvider: ', t1 - t0);
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            this.clearStores();

            // Nullify the provider.data structure
            this.dataProvider.setDataToNull();

            resolve(false);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });

    return promise;
  }

  /**
   * Load into the provider the data from the local databse
   */
  public getDataDb() {
    this.storage.get('_userData').then((data) => {
      this.dataProvider.data = JSON.parse(data);
    });
  }

  /**
   * Saves the app data.
   */
  public saveData() {
    if (
      Date.now() - this.lastRequestToSave >= THROTTLE_TIME ||
      this.saveAttempts >= THROTTLE_ATTEMPTS
    ) {
      this.storage.set('_userData', JSON.stringify(this.dataProvider.data)).catch(() => {});

      // reset throttle counters
      this.lastRequestToSave = +new Date();
      this.saveAttempts = 0;
    } else {
      this.saveAttempts += 1;
      setTimeout(() => this.saveData(), THROTTLE_TIME);
    }
  }

  setupData() {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const i in this.dataProvider.data.user.challenges) {
      const challenge = this.dataProvider.data.user.challenges[i];
      this.dataProvider.data.user.challenges[i] = new ChallengeModel(challenge.data, challenge);
    }
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const i in this.dataProvider.data.completed) {
      const challenge = this.dataProvider.data.completed[i];
      this.dataProvider.data.completed[i] = new ChallengeModel(challenge.data, challenge);
    }
  }

  getNotificationClick() {
    return this.storage.get('view.challenge');
  }

  removeNotificationClick() {
    this.storage.remove('view.challenge');
  }

  /* returns a promise that resolves an object if the key is valid or null if it is not */
  public get(key: string): Promise<any> {
    const getPromise = new Promise((resolve, reject) => {
      this.storage
        .get(key)
        .then((data: string) => {
          let parsedData = null;
          if (data) {
            parsedData = JSON.parse(data);
          }

          resolve(parsedData);
        })
        .catch(() => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(null);
        });
    });

    return getPromise;
  }

  /* saves a string on the database */
  public set(key: string, value: string) {
    this.storage.set(key, value);
  }

  public clearStores() {
    this.appointmentsStore.reset();
    this.resultsStore.reset();
    this.akitaStorage.clear();
  }
}
