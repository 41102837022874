import { Injectable } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ImpersonateService } from '../impersonate/impersonate.service';
import { AppModeService } from '../app-mode/app-mode.service';

@Injectable({
  providedIn: 'root',
})
export class StatusbarService {
  constructor(
    private appModeService: AppModeService,
    private impersonateService: ImpersonateService
  ) {
    this.impersonateService.impersonateId$.subscribe((impersonateId) => {
      this.setStatusBarStyle(impersonateId !== null);
    });
  }

  async setStatusBarStyle(hasImpersonate = false) {
    const isNative = await this.appModeService.getIsMobileDevice();

    if (!isNative || this.appModeService.getPlatform() !== 'ios') {
      return;
    }

    const style = hasImpersonate ? Style.Dark : Style.Light;
    StatusBar.setStyle({ style });
  }
}
