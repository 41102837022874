import { NgModule } from '@angular/core';
import { AppointmentHeaderPipe } from './appointment-header/appointment.pipe';
import { TranslationPipe } from './translation/translation';
import { JoinArrayPipe } from './join-array/join-array';
import { ReversePipe } from './reverse/reverse';
import { LocalePipe } from './locale/locale';

@NgModule({
  declarations: [TranslationPipe, JoinArrayPipe, ReversePipe, AppointmentHeaderPipe, LocalePipe],
  imports: [],
  exports: [TranslationPipe, JoinArrayPipe, ReversePipe, AppointmentHeaderPipe, LocalePipe],
})
export class PipesModule {}
