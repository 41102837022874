import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Appointment } from './appointments.interface';
import { AppointmentsStore, AppointmentsState } from './appointments.store';

@Injectable({ providedIn: 'root' })
export class AppointmentsQuery extends Query<AppointmentsState> {
  pastAppointments$ = this.select((state) => state.past);

  upcomingAppointments$ = this.select((state) => state.upcoming);

  lastPerformedCheckup$ = this.select((state) =>
    this.getLastAppointmentPerformed(state.past, 'checkup')
  );

  constructor(protected store: AppointmentsStore) {
    super(store);
  }

  hasMultipleUpcoming() {
    return this.getValue().upcoming.length > 1;
  }

  getUpcomingByType(type) {
    const checkupAppointment = this.getValue().upcoming.find(
      (appointment) => appointment.type === type
    );

    return checkupAppointment || null;
  }

  upcomingAppointments() {
    return this.getValue().upcoming;
  }

  lastPerformedCheckup() {
    return this.getLastAppointmentPerformed(this.getValue().past, 'checkup');
  }

  // Should always use appointment_id of first element
  // to cancel appointments
  appointmentCancelId() {
    const { upcoming } = this.getValue();
    if (!upcoming || upcoming.length === 0) {
      return -1;
    }

    return upcoming[0].appointment_id;
  }

  upcomingAppointmentPackagedId(appointmentId: number) {
    const appointment = this.getValue().upcoming.find((item) => {
      return appointmentId === item.appointment_id;
    });

    if (!appointment || !appointment.package) {
      return -1;
    }

    return appointment.package.package_id;
  }

  private getLastAppointmentPerformed(appointments: Appointment[], type: string): Appointment {
    if (!appointments) {
      return null;
    }

    const appointmentsPerformed = appointments.filter(
      (appointment) => appointment.status === 'performed' && appointment.type === type
    );

    const totalItems = appointmentsPerformed.length;

    if (totalItems > 0) {
      const lastIndex = totalItems - 1;
      return appointmentsPerformed[lastIndex];
    }

    return null;
  }
}
