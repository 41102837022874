export default class Utils {
  public static getJson(val: any) {
    try {
      const valueParsed: any = JSON.parse(val);
      return valueParsed;
    } catch (e) {
      return false;
    }
  }

  public static parseApiError(apiError): { error: string; message: string } {
    try {
      // eslint-disable-next-line no-underscore-dangle
      return this.getJson(apiError._body);
    } catch (e) {
      return null;
    }
  }

  public static capitalizeFirstLetter(val: string) {
    let result = '';

    if (val) {
      result = val.charAt(0).toUpperCase() + val.slice(1);
    }

    return result;
  }
}
