import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';

import { Network } from '@ionic-native/network/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Screenshot } from '@ionic-native/screenshot/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';

import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';

import { ClipboardModule } from 'ngx-clipboard';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ErrorHandlerService } from './core/services/error-handler/error-handler.service';
import { ImpersonateModule } from './core/services/impersonate/impersonate.module';
import { CookieConsentService } from './core/services/cookie-consent/cookie-consent.service';
import { StripTopDevEnvironmentComponent } from './shared/components/strip-top-dev-environment/strip-top-dev-environment.component';
import { AppointmentsModule } from './modules/appointments/appointments.module';
import { WalkthroughFlyoverModule } from './shared/overlays/walkthrough-flyover/walkthrough-flyover.module';
import { WarningDoctorMessageModule } from './shared/overlays/warning-doctor-message/warning-doctor-message.module';
import { rollbarFactory, RollbarService } from './core/services/rollbar/rollbar.service';
import { PlatformFabComponent } from './shared/components/platform-fab/platform-fab.component';

@NgModule({
  declarations: [AppComponent, StripTopDevEnvironmentComponent, PlatformFabComponent],
  imports: [
    ImpersonateModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios',
      backButtonText: '',
      backButtonIcon: 'md-arrow-back',
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    RoundProgressModule,
    AppointmentsModule,
    WalkthroughFlyoverModule,
    WarningDoctorMessageModule,
    AkitaNgDevtools.forRoot(),
    NgcCookieConsentModule.forRoot(CookieConsentService.CONFIG),
    ClipboardModule,
  ],
  providers: [
    Device,
    Network,
    Screenshot,
    Calendar,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
