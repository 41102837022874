import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { JourneyFlagType, JourneyData } from '../../../shared/interfaces/api/journey-api.interface';
import {
  ChallengeData,
  ChallengeExtra,
} from '../../../shared/interfaces/challenges/challenge.interface';
import { JourneyApiService } from '../../api/journey-api.service';
import { DataProviderService } from '../data-provider/data-handler.service';
import { StorageService } from '../storage/storage.service';
import {
  CompletedJourney,
  OngoingJourney,
  RecommendedJourney,
  ChallengeJourney,
} from '../../../shared/interfaces/journeys/journey.interface';
import { EventsService } from '../events/events.service';

@Injectable({
  providedIn: 'root',
})
export class JourneyService {
  private cachedJourneys: Array<JourneyData> = [];

  constructor(
    private journeyApi: JourneyApiService,
    private dataProvider: DataProviderService,
    private storage: StorageService,
    private eventsService: EventsService
  ) {}

  public setChallengeFlag(journeyId: number, challengeId: number, type: JourneyFlagType) {
    const flag = {
      flag: type,
    };
    return this.journeyApi.postJourneyChallengeFlag(journeyId, challengeId, flag);
  }

  public getChallengeJourney(journeyId: number, challengeId: number) {
    return this.journeyApi
      .getChallengeJourney(journeyId, challengeId)
      .pipe(
        map((challenge) => {
          // Reverse extras to avoid reversing using Angular Pipe
          // eslint-disable-next-line no-param-reassign
          challenge.extras = this.reverseChallengeExtras(challenge.extras);
          return challenge;
        })
      )
      .toPromise();
  }

  public getCompletedJourneys(): Promise<Array<CompletedJourney>> {
    return new Promise((resolve, reject) => {
      this.journeyApi.getCompletedJourneys().then(
        (completedJourneys) => {
          this.dataProvider.data.user.completedJourneys = completedJourneys;
          this.storage.saveData();
          resolve(this.dataProvider.data.user.completedJourneys);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public getLocalCompletedJourneys() {
    return this.dataProvider.data.user.completedJourneys || [];
  }

  public getJourney(journeyId: number): Promise<JourneyData> {
    return new Promise((resolve, reject) => {
      this.journeyApi
        .getJourney(journeyId)
        .then((journey) => {
          // Map local ongoing challenges into the journey array
          // TODO: Fix casting type
          const mappedChallenges = journey.challenges.map((journeyChallenge: any) => {
            const mappedChallenge =
              journeyChallenge.status.name === 'ongoing'
                ? this.mapOngoingChallenges(journeyChallenge)
                : journeyChallenge;

            return mappedChallenge != null ? mappedChallenge : journeyChallenge;
          });
          const mappedJourney: JourneyData = journey;
          mappedJourney.challenges = mappedChallenges;

          this.mapOngoingJourney(journey);

          this.storage.saveData();
          resolve(journey);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public mapOngoingJourney(journey: JourneyData) {
    if (
      journey &&
      this.dataProvider &&
      this.dataProvider.data &&
      this.dataProvider.data.user &&
      this.dataProvider.data.user.ongoingJourneys
    ) {
      const ongoingJourney = this.dataProvider.data.user.ongoingJourneys.filter(
        (oJourney) => journey.journey_id === oJourney.journey_id
      );

      if (ongoingJourney.length > 0) {
        Object.assign(ongoingJourney, journey);
      }
    }
  }

  public mapOngoingChallenges(challenge: ChallengeJourney | ChallengeData): ChallengeData {
    if (challenge == null) {
      return null;
    }

    const ongoingChallenges = this.dataProvider.data.user.challenges;

    const challengeId = challenge.challenge_id;

    const sameChallenges = ongoingChallenges.filter(
      (ongoingChallenge) => ongoingChallenge.data.challenge_id === challengeId
    );

    return sameChallenges.length > 0 ? sameChallenges[0].data : null;
  }

  public getOngoingJourneys(): Promise<Array<OngoingJourney>> {
    return new Promise((resolve, reject) => {
      this.journeyApi
        .getOngoingJourneys()
        .then((journeys) => {
          this.dataProvider.data.user.ongoingJourneys = journeys;
          this.storage.saveData();
          this.eventsService.journeysRefreshSubject$.next(journeys);
          resolve(journeys);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getLocalOngoingJourneys(): Array<any> {
    return this.dataProvider.data.user.ongoingJourneys || [];
  }

  public getLocalOngoingJourney(journeyId: number): JourneyData {
    const journey = this.getLocalOngoingJourneys().find(
      (element) => element.journey_id === journeyId
    );

    return journey;
  }

  public quitJourney(journeyId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.journeyApi
        .deleteJourney(journeyId)
        .then(() => {
          /* Remove from ongoing journeys */
          this.removeOngoingJourney(journeyId);

          this.eventsService.journeyQuitSubject$.next(journeyId);

          this.storage.saveData();
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getRecommendedJourneys(): Promise<Array<RecommendedJourney>> {
    return new Promise((resolve, reject) => {
      this.journeyApi
        .getRecommendedJourneys()
        .then((journeys) => {
          this.dataProvider.data.user.recommendedJourneys = journeys;
          this.eventsService.recommendedJourneysSubject$.next(
            this.dataProvider.data.user.recommendedJourneys
          );
          this.storage.saveData();
          resolve(journeys);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getLocalRecommendedJourneys(): Array<RecommendedJourney> {
    return this.dataProvider.data.user.recommendedJourneys || [];
  }

  public getLocalRecommendedJourney(journeyId: number): RecommendedJourney {
    const matchingJourneys = this.dataProvider.data.user.recommendedJourneys.filter(
      (el) => el.journey_id === journeyId
    );

    if (matchingJourneys) {
      return matchingJourneys[0];
    }
    return null;
  }

  // Return the content after challenge is completed. Can be a challenge object or boolean if journey is completed or not
  public getCompletedContent(journeyId: number): Promise<{
    type: string;
    challenge?: ChallengeJourney | ChallengeData;
    completedJourney?: boolean;
  }> {
    const completedContentPromise: Promise<{
      type: string;
      challenge?: ChallengeJourney | ChallengeData;
      completedJourney?: boolean;
    }> = new Promise((resolve, reject) => {
      this.getJourney(journeyId)
        .then((journey) => {
          this.updateJourney(journey);

          // TODO: Fix type check
          const nextChallenge = <any>this.getFirstAvailableChallenge(journey);
          if (nextChallenge) {
            resolve({
              type: 'challenge',
              challenge: nextChallenge,
              completedJourney: false,
            });
          } else {
            const completedJourney = this.isJourneyCompleted(journey);
            resolve({
              type: 'journey',
              challenge: null,
              completedJourney,
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

    return completedContentPromise;
  }

  public getNextChallenge(journeyId: number) {
    const nextChallengePromise = new Promise((resolve, reject) => {
      this.getJourney(journeyId)
        .then((journey) => {
          const challenge = this.getFirstAvailableChallenge(journey);
          if (challenge) {
            resolve(challenge);
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(null);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    return nextChallengePromise;
  }

  public startJourney(journeyId: number) {
    return new Promise((resolve, reject) => {
      this.journeyApi
        .postJourney(journeyId)
        .then((started) => {
          if (typeof this.dataProvider.data.user.ongoingJourneys === 'undefined') {
            this.dataProvider.data.user.ongoingJourneys = [];
          }

          /* Remove this journey from recommended */
          this.removeFromRecommended(journeyId);
          this.addOngoingJourney(started);

          this.eventsService.recommendedJourneysSubject$.next(
            this.dataProvider.data.user.recommendedJourneys
          );
          this.eventsService.journeysRefreshSubject$.next(
            this.dataProvider.data.user.ongoingJourneys
          );
          this.eventsService.journeyStartSubject$.next(started);
          this.storage.saveData();
          resolve(started);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public completeJourney(journey) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.dataProvider.data.user.ongoingJourneys.length; i++) {
      const oJourney = this.dataProvider.data.user.ongoingJourneys[i];
      if (oJourney.journey_id === journey.journey_id) {
        this.dataProvider.data.user.ongoingJourneys.splice(i, 1);
        break;
      }
    }

    this.eventsService.journeysRefreshSubject$.next(this.dataProvider.data.user.ongoingJourneys);
  }

  public removeFromRecommended(journeyId: number) {
    this.dataProvider.data.user.recommendedJourneys =
      this.dataProvider.data.user.recommendedJourneys.filter(
        (journey) => journey.journey_id !== journeyId
      );
  }

  public addOngoingJourney(ongoingJourney: OngoingJourney) {
    this.dataProvider.data.user.ongoingJourneys.unshift(ongoingJourney);
  }

  public removeOngoingJourney(journeyId) {
    // Removes ongoing challenges from this Journey
    try {
      if (this.dataProvider.data.user.challenges) {
        const filteredChallenges = this.dataProvider.data.user.challenges.filter((challenge) => {
          if (challenge.active_checkup) {
            if (challenge.active_checkup.journey.journey_id === journeyId) {
              return false;
            }
          } else {
            const activeActivity = challenge.getActiveActivity();
            if (activeActivity && activeActivity.journey.journey_id === journeyId) {
              return false;
            }
          }
          return true;
        });

        this.dataProvider.data.user.challenges = filteredChallenges;
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.dataProvider.data.user.ongoingJourneys.length; i++) {
      const oJourney = this.dataProvider.data.user.ongoingJourneys[i];
      if (oJourney.journey_id === journeyId) {
        this.dataProvider.data.user.ongoingJourneys.splice(i, 1);
        break;
      }
    }
  }

  public getFirstAvailableChallenge(journey: JourneyData) {
    if (journey == null || typeof journey === undefined) {
      return null;
    }

    for (const challenge of journey.challenges) {
      if (challenge.status.name === 'pending') {
        return challenge;
      }
    }

    return null;
  }

  private reverseChallengeExtras(extras: ChallengeExtra[]) {
    if (extras && extras.length > 0) {
      return extras.reverse();
    }
    return [];
  }

  private updateJourney(updatedJourney) {
    const { ongoingJourneys } = this.dataProvider.data.user;
    if (ongoingJourneys) {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in ongoingJourneys) {
        const journey = ongoingJourneys[key];
        if (journey.journey_id === updatedJourney.journey_id) {
          ongoingJourneys[key] = updatedJourney;
          return;
        }
      }
    }
  }

  private isJourneyCompleted(journey: JourneyData): boolean {
    let numCompletedChallenges = 0;
    for (const challenge of journey.challenges) {
      if (challenge.status.name === 'completed') {
        numCompletedChallenges += 1;
      }
    }
    if (numCompletedChallenges === journey.challenges.length) {
      return true;
    }
    return false;
  }
}
