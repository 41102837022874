export const environment = {
  production: true,
  currentEnv: 'development',
  api: 'https://api.wellabe.dev',
  rollbar: {
    environment: 'development',
    enabled: true,
  },
  cookie: {
    privacy_link: 'https://web.wellabe.dev/terms/privacy-statement',
  },
  console: {
    enabled: false,
  },
  analytics: {
    trackerUrl: 'https://track.wellabe.fit/',
    mobileSiteId: '13',
    webSiteId: '14',
    subdomain: '*.wellabe.fit',
  },
  userConstraints: {
    maxAge: 120,
    minAge: 16,
    minHeight: 100,
    maxHeight: 250,
  }, // user register constraints based on API documentation
};
