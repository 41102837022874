<ion-item class="c-field">
  <ion-icon slot="start" *ngIf="icon" [name]="icon" class="c-field__icon"
    [class.c-field__icon--floating]="mode === 'floating'">
  </ion-icon>

  <ion-label class="c-field__label" mode="ios" [position]="mode" *ngIf="mode !== 'bare'">{{ label }}
  </ion-label>

  <ion-input #input class="c-field__input" type="text" [formControl]="ngControl.control"
    [placeholder]="mode === 'floating' ? null : placeholder" [min]="min" [max]="max"
    (ionChange)="setValue($event)" (keypress)="preventAlphaChars($event)" (paste)="onPaste($event)">
  </ion-input>
</ion-item>

<app-field-error-message [visible]="hasError" [currentControl]="ngControl.control">
</app-field-error-message>