import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api';
import {
  Questionnaire,
  Question,
  QuestionnaireInProgress,
} from '../../modules/questionnaires/state/questionnaires.interface';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireApiService {
  constructor(private apiService: ApiService) {}

  /*
   * Can either be available, completed,
   */
  getQuestionnaires(
    parameter: 'available' | 'completed' = 'available',
    pre_checkup = null
  ): Observable<Questionnaire[]> {
    let params;
    if (pre_checkup !== null) {
      params = {
        pre_checkup,
        status: parameter,
      };
    } else {
      params = { status: parameter };
    }

    return this.apiService.get(`/app/questionnaires`, params).pipe(
      map((questionnaires: Questionnaire[]) => {
        if (!questionnaires) {
          return [];
        }

        return questionnaires.sort((a: Questionnaire, b: Questionnaire) => a.order - b.order);
      })
    );
  }

  /*
   * Can either be handle or questionnaire_id,
   */
  getQuestionnaire(
    parameter: string,
    type: 'handle' | 'id' = 'id',
    voucher = null
  ): Observable<QuestionnaireInProgress> {
    let url =
      type === 'id'
        ? `/app/questionnaires/${parameter}`
        : `/app/questionnaires/handle/${parameter}`;

    if (voucher) {
      url += `?voucher=${voucher}`;
    }

    return this.apiService.get(url);
  }

  postQuestion(
    questionnaire_id: string,
    question_id: string,
    answer: any,
    voucher: string = null
  ): Observable<Question> {
    let url = `/app/questionnaires/${questionnaire_id}/question/${question_id}/answer`;
    if (voucher) {
      url += `?voucher=${voucher}`;
    }

    return this.apiService.post(url, answer);
  }

  getQuestion(
    questionnaire_id: string,
    question_id: string,
    voucher: string = null
  ): Observable<Question> {
    let url = `/app/questionnaires/${questionnaire_id}/question/${question_id}`;
    if (voucher) {
      url += `?voucher=${voucher}`;
    }

    return this.apiService.get(url);
  }
}
