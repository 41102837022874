import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExtraContent, PartnerInfo } from './cobranding.interface';

import { CobrandingState, CobrandingStore } from './cobranding.store';

@Injectable({ providedIn: 'root' })
export class CobrandingQuery extends QueryEntity<CobrandingState> {
  showSponsorContent$: Observable<boolean> = this.select((state) => !!state.showSponsorContent);

  partnerInfo$: Observable<PartnerInfo> = this.select(
    (state) => state.partnerInfo || <PartnerInfo>{}
  );

  sponsorName$ = this.select((state) => {
    const { partnerInfo } = state;
    if (!partnerInfo) {
      return '';
    }

    return partnerInfo.name;
  });

  primaryColor$ = this.select((state) => {
    const { partnerInfo } = state;
    if (!partnerInfo) {
      return '';
    }

    return partnerInfo.primary_color;
  });

  /* 
    These are the rules for preparing the list of content:
    - Ideally, show 2 courses and an article
    - If there are no articles, show 3 courses
    - If there is only one course, show 1 course and 2 articles
    - If there are no courses, show 3 articles
  */
  extraContentFeed$: Observable<ExtraContent[]> = this.selectAll().pipe(
    map((feed: ExtraContent[]) => {
      const [maxFeedLength, maxNoCourses] = [3, 2];
      const courses = feed.filter((content) => content.type === 'partner_custom_content_1');
      const articles = feed.filter((content) => content.type === 'partner_custom_content_2');

      // No articles -> show 3 courses
      if (!articles || articles.length < 1) {
        return courses.slice(0, maxFeedLength);
      }

      // Show at most 2 courses and  the rest are articles
      const selectedCourses = courses.slice(0, maxNoCourses);
      const selectedArticles = articles.slice(0, maxFeedLength);
      const curatedFeed = [...selectedCourses, ...selectedArticles].slice(0, maxFeedLength);
      return curatedFeed;
    })
  );

  hasExtraContent$: Observable<boolean> = this.selectCount().pipe(map((count) => !!count));

  courses$: Observable<ExtraContent[]> = this.selectAll({
    filterBy: (entity) => entity.type === 'partner_custom_content_1',
  });

  articles$: Observable<ExtraContent[]> = this.selectAll({
    filterBy: (entity) => entity.type === 'partner_custom_content_2',
  });

  constructor(protected store: CobrandingStore) {
    super(store);
  }

  hasCobrandedContent(): Observable<boolean> {
    return this.select((state) => {
      const { partnerInfo } = state;
      return !!(partnerInfo && partnerInfo.name);
    });
  }

  hasSponsorContent() {
    const { showSponsorContent } = this.getValue();
    if (!showSponsorContent) {
      return undefined;
    }

    return showSponsorContent;
  }

  getCurrentVoucher() {
    const { currentVoucher } = this.getValue();
    if (!currentVoucher) {
      return undefined;
    }

    return currentVoucher;
  }

  getPartnerInfo(): PartnerInfo {
    const { partnerInfo } = this.getValue();
    if (!partnerInfo) {
      return undefined;
    }

    return partnerInfo;
  }

  getPrimaryColor(): string {
    const { partnerInfo } = this.getValue();
    if (!partnerInfo) {
      return '';
    }

    return partnerInfo.primary_color;
  }
}
