import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import { TranslationsService } from '../translations/translations.service';

import { DataProviderService } from '../data-provider/data-handler.service';
import { AppModeService } from '../app-mode/app-mode.service';
import { EventsService } from '../events/events.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private isOnline: boolean;

  /// ///////////////////////////////////
  //          PUBLIC FUNCTIONS        //
  /// ///////////////////////////////////

  constructor(
    public alertCtrl: AlertController,
    public translations: TranslationsService,
    public dataProvider: DataProviderService,
    private appModeService: AppModeService,
    public network: Network,
    private eventsService: EventsService
  ) {
    // When we start the app, we determine whether or not the user has an active internet connection
    if (navigator.onLine) {
      this.setConnectionStatus(true);
    } else {
      this.setConnectionStatus(false);
    }

    // And from now on, we listen to the user's internet status
    this.network.onConnect().subscribe(() => {
      this.setConnectionStatus(true);

      this.eventsService.networkConnectedSubject$.next();
    });

    this.network.onDisconnect().subscribe(() => {
      this.setConnectionStatus(false);
    });
  }

  /*
   * Description: Do we have an internet connection?
   * Outputs: true if has connection, false otherwise
   */
  public online() {
    return this.isOnline;
  }

  /*
   * Description: Set the actual internet connection status
   * Inputs: _status => boolean flag
   */
  public setConnectionStatus(_status: boolean) {
    this.isOnline = _status;
  }

  /*
   * Description: Show to the user an Alert indicating he doesn't have an active internet connection
   */
  public showNetworkAlert() {
    const mode = this.appModeService.getIonicModeStyle();
    const buttons = [
      {
        text: this.translations.phrases.CHALLENGE_CLOSE,

        handler: () => {},
      },
    ];

    this.alertCtrl
      .create({
        mode,
        header: this.translations.phrases.NO_INTERNET_CONNECTION,
        message: this.translations.phrases.CHECK_YOUR_INTERNET_CONNECTION,
        buttons,
      })
      .then((alert) => alert.present());
  }

  /// ///////////////////////////////////
  //         PRIVATE FUNCTIONS        //
  /// ///////////////////////////////////
}
