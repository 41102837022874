import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-flyover',
  templateUrl: './flyover.component.html',
  styleUrls: ['./flyover.component.scss'],
})
export class FlyoverComponent {
  constructor(public modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }
}
