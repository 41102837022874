import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { UpcomingAppointmentCardComponent } from './components/upcoming-appointment-card/upcoming-appointment-card.component';
import { WaitingConfirmModal } from './overlays/waiting-confirm-modal/waiting-confirm-modal.page';
import { WaitingListCtaComponent } from './components/waiting-list-cta/waiting-list-cta.component';
import { AppointmentRecomendationsComponent } from './components/appointment-recomendations/appointment-recomendations.component';
import { AppointmentPickerComponent } from './components/appointment-picker/appointment-picker.component';
import { VoucherInputContainerComponent } from './components/voucher-input-container/voucher-input-container.component';
import { ComponentsModule } from '../../shared/components/components.module';
import { AppointmentDetailModalPage } from './overlays/appointment-detail-modal/appointment-detail-modal.page';
import { EditAppointmentModalPage } from './overlays/edit-appointment-modal/edit-appointment-modal.page';
import { UpcomingAppointmentDetailModalPage } from './overlays/upcoming-appointment-detail-modal/upcoming-appointment-detail-modal.page';
import { CheckupResultsCardComponent } from './components/checkup-results-card/checkup-results-card.component';
import { LoadingAppointmentCardComponent } from './components/loading-appointment-card/loading-appointment-card.component';
import { TermsModalModule } from '../../shared/overlays/terms-modal/terms-modal.module';
import { AppointmentNextStepsComponent } from './components/appointment-next-steps/appointment-next-steps.component';
import { CobrandingModule } from '../cobranding/cobranding.module';

@NgModule({
  declarations: [
    UpcomingAppointmentCardComponent,
    VoucherInputContainerComponent,
    AppointmentPickerComponent,
    AppointmentRecomendationsComponent,
    AppointmentNextStepsComponent,
    WaitingListCtaComponent,
    WaitingConfirmModal,
    UpcomingAppointmentDetailModalPage,
    EditAppointmentModalPage,
    AppointmentDetailModalPage,
    CheckupResultsCardComponent,
    LoadingAppointmentCardComponent,
  ],
  imports: [
    PipesModule,
    CommonModule,
    ComponentsModule,
    IonicModule,
    ReactiveFormsModule,
    TermsModalModule,
    CobrandingModule,
  ],
  exports: [
    UpcomingAppointmentCardComponent,
    VoucherInputContainerComponent,
    AppointmentPickerComponent,
    AppointmentRecomendationsComponent,
    AppointmentNextStepsComponent,
    WaitingListCtaComponent,
    WaitingConfirmModal,
    UpcomingAppointmentDetailModalPage,
    EditAppointmentModalPage,
    AppointmentDetailModalPage,
    CheckupResultsCardComponent,
    LoadingAppointmentCardComponent,
  ],
})
export class AppointmentsModule {}
