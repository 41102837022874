import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api';
import {
  BiomarkerGroup,
  Biomarker,
  PdfResultApiResponse,
  BiomarkerReading,
  HealthSummary,
  ResultReading,
} from '../../shared/interfaces/api/results-api.interface';
import { CheckupReview } from '../../shared/interfaces/api/checkup-api.interface';

@Injectable({
  providedIn: 'root',
})
export class ResultsApiService {
  constructor(private apiService: ApiService) {}

  getGroups(): Observable<BiomarkerGroup[]> {
    return this.apiService.get(`/app/results/groups`);
  }

  getBiomarker(biomarker_id: number): Observable<Biomarker> {
    return this.apiService.get(`/app/results/biomarker/${biomarker_id}`);
  }

  getBiomarkerReadings(biomarker_id: number): Observable<ResultReading[]> {
    return this.apiService.get(`/app/results/biomarkers/${biomarker_id}/readings`);
  }

  getBiomarkerList(): Observable<BiomarkerGroup[]> {
    return this.apiService.get(`/app/results/groups/biomarkers`);
  }

  getReviews(): Observable<CheckupReview[]> {
    return this.apiService.get(`/app/results/reviews`);
  }

  getPdfResult(): Observable<PdfResultApiResponse> {
    return this.apiService.get(`/app/results/latest/pdf`);
  }

  postBiomarkerReading(biomarkerId: number, body: BiomarkerReading) {
    return this.apiService.post(`/app/results/biomarkers/${biomarkerId}/readings`, body);
  }

  getHealthSummary(): Observable<HealthSummary> {
    return this.apiService.get(`/app/results/summary`);
  }

  deleteBiomarkerReading(biomarkerId: number, readingId: string) {
    return this.apiService.del(`/app/results/biomarkers/${biomarkerId}/readings/${readingId}`);
  }
}
