import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChallengeModel } from '../../models/challenge/challenge.model';
import { ErrorDialogService } from '../../../core/services/error-dialog/error-dialog.service';
import { ChallengeActionsService } from '../../../core/services/challenge-actions/challenge-actions.service';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';

@Component({
  selector: 'app-challenge-log-modal',
  templateUrl: './challenge-log-modal.page.html',
  styleUrls: ['./challenge-log-modal.page.scss'],
})
export class ChallengeLogModalPage implements OnInit {
  @Input() challenge: ChallengeModel;

  @Input() previousDay = false;

  loading = false;

  submitted = false;

  unit = '';

  placeholder = '';

  value: number = 0;

  constructor(
    private actionProvider: ChallengeActionsService,
    private errorDisplay: ErrorDialogService,
    private analyticsService: AnalyticsService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.placeholder = this.challenge.data.log_placeholder || '0';

    if (this.previousDay) {
      this.submitted = this.challenge.loggedYesterday;
      this.value = this.challenge.getYesterdayActionValue();
    } else {
      this.submitted = this.challenge.loggedToday;
      this.value = this.challenge.getTodayActionValue();
    }

    this.unit = this.challenge.data.log_unit;

    this.analyticsService.initPageTracking(
      ['Challenge', 'Log'],
      `Challenge ${this.challenge.data.challenge_id} log`,
      'Log challenge modal'
    );
  }

  dismiss() {
    this.modalController.dismiss();
  }

  submit() {
    if (this.loading || Number.isNaN(this.value)) {
      return;
    }

    this.loading = true;

    let q;

    if (this.submitted) {
      q = this.actionProvider.updateLog(this.challenge, this.value, true, this.previousDay);
    } else {
      q = this.actionProvider.logChallenge(this.challenge, this.value, this.previousDay);
    }

    q.then(() => {
      this.dismiss();
    })
      .catch((error) => {
        this.errorDisplay.showError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  decreaseValue() {
    if (!this.value) {
      this.value = 0;
    }

    if (this.value > 0) {
      this.value = Number(this.value) - 1;
    }
  }

  incrementValue() {
    if (!this.value) {
      this.value = 0;
    }

    this.value = Number(this.value) + 1;
  }
}
