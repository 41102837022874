// eslint-disable-next-line @typescript-eslint/naming-convention
export enum OFFLINE_SYNC_TYPES {
  UPDATE_SETTINGS = 0,

  POST_TRACKING,

  POST_FAVORITE,

  DELETE_FAVORITE,

  POST_CHALLENGE,

  DELETE_CHALLENGE,

  UPDATE_CHALLENGE,

  POST_SURVEYS,

  DELETE_SURVEYS,

  POST_ACTION,

  UPDATE_ACTION,

  ARTICLE_READ,

  ARTICLE_HIDE,

  POST_DEVICE,

  PUT_FAVORITE,
}
