import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() size: 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs' = 'sm';

  @Input() name: string;

  @Input() src: string;

  @Input() color: 'red' | 'black' | 'green';

  @Input() customColor: ''; // COBRANDING variable

  @Input() default: string;

  @Input() loading: boolean;

  @Input() bare: boolean;

  ngOnInit() {}

  setDefaultImage(element) {
    if (this.default) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    element.src = this.default;
  }
}
