import { Injectable } from '@angular/core';
import { ApiService } from './api';
import {
  ArticleData,
  ArticlesReadApiResponse,
} from '../../shared/interfaces/api/article-api.interface';

@Injectable({
  providedIn: 'root',
})
export class ArticleApiService {
  constructor(private apiService: ApiService) {}

  getRecommendedArticles(): Promise<ArticleData> {
    return this.apiService.get(`/app/articles/recommended`).toPromise();
  }

  getReadArticles(page: number = 1): Promise<ArticlesReadApiResponse> {
    return this.apiService.get(`/app/articles/read?page=${page}`).toPromise();
  }

  getArticleById(article_id: number): Promise<ArticleData> {
    return this.apiService.get(`/app/article/${article_id}`).toPromise();
  }

  getDeveloperArticleById(article_id: number): Promise<ArticleData> {
    return this.apiService.get(`/app/developer/article/${article_id}`).toPromise();
  }

  setArticleDisplayedById(article_id: number): Promise<null> {
    return this.apiService.put(`/app/article/${article_id}/display`, null).toPromise();
  }

  setArticleReadById(article_id: number): Promise<null> {
    return this.apiService.put(`/app/article/${article_id}/read`, null).toPromise();
  }
}
