<app-flyover center="true" class="e-slides">

  <div class="e-slides__container">
    <div class="e-slides__wrapper">
      <ion-slides pager="true" (ionSlideDidChange)="updateIsEnd()" #onboardingSlides>
        <ion-slide>
          <div class="e-slides__image">
            <img src="./assets/images/onboarding/onboarding-1.svg">
          </div>
          <div class="e-slides__description">
            <img class="e-slides__logo" src="./assets/images/icon-green.svg">
            <h3 class="u-margin-bottom--md">
              {{'ONBOARDING_RESULTS_SLIDE_1_TITLE' | translate}}
            </h3>
            <p>
              {{'ONBOARDING_RESULTS_SLIDE_1_DESCRIPTION' | translate}}
            </p>
          </div>
        </ion-slide>

        <ion-slide>
          <div class="e-slides__image">
            <img src="./assets/images/onboarding/onboarding-2.svg">
          </div>
          <div class="e-slides__description">
            <img class="e-slides__logo" src="./assets/images/icon-green.svg">
            <h3 class="u-margin-bottom--md">
              {{'ONBOARDING_RESULTS_SLIDE_2_TITLE' | translate}}
            </h3>
            <p>
              {{'ONBOARDING_RESULTS_SLIDE_2_DESCRIPTION' | translate}}
            </p>
          </div>
        </ion-slide>

        <ion-slide>
          <div class="e-slides__image">
            <img src="./assets/images/onboarding/onboarding-3.svg">
          </div>
          <div class="e-slides__description">
            <img class="e-slides__logo" src="./assets/images/icon-green.svg">
            <h3 class="u-margin-bottom--md">
              {{'ONBOARDING_RESULTS_SLIDE_3_TITLE' | translate}}
            </h3>
            <p>
              {{'ONBOARDING_RESULTS_SLIDE_3_DESCRIPTION' | translate}}
            </p>
          </div>
        </ion-slide>

      </ion-slides>
    </div>

    <div class="l-container">
      <app-button (click)="nextSlide()" *ngIf="onboardingSlides">
        <ng-container *ngIf="isLastSlide; else nextLabel">
          {{'ONBOARDING_RESULTS_DONE_BUTTON' | translate}}
        </ng-container>
        <ng-template #nextLabel>{{'NEXT_BUTTON' | translate}}</ng-template>
      </app-button>
    </div>

  </div>
</app-flyover>