import { Injectable } from '@angular/core';
import { ApiService } from './api';

@Injectable({
  providedIn: 'root',
})
export class DeveloperApiService {
  constructor(private apiService: ApiService) {}

  public getDeveloperChallenge(challenge_id: number) {
    return this.apiService.get(`/app/developer/challenge/${challenge_id}`);
  }
}
