import { Injectable } from '@angular/core';
import { MatomoService } from '../matomo/matomo.service';
import { UserFlagService } from '../user-flag/user-flag.service';
import Utils from '../../../shared/utils/utils';
import { environment } from '../../../../environments/environment';
import { AppModeService } from '../app-mode/app-mode.service';

const BASE_TRACKER_URL = 'https://app.wellabe.fit';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public documentTitle: string;

  public categoryForEvents: string;

  constructor(
    private matomoTrackerService: MatomoService,
    private userFlagProvider: UserFlagService,
    private appModeService: AppModeService
  ) {
    this.enableHeartBeatTimer();
    this.disableAutoDetectNewPageView();
  }

  public init() {
    if (!this.isLoaded()) {
      let siteId = environment.analytics.mobileSiteId;

      if (this.appModeService.isWeb()) {
        siteId = environment.analytics.webSiteId;
      }

      this.matomoTrackerService.init(
        environment.analytics.trackerUrl,
        siteId,
        environment.analytics.subdomain
      );
    }
  }

  public trackEvent(action: string, name?: string, category?: string): void {
    if (this.isAnalyticsEnabled()) {
      this.matomoTrackerService.trackEvent(category || this.categoryForEvents, action, name, null);
    }
  }

  public trackEventError(
    action: string,
    rawError: any,
    apiError: boolean = true,
    category?: string
  ): void {
    if (this.isAnalyticsEnabled()) {
      /*
      TO FIX: Error parser should be on API service
     */

      const errorJsonFormat: any =
        // eslint-disable-next-line no-underscore-dangle
        rawError && rawError._body ? Utils.getJson(rawError._body) : null;
      const errorMessage: string =
        errorJsonFormat && errorJsonFormat.error ? errorJsonFormat.error : JSON.stringify(rawError);

      this.matomoTrackerService.trackEvent(
        category || this.categoryForEvents,
        action,
        apiError ? `Api Error: ${errorMessage}` : errorMessage
      );
    }
  }

  public initPageInfo(
    customTitleArray: Array<string>,
    customUrl: string,
    categoryForEvents: string
  ): void {
    this.setCategoryForEvents(categoryForEvents);
    this.setDocumentTitle(customTitleArray);
    this.setCustomUrl(`${BASE_TRACKER_URL}`);
  }

  public setCategoryForEvents(category: string) {
    this.categoryForEvents = category;
  }

  public initPageTracking(
    customTitleArray: Array<string>,
    customUrl: string,
    categoryForEvents: string,
    newSessionPage: boolean = true
  ): void {
    if (newSessionPage) {
      this.setNewPageView();
    }

    this.initPageInfo(customTitleArray, customUrl, categoryForEvents);
    this.trackPageView();
  }

  public trackPageView(customTitleArray?: Array<string>): void {
    if (this.isAnalyticsEnabled()) {
      this.matomoTrackerService.trackPageView(
        customTitleArray && customTitleArray[0] !== undefined ? customTitleArray.join(' / ') : null
      );
    }
  }

  public setCustomUrl(customUrl: string) {
    if (this.isAnalyticsEnabled()) {
      this.matomoTrackerService.setCustomUrl(customUrl);
    }
  }

  public setUserId(userId: string): void {
    if (this.isAnalyticsEnabled()) {
      this.matomoTrackerService.setUserId(userId);
    }
  }

  public setDocumentTitle(customTitleArray: Array<string>) {
    this.documentTitle = customTitleArray.join(' / ');

    if (this.isAnalyticsEnabled()) {
      this.matomoTrackerService.setDocumentTitle(this.documentTitle);
    }
  }

  public resetUserId() {
    if (this.isAnalyticsEnabled()) {
      this.matomoTrackerService.resetUserId();
    }
  }

  public enableHeartBeatTimer(customInterval?: number): void {
    this.matomoTrackerService.enableHeartBeatTimer(customInterval);
  }

  public disableAutoDetectNewPageView(): void {
    this.matomoTrackerService.disableAutoDetectNewPageView();
  }

  public enableHeatMapAndSessionRecording(): void {
    this.matomoTrackerService.enableHeatMapAndSessionRecording();
  }

  public disableHeatMapAndSessionRecording(): void {
    this.matomoTrackerService.disableHeatMapAndSessionRecording();
  }

  public setNewPageView(): void {
    this.matomoTrackerService.setNewPageView();
  }

  public loadConfig() {
    if (this.isAnalyticsEnabled()) {
      this.enableAnalytics();
    } else {
      this.disableAnalytics();
    }
  }

  public disableAnalytics() {
    this.resetUserId();
    this.matomoTrackerService.optOutUser();
  }

  public enableAnalytics(userId?: string) {
    if (userId) {
      this.matomoTrackerService.setUserId(userId);
    }

    this.matomoTrackerService.forgetUserOptOut();
  }

  public optOutCookies() {
    this.matomoTrackerService.disableCookies();
    this.matomoTrackerService.deleteCookies();
  }

  public isLoaded() {
    return !!this.matomoTrackerService.getInstance();
  }

  private isAnalyticsEnabled() {
    return !this.userFlagProvider.getFlag('disable_analytics');

    // return (!(this.provider &&
    //   this.provider.data &&
    //   this.provider.data.user &&
    //   this.provider.data.user.info &&
    //   this.provider.data.user.info.developer) && !this.userFlagProvider.get("disableAnalytics"));
  }
}
