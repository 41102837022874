import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from '../../../core/services/translations/translations.service';

/**
 * Generated class for the TranslationPipe pipe.
 *
 * See https://angular.io/docs/ts/latest/guide/pipes.html for more info on
 * Angular Pipes.
 */
@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslationPipe implements PipeTransform {
  constructor(public translations: TranslationsService) {}

  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string, interpolationKeys = null) {
    let translatedPhrase = this.translations.phrases[value];

    if (translatedPhrase) {
      if (!interpolationKeys) {
        return translatedPhrase;
      }

      for (const key of Object.keys(interpolationKeys)) {
        const regex = new RegExp(`{{${key}}}`, 'gi');
        translatedPhrase = translatedPhrase.replace(regex, interpolationKeys[key]);
      }

      return translatedPhrase;
    }
    return value;
  }
}
