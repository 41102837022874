import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';

import { QuestionnaireInProgress } from './questionnaires.interface';

export interface QuestionnaireState extends EntityState<QuestionnaireInProgress> {}

export function createInitialState(): QuestionnaireState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'questionnaires',
  idKey: 'questionnaire_id',
  resettable: true,
  cache: { ttl: 30000 },
})
export class QuestionnaireStore extends EntityStore<QuestionnaireState> {
  constructor() {
    super(createInitialState());
  }
}
