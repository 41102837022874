import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ProviderState, ProviderStore } from './provider.store';

@Injectable({ providedIn: 'root' })
export class ProviderQuery extends Query<ProviderState> {
  handle$ = this.select((state) => state.handle);

  constructor(protected store: ProviderStore) {
    super(store);
  }

  getHandle() {
    return this.getValue().handle;
  }

  getContactInfo() {
    return this.getValue().contact_info;
  }

  setHandle(handle: string) {
    this.store.update({ handle });
  }

  setContactInfo(contact_info: ProviderState['contact_info']) {
    this.store.update({
      contact_info,
    });
  }
}
