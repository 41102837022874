<button class="c-btn" [class.c-btn--full]="size === 'full'" [class.c-btn--sm]="size === 'sm'"
  [class.c-btn--responsive]="responsive" [class.c-btn--secondary]="variant === 'secondary'"
  [class.c-btn--terciary]="variant === 'terciary'" [class.c-btn--red]="variant === 'red'"
  [class.c-btn--white]="color === 'white'" [class.c-btn--arrow]="variant === 'arrow'"
  [class.c-btn--icon-only]="variant === 'icon'" [class.c-btn--icon]="icon" [type]="type"
  [disabled]="disabled || loading" [style.backgroundColor]="customColor">

  <span *ngIf="loading" class="c-btn__loading">
    <ion-spinner name="dots" class="c-btn__spinner"></ion-spinner>
  </span>

  <ng-container *ngIf="!loading">
    <ion-icon class="c-btn__icon" *ngIf="icon; else content" [name]="icon"></ion-icon>
  </ng-container>

  <ion-icon class="c-btn__arrow c-btn__arrow--back" *ngIf="arrowBack" name="chevron-back-outline">
  </ion-icon>

  <ion-icon class="c-btn__arrow" *ngIf="arrow" name="chevron-forward-outline"></ion-icon>

  <ion-icon class="c-btn__external" *ngIf="external" src="assets/images/open.svg"></ion-icon>
</button>

<ng-template #content>
  <span class="c-btn__content">
    <ng-content></ng-content>
  </span>
</ng-template>