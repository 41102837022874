import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class VoucherService {
  addVoucherToStorage(value: string) {
    sessionStorage.setItem('voucher', value);
  }

  getVoucherValue(): string {
    return sessionStorage.getItem('voucher');
  }
}
