/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
import { Inject, Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import * as Rollbar from 'rollbar';
import { TranslationsService } from '../translations/translations.service';
import { DataProviderService } from '../data-provider/data-handler.service';
import { NetworkService } from '../network/network.service';
import { AppSettings } from '../../../app.settings';
import { EventsService } from '../events/events.service';
import { RollbarService } from '../rollbar/rollbar.service';
import { ErrorHandlerService } from '../error-handler/error-handler.service';

export type ErrorType = 'error' | 'success' | 'info' | 'alert';

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    public toastCtrl: ToastController,
    public translations: TranslationsService,
    public networkService: NetworkService,
    private dataProvider: DataProviderService,
    private eventsService: EventsService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  async showWarning(text: string): Promise<HTMLIonToastElement> {
    return this.presentToast(text, 'alert');
  }

  async showInfo(text: string): Promise<HTMLIonToastElement> {
    return this.presentToast(text, 'info');
  }

  async showSuccess(text: string): Promise<HTMLIonToastElement> {
    return this.presentToast(text, 'success');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async showError(error, type: ErrorType = 'error', logAnalytics = true, logRollbar = false) {
    const errorJsonFormat: any = error && error._body ? this.getJson(error._body) : null;
    const errorHttpFormat: any = error && error.error ? error.error : null;

    const errorParesed = errorJsonFormat || errorHttpFormat;
    let messageToUser: any = errorParesed && errorParesed.message ? errorParesed.message : null;
    let messageToDebug: any = errorParesed && errorParesed.error ? errorParesed.error : null;

    /*
            Support for old error messages:
            - error can be an array
            - error can be parsed to json and read as an array
        */
    if (!messageToUser && error) {
      if (typeof error !== 'string' && error[0] !== undefined) {
        messageToUser = error[0];
        messageToDebug = error[0];
      } else if (this.getJson(error._body)[0]) {
        messageToUser = this.getJson(error._body)[0];
        messageToDebug = this.getJson(error._body)[0];
      } else if (this.getJson(error._body).message) {
        messageToUser = this.getJson(error._body).message;
        messageToDebug = this.getJson(error._body).message;
      } else if (typeof error === 'string') {
        messageToUser = error;
        messageToDebug = error;
      }
    }

    if (error === 'ERROR_TIMEOUT') {
      messageToUser = this.translations.phrases.ERROR_TIMEOUT;
      type = 'info';
    }

    // Checks for internet connection. If error is not found, it is probably due to no internet
    if ((!messageToUser && !this.networkService.online()) || error === 'ERROR_NO_INTERNET') {
      messageToUser = this.translations.phrases.ERROR_NO_INTERNET;
      type = 'info';
    }

    if (!messageToUser || messageToUser === undefined || messageToUser == null) {
      this.trackError('Unknown error', error);
    }

    messageToUser = messageToUser || this.translations.phrases.ERROR_UNKNOWN_MESSAGE;
    messageToDebug = messageToDebug || this.translations.phrases.ERROR_UNKNOWN_MESSAGE;

    // console.log('[errorDisplayProvider] AFTER', messageToUser);

    this.presentToast(messageToUser, type);

    if (logRollbar) {
      this.trackError(messageToDebug, error);
    }
  }

  /**
   * Handle errors that do not need to be shown to the user
   */
  handleError(error) {
    let messageToUser = '';
    let type: ErrorType = 'error';
    // Checks first if the user is not authenticated
    if (error.status && error.status === 401) {
      messageToUser = this.translations.phrases.ERROR_FORCE_LOGOUT_MESSAGE;
      this.eventsService.userLogoutSubject$.next();
    }

    if (!this.networkService.online() || error === 'ERROR_NO_INTERNET') {
      messageToUser = this.translations.phrases.ERROR_NO_INTERNET;
    }

    if (error === 'ERROR_TIMEOUT') {
      messageToUser = this.translations.phrases.ERROR_TIMEOUT;
      type = 'info';
    }

    messageToUser = messageToUser || this.translations.phrases.ERROR_UNKNOWN_MESSAGE;

    this.presentToast(messageToUser, type);
  }

  /**
   * Tracks error into rollbar. This does not show any message to the user.
   */
  trackError(message, error) {
    let user = null;
    let payload: any = null;

    try {
      user = {
        id: this.dataProvider.data.user.info.wellabe_id,
        username: this.dataProvider.data.user.info.wellabe_id,
      };
      // eslint-disable-next-line no-empty
    } catch (err) {}

    payload = {
      person: user,
      client: AppSettings.ROLLBAR_CLIENT,
    };

    if (message === 'Unknown error') {
      message = error;
    }

    if (this.errorHandlerService.shouldReport(error)) {
      this.rollbar.configure({
        captureIp: false,
        payload,
        scrubFields: ['user_ip'],
      });

      this.rollbar.error(message, error);
    }
  }

  /**
   * Shows toast with the provided message
   */
  async presentToast(message: string, type: ErrorType): Promise<HTMLIonToastElement> {
    const toast = await this.toastCtrl.create({
      cssClass: type ? `c-toast--${type}` : 'c-toast--info',
      message: `  ${message}  `,
      position: 'top',
      duration: 4000,
    });

    toast.present();

    return toast;
  }

  private getJson(val) {
    try {
      const valueParsed: any = JSON.parse(val);
      return valueParsed;
    } catch (e) {
      return false;
    }
  }
}
