import { Injectable } from '@angular/core';
import { LanguageSettings } from '../../../shared/interfaces/settings.interface';
import { StorageService } from '../storage/storage.service';
import { DataProviderService } from '../data-provider/data-handler.service';
import { TRANSLATIONS } from './local-translations.class';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  public phrases: any = {};

  public docs: any = {};

  public languages = [];

  public defaultLang: any;

  private translationsEn = TRANSLATIONS.EN_TRANSLATIONS;

  private translationsDe = TRANSLATIONS.DE_TRANSLATIONS;

  private translationsCs = TRANSLATIONS.CS_TRANSLATIONS;

  private translationsSk = TRANSLATIONS.SK_TRANSLATIONS;

  constructor(public dataProvider: DataProviderService, public storage: StorageService) {
    this.phrases = this.getLocalTranslation();
  }

  /// ///////////////////////////////////
  //          PUBLIC FUNCTIONS        //
  /// ///////////////////////////////////

  /*
   * Description: Returns the translation from the given key
   * Inputs:
   * Outputs:
   */
  public getTranslation(key: string = null): string {
    if (key === null) {
      return '';
    }
    return this.phrases[key];
  }

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public setPhrases(_translation: any) {
    this.phrases = _translation;
  }

  public setDocs(_translation: any) {
    this.docs = _translation;
  }

  public getLanguages() {
    return this.languages;
  }

  /*
   * Description:
   * Inputs:
   * Outputs:
   */
  public setAvailableLanguages(
    _lang: LanguageSettings[] = this.dataProvider.data.metadata.languages,
    _defaultLang: any = this.dataProvider.data.settings.defaultLang
  ) {
    [this.defaultLang] = _lang;

    for (const language of _lang) {
      if (language.locale === _defaultLang.locale) {
        this.defaultLang = language;

        language.default = true;
      } else {
        language.default = false;
      }
    }

    this.languages = _lang;
  }

  /**
   * Sets the language depending on the user saved language
   * If a language was previously set, use that language
   * Otherwise use device language
   * Returns true if a differente language was set
   * Returns false if language was not modified
   */
  setDefaultLanguage() {
    let promise = null;
    const currentLang = this.dataProvider.data.settings.defaultLang;

    promise = new Promise((resolve) => {
      if (this.loadLanguageFromQueryParam()) {
        resolve(true);
      } else if (currentLang.default) {
        resolve(this.useDeviceLanguage());
      } else {
        resolve(false);
      }
    });
    return promise;
  }

  loadLanguageFromQueryParam(): boolean {
    // get query from location as to not use ActivatedRoute since it was not initialized yet
    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang');

    // has lang
    if (!lang) {
      return false;
    }

    return this.setDefaultLocale(lang);
  }

  /**
   * Sets the language depending on the user device language
   * Returns true if a differente language was set
   * Returns false if language was not modified
   */
  useDeviceLanguage() {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      const { language } = navigator;
      if (language) {
        resolve(this.setDefaultLocale(language, true));
      } else {
        this.setDefaultLocale('de', true);
        reject();
      }
    });
    return promise;
  }

  public setDefaultLocale(localeParam: string, forcingDefault = false) {
    let locale = localeParam;

    if (locale.length > 2) {
      locale = locale.substring(0, 2);
    }

    if (locale !== this.dataProvider.data.settings.defaultLang.locale) {
      for (const language of this.dataProvider.data.metadata.languages) {
        if (language.locale === locale) {
          this.defaultLang = false;

          if (forcingDefault) {
            language.default = true;
          }

          this.defaultLang = language;
          this.dataProvider.data.settings.defaultLang = language;
          this.storage.saveData();
          return true;
        }
      }
    }
    return false;
  }

  /// ///////////////////////////////////
  //         PRIVATE FUNCTIONS        //
  /// ///////////////////////////////////

  /*
   * Description: Returns the local translations, before loading from API
   * Inputs:
   * Outputs:
   */
  private getLocalTranslation(tag: any = null) {
    const { language } = navigator;

    let translationsObj;
    switch (language) {
      case 'de':
        translationsObj = this.translationsDe;
        break;
      case 'cs':
        translationsObj = this.translationsCs;
        break;
      case 'sk':
        translationsObj = this.translationsSk;
        break;
      case 'en':
      default:
        translationsObj = this.translationsEn;
    }

    if (tag != null) {
      return translationsObj[tag];
    }

    return translationsObj;
  }
}
