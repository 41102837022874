import { Injectable } from '@angular/core';
import {
  NgcCookieConsentService,
  NgcStatusChangeEvent,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '../analytics/analytics.service';

import { environment } from '../../../../environments/environment';
import { TranslationsService } from '../translations/translations.service';
import { CookieService } from '../cookie/cookie.service';

const COOKIES_REMOVAL_NOT_ALLOWED = ['cookieconsent_status', '__cfduid'];

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  public static CONFIG: NgcCookieConsentConfig = {
    enabled: false,
    cookie: {
      domain: document.location.hostname,
    },
    content: {
      href: environment.cookie.privacy_link,
    },
    position: 'top-left',
    theme: 'edgeless',
    type: 'opt-out',
    showLink: false,
  };

  private previousConsentOption: boolean = true;

  private showOnCurrentPage = true;

  private hasInitialized = false;

  constructor(
    private analyticsService: AnalyticsService,
    private ccService: NgcCookieConsentService,
    private cookieService: CookieService,
    private customTranslationService: TranslationsService,
    private router: Router
  ) {}

  public init(): void {
    this.ccService.init({ ...CookieConsentService.CONFIG });
    this.loadContentTranslations();
    this.load();

    this.ccService.statusChange$.subscribe((event) => {
      this.statusChangeEvent(event);
    });

    this.ccService.popupOpen$.subscribe(() => {
      /*
        TOFIX: workaround to handle "Cannot read property 'cookie' of null at o.getStatus" error.

        If it is the first ever the user is acessing the website (no previous cookies preferences set),
        the methods from NgcCookieConsentService will throw an error inside this subscription.

        If the error is thrown we assume that previous consent option is not set.

        UPDATE: This error is not being thrown atm. As we are not allowing the user to change
        consent, I'm commenting the line below to avoid the app reloading when accepting cookies
      */

      try {
        // this.previousConsentOption = this.ccService.hasConsented();
      } catch (err) {
        this.previousConsentOption = null;
      }
    });

    this.router.events.subscribe((ev) => {
      this.handleNavigationChange(ev);
    });

    this.ccService.initialize$.subscribe((initialized) => {
      this.hasInitialized = initialized && initialized.status !== null;
    });
  }

  public openDialog(): void {
    this.ccService.open();
  }

  private load(): void {
    if (this.ccService.hasAnswered()) {
      if (this.ccService.hasConsented()) {
        this.allowCookies();
      } else {
        this.declineCookies();
      }
    }
  }

  private handleNavigationChange(ev: Event) {
    // Hide banner on Privacy Page
    if (ev instanceof NavigationEnd && ev.url === '/terms?type=privacy') {
      this.showOnCurrentPage = false;
      this.ccService.destroy();
      this.declineCookies();
    } else if (ev instanceof NavigationEnd && ev.url !== '/terms?type=privacy') {
      // If user is moving away from the privacy page, initialize the cookie service
      this.showOnCurrentPage = true;
      if (!this.hasInitialized) {
        this.ccService.init({ ...CookieConsentService.CONFIG, enabled: this.showOnCurrentPage });
      }
    }
  }

  private statusChangeEvent(event: NgcStatusChangeEvent): void {
    if (event.status === 'allow') {
      this.allowCookies(true);
    } else {
      this.declineCookies();
    }
  }

  /**
   * @param statusChangeEvent Enabled if the event comes from a status change event
   *
   * If the previous status was "not consented" (previousConsentOption = false),
   * the only way to re-enable the Matomo cookies is forcing the page to reload. By doing this,
   * the Matomo script with be loaded and "optOutCookies" method won't be called.
   */
  private allowCookies(statusChangeEvent: boolean = false): void {
    if (statusChangeEvent && this.previousConsentOption === false) {
      window.location.reload();
    }
    this.analyticsService.enableAnalytics();
    this.ccService.close(false);
  }

  private declineCookies(): void {
    this.analyticsService.optOutCookies();
    this.cookieService.deleteAll(COOKIES_REMOVAL_NOT_ALLOWED);
    this.ccService.close(false);
  }

  private loadContentTranslations(): void {
    this.ccService.getConfig().content = this.ccService.getConfig().content || {};

    this.ccService.getConfig().content = {
      message: this.customTranslationService.getTranslation('COOKIES_MESSAGE'),
      deny: this.customTranslationService.getTranslation('COOKIES_DECLINE'),
      allow: this.customTranslationService.getTranslation('COOKIES_ALLOW'),
    };

    // Prevent from destroying if it isn't open
    if (this.ccService.isOpen()) {
      this.ccService.destroy();
    }
    this.ccService.init({ ...this.ccService.getConfig(), enabled: this.showOnCurrentPage });
  }
}
