import { NgModule } from '@angular/core';
import { RouterModule, Routes, NoPreloading } from '@angular/router';
import { MobileGuard } from './core/guards/mobile/mobile.guard';
import { StepGuard } from './core/guards/step/step.guard';
import { WebGuard } from './core/guards/web/web.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'appointment',
    canActivate: [StepGuard],
    loadChildren: () =>
      import('./modules/appointments/pages/booking/booking.page.module').then(
        (m) => m.BookingPageModule
      ),
  },
  {
    path: 'onboarding-questions',
    canActivate: [StepGuard],
    loadChildren: () =>
      import('./modules/onboarding/pages/onboarding-questions/onboarding-questions.module').then(
        (m) => m.OnboardingQuestionsPageModule
      ),
  },
  {
    path: 'onboarding/waiting-checkup',
    canActivate: [StepGuard],
    loadChildren: () =>
      import('./modules/onboarding/pages/waiting-checkup/waiting-checkup.module').then(
        (m) => m.WaitingCheckupPageModule
      ),
  },
  {
    path: 'onboarding/results',
    canActivate: [WebGuard, StepGuard],
    loadChildren: () =>
      import('./modules/onboarding/pages/onboarding-results/onboarding-results.module').then(
        (m) => m.OnboardingResultsPageModule
      ),
  },
  {
    path: 'activities',
    canActivate: [MobileGuard, StepGuard],
    loadChildren: () =>
      import('./modules/activities/pages/activities/activities.module').then(
        (m) => m.ActivitiesPageModule
      ),
  },
  {
    path: 'todays-goals',
    canActivate: [MobileGuard, StepGuard],
    loadChildren: () =>
      import('./modules/todays-goals/pages/today/today.module').then((m) => m.TodayPageModule),
  },
  {
    path: 'appointments',
    canActivate: [StepGuard],
    loadChildren: () =>
      import('./modules/appointments/pages/appointments/appointments.module').then(
        (m) => m.AppointmentsPageModule
      ),
  },
  {
    path: 'questionnaire',
    loadChildren: () =>
      import('./modules/questionnaires/pages/questionnaires/questionnaires.module').then(
        (m) => m.QuestionnairesPageModule
      ),
  },
  {
    path: 'notification-permission-modal',
    loadChildren: () =>
      import(
        './shared/overlays/notification-permission-modal/notification-permission-modal.module'
      ).then((m) => m.NotificationPermissionModalPageModule),
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
