import { Injectable } from '@angular/core';
import {
  PushNotifications,
  PushNotificationsPlugin,
  Channel,
  Token,
  PushNotificationSchema,
  ActionPerformed,
} from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { registerPlugin } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { FCMPlugin, FCM } from '@capacitor-community/fcm';
import { BehaviorSubject } from 'rxjs';
import { DataProviderService } from '../data-provider/data-handler.service';
import { OfflineSyncService } from '../offline-sync/offline-sync.service';
import { PushApiService } from '../../api/push-api.service';
import { ANALYTICS_TRACKING } from '../../../shared/interfaces/analytics.interface';
import { StorageService } from '../storage/storage.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { AppModeService } from '../app-mode/app-mode.service';

type PushActionType = 'open_page' | 'open_app' | 'open_checkup_review';
type PushContentType = 'journey' | 'article';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fcm = registerPlugin<FCMPlugin>('FCMPlugin', {});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface PushNotificationAdditionalData {
  foreground: boolean;
  dismissed: boolean; // does not trigger
  content_type?: PushContentType;
  action?: PushActionType;
  content_id?: number;
  push_notification_id: number;
  coldstart?: boolean;
}

interface PushLocalData {
  askedForPermission: boolean;
}

@Injectable({ providedIn: 'root' })
export class PushNotificationsService {
  /** Push Options used to initiliaze the push service */
  // options: PushOptions = {
  //   android: {},
  //   ios: {
  //     alert: 'true',
  //     badge: false,
  //     sound: 'false'
  //   }
  // };

  /** Saves the response from the push registration event */
  registrationToken: Token = null;

  onNotificationClick: BehaviorSubject<{
    page: string;
    params: any;
  }> = new BehaviorSubject(null);

  onPermissionRequest = new BehaviorSubject(false);

  onPermissionGranted = new BehaviorSubject(false);

  hasPermission = false;

  private pushNotificationsPlugin: PushNotificationsPlugin = null;

  private notificationEvent: PushNotificationSchema = null;

  // Needs to save on a separate DB to avoid being cleaned after logout
  private permissionDb = '_push';

  // Data structure saved on device
  private pushLocalData: PushLocalData = {
    askedForPermission: false,
  };

  constructor(
    private dataProvider: DataProviderService,
    private offlineSync: OfflineSyncService,
    private pushApiService: PushApiService,
    private storage: StorageService,
    private analyticsService: AnalyticsService,
    private appModeService: AppModeService
  ) {}

  public async initialize() {
    const isNative = await this.appModeService.getIsMobileDevice();

    if (!isNative) {
      return;
    }

    this.pushNotificationsPlugin = PushNotifications;
    LocalNotifications.checkPermissions().then((permissionStatus) => {
      if (permissionStatus.display === 'granted') {
        this.onPermissionGranted.next(true);
        this.initWithPermission();
      } else {
        this.getDevicePushPermission().then(({ askedForPermission }) => {
          if (askedForPermission === false) {
            this.showPermissionModal();
          }
        });
      }
    });
  }

  public async denyPermission() {
    const isNative = await this.appModeService.getIsMobileDevice();

    if (!isNative) {
      return;
    }

    this.onPermissionRequest.next(false);
    this.saveDevicePushPermission();
  }

  public async requestPermissionAndInitialize() {
    const isNative = await this.appModeService.getIsMobileDevice();

    if (!isNative) {
      return;
    }

    this.saveDevicePushPermission();
    LocalNotifications.requestPermissions().then((permissionStatus) => {
      if (permissionStatus.display === 'granted') {
        this.onPermissionGranted.next(true);
        this.initWithPermission();
      }
    });
  }

  public async clearNotificationClick() {
    const isNative = await this.appModeService.getIsMobileDevice();

    if (!isNative) {
      return;
    }

    this.onNotificationClick.next(null);
  }

  public async unregister() {
    const isNative = await this.appModeService.getIsMobileDevice();

    if (!isNative) {
      return;
    }

    this.pushNotificationsPlugin.removeAllListeners();
    FCM.deleteInstance().then(
      () => {},
      () => {}
    );
  }

  /**
   * Returns the registration ID of the push service.
   * Returns null if device is not registered
   */
  public registrationId(): string {
    return this.registrationToken ? this.registrationToken.value : null;
  }

  public getNotificationPage(): { page: string; params: any } {
    if (this.notificationEvent == null) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { action, content_type, content_id } = this.notificationEvent.data;

    // Clear event so it won't be triggered again
    this.notificationEvent = null;

    if (!action || action === 'open_app') {
      return null;
    }

    if (action === 'open_page' && content_id >= 0) {
      switch (content_type) {
        case 'journey':
          return { page: 'JourneyPage', params: { journey_id: content_id } };
        case 'article':
          return { page: 'ArticlePage', params: { article_id: content_id } };
        default:
          break;
      }
    }

    if (action === 'open_checkup_review') {
      return { page: 'ResultsPage', params: { show_review: true } };
    }

    return null;
  }

  private initWithPermission() {
    // Reset Permission request so modal won't be shown twice
    this.onPermissionRequest.next(false);
    this.hasPermission = true;
    this.createAndroidChannel();
    this.subscribeToEvents();
    this.pushNotificationsPlugin.register();
  }

  /**
   * Renames the default push notification channel on Android (Oreo or higher)
   */
  private async createAndroidChannel() {
    const isAndroid = (await Device.getInfo()).platform === 'android';
    if (isAndroid) {
      const defaultChannel: Channel = {
        id: 'PushPluginChannel',
        name: 'Push Notifications',
        importance: 3,
      };
      this.pushNotificationsPlugin.createChannel(defaultChannel);
    }
  }

  /**
   * Subscribe to multiple push events: 'registration', 'notification'
   */
  private subscribeToEvents(): void {
    this.subscribeToRegistration();
    this.subscribeToNotification();
  }

  /**
   * Subscribe to 'registration' event and handles its callback
   */
  private subscribeToRegistration(): void {
    this.pushNotificationsPlugin.addListener('registration', (token: Token) => {
      this.setRegistrationToken(token);
    });

    this.pushNotificationsPlugin.addListener('registrationError', () => {});
  }

  /**
   *  Subscribe to 'notification' event and handles its callback
   */
  private subscribeToNotification(): void {
    this.pushNotificationsPlugin.addListener('pushNotificationReceived', () => {});

    this.pushNotificationsPlugin.addListener(
      'pushNotificationActionPerformed',
      (pushNotificationAction: ActionPerformed) => {
        this.notificationEvent = pushNotificationAction.notification;
        const notificationPage = this.getNotificationPage();
        this.onNotificationClick.next(notificationPage);

        this.analyticsService.trackEvent(
          ANALYTICS_TRACKING.ACTIONS.PUSH_NOTIFICATION_OPENED,
          `Open Notification (Id: ${pushNotificationAction.notification.data.push_notification_id})`,
          'Push Notifications'
        );

        this.updatePushServerStatus(pushNotificationAction.notification.data);
      }
    );
  }

  private updatePushServerStatus(data) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { push_notification_id, foreground, coldstart } = data;
    if (push_notification_id === null || push_notification_id === undefined) {
      return;
    }

    const body = {
      foreground: !!foreground,
      coldstart: !!coldstart,
    };
    this.pushApiService.putNotificationOpened(push_notification_id, body).subscribe(
      () => {},
      () => {}
    );
  }

  private showPermissionModal() {
    this.onPermissionRequest.next(true);
  }

  private saveDevicePushPermission() {
    this.pushLocalData.askedForPermission = true;
    this.storage.set(this.permissionDb, JSON.stringify(this.pushLocalData));
  }

  private getDevicePushPermission() {
    return this.storage.get(this.permissionDb).then((permission: PushLocalData) => {
      if (permission && permission.askedForPermission) {
        this.pushLocalData = permission;
      }
      return this.pushLocalData;
    });
  }

  /**
   * Saves registration ID locally and also send it to server
   */
  private setRegistrationToken(token: Token) {
    this.registrationToken = token;

    // Saves on the provider
    this.dataProvider.data.pushToken = token.value;

    // Send token to Server
    this.offlineSync.postDevice().catch(() => {});
  }
}
