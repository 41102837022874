import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api';

import { Appointment } from '../../modules/appointments/state/appointments.interface';

@Injectable({
  providedIn: 'root',
})
export class AppointmentApiService {
  constructor(private apiService: ApiService) {}

  getAppointments(filter: 'past' | 'upcoming'): Observable<Appointment[]> {
    return this.apiService.get(`/app/appointments/${filter}`);
  }
}
