import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiService } from './api';

import { CheckupApiResponse } from '../../shared/interfaces/api/checkup-api.interface';

@Injectable({
  providedIn: 'root',
})
export class CheckupApiService {
  constructor(private apiService: ApiService) {}

  getCheckupInformation(): Observable<CheckupApiResponse> {
    return this.apiService.get(`/app/checkup`);
  }

  putCheckup(checkupInfo: { risk_accepted: boolean }): Promise<CheckupApiResponse> {
    return this.apiService.put(`/app/checkup`, checkupInfo).toPromise();
  }

  restartCheckup() {
    return this.apiService.post(`/app/checkup/restart`, {}).toPromise();
  }
}
