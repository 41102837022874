import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ProviderState {
  handle: string | undefined;
  contact_info?: {
    name: string | undefined;
    contact_email: string | undefined;
    contact_phone: string | undefined;
    contact_from?: string | undefined;
    contact_to?: string | undefined;
  };
}

export function createInitialState() {
  const emptyState = {
    handle: undefined,
    contact_info: undefined,
  };

  return emptyState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'provider',
})
export class ProviderStore extends Store<ProviderState> {
  constructor() {
    super(createInitialState());
  }
}
