import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(DOCUMENT) private document: any) {}

  /**
   * @returns all the cookies in json
   */
  public getAll(): { [key: string]: string } {
    const cookies: { [key: string]: string } = {};
    const { document } = this;

    if (document.cookie && document.cookie !== '') {
      document.cookie.split(';').forEach((currentCookie) => {
        const [cookieName, cookieValue] = currentCookie.split('=');
        cookies[this.safeDecodeURIComponent(cookieName.replace(/^ /, ''))] =
          this.safeDecodeURIComponent(cookieValue);
      });
    }

    return cookies;
  }

  /**
   * @param name     Cookie name
   * @param value    Cookie value
   * @param expires  Expiration `Date`
   */
  public set(name: string, value: string, expirationDate?: Date): void {
    let cookieString: string = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;

    if (expirationDate) {
      cookieString += `expires=${expirationDate.toUTCString()};`;
    }

    this.document.cookie = cookieString;
  }

  public delete(name: string): void {
    this.set(name, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'));
  }

  /**
   * @param ignoreList List of cookies that should not be deleted
   */
  public deleteAll(ignoreList: string[] = []): void {
    const cookies: any = this.getAll();

    ignoreList.forEach((item) => {
      delete cookies[item];
    });

    for (const key of Object.keys(cookies)) {
      this.delete(key);
    }
  }

  /**
   * @param encodedURIComponent Encoded URI string
   * @returns decoded URI string or the original encoded URI string in case of an error
   */
  public safeDecodeURIComponent(encodedURIComponent: string): string {
    try {
      return decodeURIComponent(encodedURIComponent);
    } catch {
      return encodedURIComponent;
    }
  }
}
