import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() size: 'full' | 'md' | 'sm' = 'md';

  @Input() color: '' | 'white' = '';

  @Input() customColor: ''; // COBRANDING variable

  @Input() variant: 'primary' | 'secondary' | 'terciary' | 'arrow' | 'icon' | 'red' = 'primary';

  @Input('arrow') arrow: boolean;

  @Input('arrowBack') arrowBack: boolean;

  @Input() icon: string;

  @Input() type: string = 'button';

  @Input() responsive: boolean;

  @Input() loading: boolean;

  @Input() disabled: boolean;

  @Input() external: boolean;

  ngOnInit() {}
}
