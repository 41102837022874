import { NgModule, Injector } from '@angular/core';

@NgModule({})
export class ImpersonateModule {
  static injector: Injector;

  constructor(injector: Injector) {
    ImpersonateModule.injector = injector;
  }
}
