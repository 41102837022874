import { Injectable } from '@angular/core';
import { PopoverController, NavController, AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import {
  UserData,
  UserProvidersApiResponse,
} from '../../../shared/interfaces/api/user-api.interface';
import {
  ArticleData,
  ArticlesReadApiResponse,
} from '../../../shared/interfaces/api/article-api.interface';
import { StorageService } from '../storage/storage.service';
import { DataProviderService } from '../data-provider/data-handler.service';
import { NetworkService } from '../network/network.service';
import { TranslationsService } from '../translations/translations.service';
import { LocalNotificationsService } from '../local-notifications/local-notifications.service';
import { OfflineSyncService } from '../offline-sync/offline-sync.service';
import { ChallengesService } from '../challenges/challenges.service';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { AuthApiService } from '../../api/auth-api.service';
import { MetadataApiService } from '../../api/metadata-api.service';
import { UserApiService } from '../../api/user-api.service';
import { EmailVerificationApiService } from '../../api/email-verification-api.service';
import { ANALYTICS_TRACKING } from '../../../shared/interfaces/analytics.interface';
import { ArticleApiService } from '../../api/article-api.service';
import { JourneyService } from '../journey/journey.service';
import { CheckupService } from '../checkup/checkup.service';
import { PushNotificationsService } from '../push-notifications/push-notifications.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { UserFlags } from '../../../shared/interfaces/user-flags.interface';
import { AppointmentsService } from '../../../modules/appointments/state';
import { Questionnaire } from '../../../modules/questionnaires/state/questionnaires.interface';
import { QuestionnairesService } from '../../../modules/questionnaires/state';
import { AppModeService } from '../app-mode/app-mode.service';
import { AccountApiService } from '../../api/account-api.service';
import { EventsService } from '../events/events.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private previousLogins: Array<string> = [];

  private loginDbKey = 'login.tokens';

  private logoutSubscription: Subscription;

  constructor(
    public navController: NavController,
    public dataProvider: DataProviderService,
    public storage: StorageService,
    public networkService: NetworkService,
    public translations: TranslationsService,
    public localNotifications: LocalNotificationsService,
    public offlineSync: OfflineSyncService,
    public challengesService: ChallengesService,
    public popoverCtrl: PopoverController,
    private errorDisplay: ErrorDialogService,
    private authService: AuthenticationService,
    private authApiService: AuthApiService,
    private articleApi: ArticleApiService,
    private eventsService: EventsService,
    private metadataApiService: MetadataApiService,
    private userApiService: UserApiService,
    private questionnairesService: QuestionnairesService,
    private emailVerification: EmailVerificationApiService,
    private journeyProvider: JourneyService,
    private checkupProvider: CheckupService,
    private pushService: PushNotificationsService,
    private analyticsTrackerService: AnalyticsService,
    private alertController: AlertController,
    private appointmentsService: AppointmentsService,
    private accountApiService: AccountApiService,
    private appModeService: AppModeService
  ) {
    if (!this.logoutSubscription) {
      this.setLogoutSubscription();
    }

    this.storage
      .get(this.loginDbKey)
      .then((data: Array<string>) => {
        if (data) {
          this.previousLogins = data;
        } else {
          this.previousLogins = [];
        }
      })
      .catch(() => {
        this.previousLogins = [];
      });
  }

  public getPreviousLogins() {
    return this.previousLogins;
  }

  public addLoginData(userToken: string) {
    if (userToken == null || userToken === undefined) {
      return;
    }
    for (const previousLogin of this.previousLogins) {
      if (previousLogin === userToken) {
        return;
      }
    }

    this.previousLogins.unshift(userToken);
    this.previousLogins = this.previousLogins.slice(0, 2);
    this.savePreviousLogins();
  }

  public setPreviousLogins(logins: Array<string>) {
    if (logins == null || logins === undefined) {
      return;
    }
    this.previousLogins = logins;
    this.savePreviousLogins();
  }

  public savePreviousLogins() {
    this.storage.set(this.loginDbKey, JSON.stringify(this.previousLogins));
  }

  /**
   * Does the complete user authentication.
   * Firsts it authenticates with the WS. If the authentication was successful, it also posts the device information
   *
   * Both authentication and sending device information have to be successful in order to resolve this promise
   */
  public authUser(authentication: { username: string; password: string }) {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      if (authentication.username) {
        // eslint-disable-next-line no-param-reassign
        authentication.username = authentication.username.trim();
      }

      // Clear any cache before authenticating
      this.storage.clearStores();

      this.authApiService.postLogin(authentication).then(
        (success: any) => {
          this.dataProvider.data.authentication = success;

          // Async call to update metadata
          this.getMetadata();

          // Init Push notifications
          this.pushService.initialize();

          this.eventsService.userLoginSubject$.next();

          const settingsPromise = this.offlineSync.updateSettings();
          const devicePromise = this.offlineSync.postDevice();
          const flagPromise = this.getFlags();

          const promises = [settingsPromise, devicePromise, flagPromise];

          this.addLoginData(authentication.username);

          Promise.all(promises)
            .then((completed) => {
              if (completed && completed.length > 0) {
                this.dataProvider.data.authentication.device_id = completed[1].device_id;
              }
              resolve(completed);
            })
            .catch((err) => {
              reject(err);
            });
        },
        (err) => {
          reject(err);
        }
      );
    });

    return promise;
  }

  public logoutAllDevices() {
    const promise = new Promise((resolve, reject) => {
      this.authService
        .postLogout(true)
        .then(() => {
          this.processLogout(false, true)
            .then((success) => {
              resolve(success);
            })
            .catch((err) => {
              this.errorDisplay.trackError('Failed to processLogout', err);
              reject(err);
            });
        })
        .catch((err) => {
          this.errorDisplay.trackError('Failed to post Logout', err);
          this.errorDisplay.showError(err);
          reject(err);
        });
    });
    return promise;
  }

  /**
   * Procedure to clear the user data and logs out
   */
  public processLogout(redirectToLogin = false, skipRequest = false): Promise<any> {
    this.analyticsTrackerService.trackEvent(
      ANALYTICS_TRACKING.ACTIONS.REQUEST_LOGOUT,
      skipRequest ? 'Logout From All Devices' : 'Logout'
    );

    const promiseLogout = new Promise((resolve) => {
      // Does the request before erasing the data
      if (!skipRequest) {
        this.authService.postLogout().catch((err) => {
          this.errorDisplay.trackError('Failed to post Logout', err);
        });
      }

      this.analyticsTrackerService.disableAnalytics();

      this.dataProvider.storage.set('_userData', null);

      const metadata = JSON.parse(JSON.stringify(this.dataProvider.data.metadata));
      const translations = JSON.parse(JSON.stringify(this.dataProvider.data.translations));
      const language = JSON.parse(JSON.stringify(this.dataProvider.data.settings.defaultLang));

      this.dataProvider.setDataToNull();
      this.dataProvider.setOfflineDataToNull();

      // Keep the metadata saved.
      this.dataProvider.data.metadata = metadata;

      // Keep the translation saved.
      this.dataProvider.data.translations = translations;

      // Keep the language saved.
      this.dataProvider.data.settings.defaultLang = language;

      this.localNotifications.cancelAll();

      this.pushService.unregister();

      this.dataProvider.storage.clear();
      this.storage.clearStores();
      this.storage.saveData();
      this.savePreviousLogins();

      if (redirectToLogin) {
        this.navController.navigateRoot('login');
      }
      resolve(true);
    });

    return promiseLogout;
  }

  public deleteEmail(password: string) {
    return new Promise((resolve, reject) => {
      this.accountApiService
        .deleteUserEmail(password)
        .then(() => {
          // Remove e-mail from storage
          this.dataProvider.data.user.info.email = null;
          this.dataProvider.data.user.info.email_verified = false;
          this.storage.saveData();
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public registerEmail(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.accountApiService
        .postUserEmail(email, password)
        .then((response) => {
          // Remove e-mail from storage
          this.dataProvider.data.user.info.email = response.email;
          this.dataProvider.data.user.info.email_verified = response.email_verified;
          this.storage.saveData();
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public verifyEmail(code: string) {
    return new Promise((resolve, reject) => {
      this.emailVerification
        .postVerifiy(code)
        .then(() => {
          this.dataProvider.data.user.info.email_verified = true;
          this.storage.saveData();
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Request and save the data from GET /user route.
   */
  public getUser(): Promise<UserData> {
    const promise: Promise<UserData> = new Promise((resolve, reject) => {
      this.accountApiService
        .getUser()
        .then((userInfo: UserData) => {
          // eslint-disable-next-line no-param-reassign
          userInfo = this.stripImpersonationData(userInfo);
          this.dataProvider.data.user.info = userInfo;
          this.storage.saveData();
          resolve(this.dataProvider.data.user.info);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  /* Update user e-mail and optionally send the verification e-mail */

  public updateUserEmail(email: string) {
    const putEmailPromise = new Promise((resolve, reject) => {
      if (email === '') {
        reject();
        return;
      }

      this.emailVerification
        .putUpdate(email)
        .then((response) => {
          this.dataProvider.data.user.info.email = email;
          this.storage.saveData();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return putEmailPromise;
  }

  /**
   * Return the user information
   */
  public getUserData() {
    if (this.dataProvider.data != null && this.dataProvider.data.user) {
      return this.dataProvider.data.user;
    }

    return null;
  }

  public getUserInfo() {
    if (
      this.dataProvider.data != null &&
      this.dataProvider.data.user &&
      this.dataProvider.data.user.info
    ) {
      return this.dataProvider.data.user.info;
    }

    return null;
  }

  public getUserFlags() {
    const user = this.getUserData();
    if (user && user.flags) {
      return user.flags;
    }
    return null;
  }

  public getUserProviders() {
    const promise: Promise<UserProvidersApiResponse[]> = new Promise((resolve, reject) => {
      this.accountApiService
        .getProviders()
        .then((providers: UserProvidersApiResponse[]) => {
          this.dataProvider.data.user.providers = providers;
          this.storage.saveData();
          resolve(this.dataProvider.data.user.providers);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  /**
   * Process and return the user feed
   * Returns null if app should use cached data
   */
  public getUserFeed(avoidSyncTimeCheck: boolean = false) {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      if (!this.networkService.online() && avoidSyncTimeCheck) {
        resolve(this.dataProvider.data.user.news);
      } else {
        this.offlineSync
          .getContents(avoidSyncTimeCheck)
          .then((success: ArticleData[]) => {
            this.dataProvider.data.user.news = success;

            this.storage.saveData();

            this.eventsService.dataArticlesSubject$.next(success);

            resolve(success);
          })
          .catch((err) => {
            if (err.status) {
              this.errorDisplay.trackError('Error fetching content', err);
            }
            reject(err);
          });
      }
    });

    return promise;
  }

  getLocalArticle(article_id: number) {
    return this.dataProvider.data.user.news.find((article) => article.article_id === article_id);
  }

  getReadArticles(page: number = 1): Promise<ArticlesReadApiResponse> {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      if (!this.networkService.online()) {
        resolve([]);
      } else {
        this.articleApi
          .getReadArticles(page)
          .then((success: ArticlesReadApiResponse) => {
            this.dataProvider.data.user.read = success.data;

            this.storage.saveData();

            resolve(success);
          })
          .catch((err) => {
            this.errorDisplay.trackError('Error fetching unread articles', err);
            reject(err);
          });
      }
    });

    return promise;
  }

  public readContent(content: ArticleData) {
    // Set content as read if it is not being called by offline queue
    this.moveArticleToRead(content.article_id);
    this.storage.saveData();

    /* For now we don't use the callbacks */
    this.offlineSync.postSingleContentRead(content).then().catch();
  }

  /**
   * Return the locally saved feed. Used to display before the updated feed is sent
   */
  getLocalFeed(): Array<ArticleData> {
    return this.dataProvider.data.user.news;
  }

  getLocalReadArticles(): Array<ArticleData> {
    return this.dataProvider.data.user.read;
  }

  moveArticleToRead(articleId: number): boolean {
    const articles = this.dataProvider.data.user.news;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < articles.length; i++) {
      const article = articles[i];
      if (article.article_id === articleId) {
        this.dataProvider.data.user.news.splice(i, 1);
        this.dataProvider.data.user.read.unshift(article);
        return true;
      }
    }
    return false;
  }

  /**
   * Get metadata from WS
   * If skipPhrases is set to true, the app phrases will not be requested
   */
  public getMetadata(skipPhrases = false) {
    const promises = [];
    const promiseLanguage = new Promise((resolve, reject) => {
      this.metadataApiService.getLanguages().then(
        (success) => {
          if (this.dataProvider.data.metadata) {
            this.dataProvider.data.metadata.languages = success;
          } else {
            this.dataProvider.data.metadata.languages = success;
          }
          // Update translation provider with the available languages
          this.translations.setAvailableLanguages();

          this.storage.saveData();

          if (!skipPhrases) {
            this.getAppPhrases();
          }
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });

    promises.push(promiseLanguage);
    return Promise.all(promises);
  }

  /**
   * Get all the phrases used in the app depending on the language set in the user settings. Returns a Promise which resolves
   * when the request is complete.
   */
  public getAppPhrases(): Promise<any> {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      const promises: any = [
        this.metadataApiService.getPhrasesByType('app'),
        this.metadataApiService.getPhrasesByType('doc'),
      ];

      Promise.all(promises).then(
        (success) => {
          // eslint-disable-next-line prefer-destructuring
          this.dataProvider.data.translations = success[0];
          this.translations.setPhrases(this.dataProvider.data.translations);

          // eslint-disable-next-line prefer-destructuring
          this.dataProvider.data.docs = success[1];
          this.translations.setDocs(this.dataProvider.data.docs);

          this.storage.saveData();
          resolve(this.dataProvider.data.translations);
        },
        (error) => {
          reject(error);
        }
      );
    });

    return promise;
  }

  /**
   * Save a new default language for the app.
   * @param language - Object of the language to be set
   */
  public setDefaultLanguage(language: any) {
    this.dataProvider.data.settings.defaultLang = language;
    this.storage.saveData();
  }

  /**
   * Force update all the user information. Returns a Promises.all which resolves
   * when all the requests are complete.
   */
  public updateAll() {
    const updatePromise = new Promise((resolve, reject) => {
      this.storage.clearStores();

      const promisesArray: any = [
        this.checkupProvider.fetchResults().toPromise(),
        this.getUserFeed(true),
        this.journeyProvider.getOngoingJourneys(),
        this.challengesService.getChallenges(),
        this.getQuestionnaires(),
        this.journeyProvider.getRecommendedJourneys(),
        this.getReadArticles(),
        this.getCompletedQuestionnaires(),
        this.getUser(),
        this.appointmentsService.getUpcomingAppointments().toPromise(),
      ];
      // Make the requests for updating the data
      Promise.all(promisesArray)
        .then((success) => {
          resolve(success);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return updatePromise;
  }

  public getLocalQuestionnaire() {
    if (this.dataProvider.data.user.questionnaires) {
      return this.dataProvider.data.user.questionnaires;
    }

    return [];
  }

  public getQuestionnaires() {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      if (!this.networkService.online()) {
        resolve(this.dataProvider.data.user.questionnaires);
      } else {
        this.questionnairesService
          .getAvailableQuestionnaires()
          .toPromise()
          .then((success: Array<Questionnaire>) => {
            this.dataProvider.data.user.questionnaires = success;

            this.storage.saveData();

            this.eventsService.dataQuestionnaireSubject$.next(success);

            resolve(success);
          })
          .catch((err) => {
            this.errorDisplay.trackError('Error fetching questionnaires', err);
            reject(err);
          });
      }
    });

    return promise;
  }

  public moveQuestionnaireToCompleted(movable: Questionnaire) {
    if (this.dataProvider.data.user.completedQuestionnaires) {
      let alreadyInCompleted = false;

      for (const questionnaire of this.dataProvider.data.user.completedQuestionnaires) {
        if (questionnaire.questionnaire_id === movable.questionnaire_id) {
          alreadyInCompleted = true;
          break;
        }
      }

      if (!alreadyInCompleted) {
        this.dataProvider.data.user.completedQuestionnaires.push(movable);

        this.dataProvider.data.user.questionnaires =
          this.dataProvider.data.user.questionnaires.filter((questionnaire) => {
            return questionnaire.questionnaire_id !== movable.questionnaire_id;
          });

        this.eventsService.dataQuestionnaireSubject$.next(
          this.dataProvider.data.user.questionnaires
        );

        this.storage.saveData();
      }
    }
  }

  public getLocalCompletedQuestionnaire() {
    if (this.dataProvider.data.user.completedQuestionnaires) {
      return this.dataProvider.data.user.completedQuestionnaires;
    }

    return [];
  }

  public getCompletedQuestionnaires() {
    let promise = null;

    promise = new Promise((resolve, reject) => {
      if (!this.networkService.online()) {
        resolve(this.dataProvider.data.user.completedQuestionnaires);
      } else {
        this.questionnairesService
          .getCompletedQuestionnaires()
          .toPromise()
          .then((success: Array<Questionnaire>) => {
            this.dataProvider.data.user.completedQuestionnaires = success;

            this.storage.saveData();

            this.eventsService.dataCompletedQuestionnaireSubject$.next(success);

            resolve(success);
          })
          .catch((err) => {
            this.errorDisplay.trackError('Error fetching completed questionnaires', err);
            reject(err);
          });
      }
    });

    return promise;
  }

  public getTotalContentCompleted() {
    let count = 0;

    if (this.dataProvider.data.user) {
      /* completed challenges */
      if (this.dataProvider.data.completed) {
        count += this.dataProvider.data.completed.length;
      }

      if (this.dataProvider.data.user.read) {
        count += this.dataProvider.data.user.read.length;
      }

      if (this.dataProvider.data.user.completedQuestionnaires) {
        count += this.dataProvider.data.user.completedQuestionnaires.length;
      }
    }

    return count;
  }

  public getFlags() {
    const getFlagsPromise = new Promise((resolve, reject) => {
      this.userApiService
        .getFlags()
        .then((success) => {
          for (const flag of success) {
            this.setFlag(flag.name, flag.status);
          }
          resolve(success);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return getFlagsPromise;
  }

  public setFlag(flag: string, value: boolean) {
    if (this.dataProvider.data.user.flags == null) {
      this.dataProvider.data.user.flags = {
        completed_onboarding: false,
        dismissed_timeline_articles_tip: false,
        dismissed_timeline_journeys_tip: false,
        dismissed_timeline_questionnaire_tip: false,
        timeline_completed_tab_tooltip: false,
        view_challenge_onboarding: false,
        view_profile_tab_tooltip: false,
        view_results_tab_tooptip: false,
        view_timeline_tab_tooltip: false,
        results_biomarker_list_tip: false,
        app_rating_dismissed: false,
        disable_analytics: false,
      };
    }

    this.dataProvider.data.user.flags[flag] = value;
  }

  public setFlags(flags: UserFlags) {
    this.dataProvider.data.user.flags = flags;
    this.storage.saveData();
  }

  public async promptLogout() {
    const mode = this.appModeService.getIonicModeStyle();
    const cssClass = mode === 'md' ? 'u-color--red' : '';

    const alert = await this.alertController.create({
      mode,
      header: this.translations.phrases.LOGOUT_ALERT_TITLE || 'Logging out?',
      message:
        this.translations.phrases.LOGOUT_ALERT_MESSAGE ||
        'Are you sure you want to logout? You can come back to this page logging back in.',
      buttons: [
        {
          text: this.translations.phrases.ALERT_BUTTON_CANCEL || 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: this.translations.phrases.LOGOUT || 'Logout',
          cssClass,
          handler: () => {
            this.processLogout().then(
              () => {
                this.navController.navigateRoot('login', { animationDirection: 'back' });
              },
              () => {
                this.navController.navigateRoot('login', { animationDirection: 'back' });
              }
            );
          },
        },
      ],
    });
    alert.present();
  }

  /// ///////////////////////////////////
  //        PRIVATE FUNCTIONS         //
  /// ///////////////////////////////////

  // Ignores can_impersonate and developer flags if user is being impersonated
  private stripImpersonationData(userData: UserData): UserData {
    const strippedData = userData;
    if (this.dataProvider.data.settings.impersonate != null) {
      strippedData.can_impersonate = this.dataProvider.data.user.info.can_impersonate;
      strippedData.developer = this.dataProvider.data.user.info.developer;
    }

    return strippedData;
  }

  private setLogoutSubscription() {
    this.logoutSubscription = this.eventsService.userLogoutSubject$.subscribe(() => {
      if (this.dataProvider.data.user.info != null) {
        this.processLogout(true).catch();
      }
    });
  }
}
