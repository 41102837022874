import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { AccountStepType } from '../../../shared/interfaces/account.interface';
import { DataProviderService } from '../data-provider/data-handler.service';
import { TranslationsService } from '../translations/translations.service';
import { ChallengesService } from '../challenges/challenges.service';
import { StorageService } from '../storage/storage.service';
import { UserFlagService } from '../user-flag/user-flag.service';
import { CheckupService } from '../checkup/checkup.service';
import { AppSettings } from '../../../app.settings';
import { EventsService } from '../events/events.service';

@Injectable({
  providedIn: 'root',
})
export class CompatibilityService {
  constructor(
    private dataProvider: DataProviderService,
    private translations: TranslationsService,
    private challengesService: ChallengesService,
    private eventsService: EventsService,
    private loadingCtrl: LoadingController,
    private storageService: StorageService,
    private userFlagService: UserFlagService,
    private checkupService: CheckupService
  ) {}

  async applyPatches() {
    // Checkings to support old app versions
    // Added on 0.3.3

    const version = this.dataProvider.data.appVersion;
    const previousParsed = {
      major: 0,
      minor: 0,
      patch: 0,
    };

    if (version && typeof version === 'string') {
      const previous = version.split('.');
      if (previous.length >= 2) {
        previousParsed.major = parseInt(previous[0], 10);
        previousParsed.minor = parseInt(previous[1], 10);
        previousParsed.patch = parseInt(previous[2], 10);
      }
    }

    let latestPatch = '';
    const updatingPhrase = this.translations.phrases.LOADING_UPDATING_APP;

    if (previousParsed.major === 0) {
      if (previousParsed.minor <= 3 && previousParsed.patch <= 8) {
        latestPatch = '0.3.8';

        this.challengesService
          .getChallenges()
          .then(() => {
            this.eventsService.appResumeSubject$.next();

            if (latestPatch === '0.3.8') {
              this.dataProvider.data.appVersion = AppSettings.APP_VERSION;
            }
          })
          .catch(() => {});
      }
      if (previousParsed.minor <= 3 && previousParsed.patch <= 30) {
        latestPatch = '0.3.30';

        const loading = await this.loadingCtrl.create({ message: updatingPhrase });

        await loading.present();

        this.checkupService.fetchResults().subscribe(
          () => {
            this.eventsService.appResumeSubject$.next();
            if (latestPatch === '0.3.30') {
              this.dataProvider.data.appVersion = AppSettings.APP_VERSION;
            }

            loading.dismiss().then().catch();
          },
          () => {
            loading.dismiss().then().catch();
          }
        );
      }
      if (previousParsed.minor <= 4 && previousParsed.patch <= 3) {
        const loading = await this.loadingCtrl.create({ message: updatingPhrase });
        await loading.present();
        latestPatch = '0.4.3';

        this.dataProvider.data.completed = [];
        this.dataProvider.data.user.challenges = [];
        this.challengesService
          .getChallenges()
          .then(() => {
            this.eventsService.appResumeSubject$.next();

            if (latestPatch === '0.4.3') {
              this.dataProvider.data.appVersion = AppSettings.APP_VERSION;
              loading.dismiss().then().catch();
            }
          })
          .catch(() => {});
      }

      if (previousParsed.minor <= 6 && previousParsed.patch <= 1) {
        if (
          this.dataProvider.data.user &&
          this.dataProvider.data.user.info &&
          <string>this.dataProvider.data.user.info.step === 'results'
        ) {
          /* In case user is already logged in, does not show tutorials */
          this.dataProvider.data.user.flags = {
            completed_onboarding: true,
            dismissed_timeline_articles_tip: false,
            dismissed_timeline_journeys_tip: false,
            dismissed_timeline_questionnaire_tip: false,
            timeline_completed_tab_tooltip: true,
            results_biomarker_list_tip: false,
            view_challenge_onboarding: false,
            view_profile_tab_tooltip: true,
            view_results_tab_tooptip: true,
            view_timeline_tab_tooltip: true,
          };
        }
      }

      if (previousParsed.minor <= 6 && previousParsed.patch <= 3) {
        if (
          this.dataProvider.data.user &&
          this.dataProvider.data.user.info &&
          <string>this.dataProvider.data.user.info.step === 'results'
        ) {
          if (
            (this.dataProvider.data.user.challenges &&
              this.dataProvider.data.user.challenges.length > 0) ||
            (this.dataProvider.data.user.read && this.dataProvider.data.user.read.length > 0) ||
            (this.dataProvider.data.user.info as any).skip_onboarding
          ) {
            this.userFlagService.setFlag('completed_onboarding');
          }
        }
      }

      if (previousParsed.minor <= 7 && previousParsed.patch <= 0) {
        this.dataProvider.data.user.challenges = [];
        this.dataProvider.data.user.ongoingJourneys = [];
        this.dataProvider.data.user.recommendedJourneys = [];
        this.dataProvider.data.user.availableChallenges = [];
        this.storageService.saveData();
        this.dataProvider.data.appVersion = AppSettings.APP_VERSION;
        this.storageService.saveData();
      }

      if (previousParsed.minor <= 8 && previousParsed.patch <= 2) {
        if (<string>this.dataProvider.data.user.info.step === 'results') {
          this.checkupService.fetchResults().subscribe();
        }

        this.dataProvider.data.appVersion = AppSettings.APP_VERSION;
        this.storageService.saveData();
      }

      if (previousParsed.minor <= 8 && previousParsed.patch <= 8) {
        this.dataProvider.data.user.challenges = [];

        this.storageService.saveData();
      }

      if (previousParsed.minor <= 8 && previousParsed.patch <= 14) {
        const oldStep = this.dataProvider.data.user.info.step;
        this.dataProvider.data.user.info.step = this.covertOldStep(oldStep);
        this.dataProvider.data.appVersion = AppSettings.APP_VERSION;
        this.dataProvider.data.user.info.wellabe_id = (<any>this.dataProvider.data.user.info).token;
        this.storageService.saveData();
      }

      if (previousParsed.minor <= 9 && previousParsed.patch <= 10) {
        this.dataProvider.data.user.info.wellabe_id = (<any>(
          this.dataProvider.data.user.info
        )).bodylabs_id;

        this.dataProvider.data.appVersion = AppSettings.APP_VERSION;
        this.storageService.saveData();
      }
    }
  }

  private covertOldStep(oldStep: string): AccountStepType {
    switch (oldStep) {
      case 'policy':
        return 'policy';
      case 'email_verification':
        return 'email_verification';
      case 'appointment':
        return 'appointment';
      case 'questions':
      case 'checkup':
        return 'waiting-checkup';
      case 'results':
        return 'app';
      default:
        break;
    }
    return null;
  }
}
