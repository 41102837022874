import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api';
import {
  PostLoginResponse,
  PostLoginBody,
  PostForgotPasswordApiBody,
  PostRegisterSubmitApiResponse,
  PostRegisterValidateApiBody,
  PostRegisterValidateApiResponse,
  PostRegisterSubmitApiBody,
} from '../../shared/interfaces/api/auth-api.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private apiService: ApiService) {}

  postLogin(credentials: { username: string; password: string }): Promise<PostLoginResponse> {
    const body: PostLoginBody = {
      username: credentials.username,
      password: credentials.password,
      client_id: 3,
    };

    return this.apiService.post(`/app/auth/login`, body).toPromise();
  }

  postValidateRegister(
    body: PostRegisterValidateApiBody
  ): Observable<PostRegisterValidateApiResponse[]> {
    return this.apiService.post(`/app/auth/register/validate`, body);
  }

  postSubmitRegister(body: PostRegisterSubmitApiBody): Observable<PostRegisterSubmitApiResponse> {
    return this.apiService.post(`/app/auth/register/submit`, body);
  }

  // This route returns  []
  postForgotPassword(body: PostForgotPasswordApiBody): Promise<any> {
    return this.apiService.post(`/app/auth/password/reset`, body).toPromise();
  }
}
