import { Injectable } from '@angular/core';

import { ApiService } from './api';

import { EmailInfoApiResponse } from '../../shared/interfaces/api/email-verification-api.interface';

@Injectable({
  providedIn: 'root',
})
export class EmailVerificationApiService {
  constructor(private apiService: ApiService) {}

  postVerifiy(code: string): Promise<EmailInfoApiResponse> {
    const body = {
      verification_code: code,
    };
    return this.apiService.post(`/app/email_verification/verify`, body).toPromise();
  }

  // This route returns []
  putResend(): Promise<any> {
    return this.apiService.put(`/app/email_verification/resend`, {}).toPromise();
  }

  putUpdate(newEmail: string): Promise<EmailInfoApiResponse> {
    const body = {
      email: newEmail,
    };
    return this.apiService.put(`/app/email_verification/update`, body).toPromise();
  }
}
