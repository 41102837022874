import { Component, OnInit, Self, Input, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, ValidatorFn } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { ClipboardDataService } from '../../../core/services/clipboard-data/clipboard-data.service';
import { DataProviderService } from '../../../core/services/data-provider/data-handler.service';
import { positiveNumberValidator } from '../../../core/validators/positive-number/positive-number.validator';

@Component({
  selector: 'app-field-number',
  templateUrl: './field-number.component.html',
  styleUrls: ['./field-number.component.scss'],
})
export class FieldNumberComponent implements ControlValueAccessor, OnInit {
  @ViewChild('input', { static: true }) input: IonInput;

  @Input() label: string;

  @Input() mode: 'stacked' | 'bare' | 'floating' = 'stacked';

  @Input() placeholder: string;

  @Input() icon;

  @Input() min: number;

  @Input() max: number;

  @Input() decimals: boolean;

  @Input() hasError = true;

  @Input() customValidator = false;

  value: string;

  isDisabled = false;

  onChange: (_: any) => void = () => {};

  onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private dataProvider: DataProviderService,
    private clipboardDataService: ClipboardDataService
  ) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    const { control } = this.ngControl;

    let validators = this.getValidators();
    validators = control.validator ? [control.validator, ...validators] : this.getValidators();
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  preventAlphaChars(event): boolean {
    /*
     * The regex matches the entire string and contains three groups:
     * 1. Any number of digits
     * 2. Dot (.)
     * 3. Any number of digits
     */

    const pattern = this.decimals ? /^[0-9]+[.,]{0,1}[0-9]*$/ : /^([0-9]+)$/;
    const charCode = String.fromCharCode(event.charCode);
    const testString = !this.value ? charCode : this.value + charCode;

    if (!pattern.test(testString)) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  async onPaste(event: ClipboardEvent) {
    const value = await this.clipboardDataService.getClipboardTextValue(event);
    // convertin commas to dot to check if it's a number
    const valueCommasReplaced = value.replace(',', '.');
    if (Number.isNaN(+valueCommasReplaced)) {
      event.preventDefault();
    }
  }

  // FORM CONTROL FUNCTIONS
  setValue($event: any) {
    const value = this.convertNumberFormat($event.detail.value);
    this.input.value = value;
    this.value = (value && value.length) === 0 ? null : value;

    // removal of leading zero
    if (value && value !== String(+this.value)) {
      const { control } = this.ngControl;
      control.setValue(this.removeLeadingZero(this.value));
    }

    this.updateChanges();
  }

  updateChanges() {
    const emittedValue = this.value ? this.value.replace(',', '.') : this.value;
    this.onChange(emittedValue);
  }

  writeValue(value: string): void {
    this.value = this.convertNumberFormat(value);
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private getValidators(): ValidatorFn[] {
    const validators = [];

    if (!this.customValidator) {
      validators.push(positiveNumberValidator());
    }

    return validators;
  }

  private convertNumberFormat(value: string) {
    if (!value) {
      return null;
    }

    const { locale } = this.dataProvider.data.settings.defaultLang;
    if (locale === 'en') {
      return String(value).replace(',', '.');
    }

    return String(value).replace('.', ',');
  }

  private removeLeadingZero(value: string) {
    let lastLeadingZeroIdx = 0;

    for (let i = 0; i < value.length - 1; i += 1) {
      if (value[i] !== '0') {
        break;
      } else if (value[i + 1] === '0') {
        lastLeadingZeroIdx = i + 1;
      }
    }

    // edge case of one solo leading zero
    if (
      lastLeadingZeroIdx === 0 &&
      value.length > 1 &&
      value[0] === '0' &&
      value[1] !== '.' &&
      value[1] !== ','
    ) {
      lastLeadingZeroIdx = 1;
    }

    return value.substr(lastLeadingZeroIdx);
  }
}
