import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppModeService } from '../../services/app-mode/app-mode.service';

@Injectable({
  providedIn: 'root',
})
export class MobileGuard implements CanActivate {
  constructor(private appModeService: AppModeService, private router: Router) {}

  async canActivate() {
    await this.appModeService.waitForInitialization();
    if (!this.appModeService.isNative()) {
      this.router.navigate(['/home'], { queryParamsHandling: 'merge' });
      return false;
    }

    return true;
  }
}
