import { Injectable } from '@angular/core';
import { ApiService } from './api';
import {
  PostChallengeActivityActionBody,
  PostChallengeActivityActionResponse,
  PutChallengeActivityActionBody,
  ActionApiData,
} from '../../shared/interfaces/api/challenge-api.interface';

@Injectable({
  providedIn: 'root',
})
export class ChallengeApiService {
  constructor(private apiService: ApiService) {}

  postChallengeAction(
    challengeActionInfo: PostChallengeActivityActionBody
  ): Promise<PostChallengeActivityActionResponse> {
    return this.apiService.post(`/app/challenge/activity/action`, challengeActionInfo).toPromise();
  }

  // Update challenge activity action, if update flag is set the value will be replaced instead of incremented.
  public putChallengeActivityAction(
    action_id: number,
    body: PutChallengeActivityActionBody
  ): Promise<ActionApiData> {
    return this.apiService.put(`/app/challenge/activity/action/${action_id}`, body).toPromise();
  }
}
