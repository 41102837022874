/* This service is an implementation of https://github.com/Arnaud73/ngx-matomo/blob/master/projects/ngx-matomo/src/lib/matomo-tracker.service.ts */
/* 
  There is an issue between Matomo and Angular which require us to run Matomo outside Angular's loop
  https://github.com/matomo-org/tag-manager/issues/227
  https://github.com/Arnaud73/ngx-matomo/issues/28
*/
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, NgZone } from '@angular/core';
import { AppSettings } from '../../../app.settings';

declare let window: {
  [key: string]: any;
  prototype: Window;
  new (): Window;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let _paq: any;

@Injectable({
  providedIn: 'root',
})
export class MatomoService {
  constructor(private ngZone: NgZone) {}

  public init(trackerUrl: string, siteId: string, subdomain: string) {
    try {
      const script = document.createElement('script');

      script.innerHTML =
        `${'var _paq = _paq || [];_paq.push(["setCookieDomain", "'}${subdomain}"]);` +
        `_paq.push(["setDomains", "${subdomain}"]);` +
        `_paq.push(["enableCrossDomainLinking"]);` +
        `_paq.push(["enableLinkTracking"]);` +
        `_paq.push(["forgetUserOptOut"]);` +
        `(function () {` +
        `var uuu = "${trackerUrl}";` +
        `_paq.push(["setTrackerUrl", uuu + "js/"]);` +
        `_paq.push(["setSiteId", "${siteId}"]);` +
        `var ddd = document, ggg = ddd.createElement("script"), sss = ddd.getElementsByTagName("script")[0];` +
        `ggg.type = "text/javascript";` +
        `ggg.async = true;` +
        `ggg.defer = true;` +
        `ggg.src = uuu + "js/";` +
        `sss.parentNode.insertBefore(ggg, sss);` +
        `})();`;

      document.head.appendChild(script);
    } catch (err) {
      // since we wouldn't have tracking functionality if matomo
      // fails to load, we'll just print the error to console
      // eslint-disable-next-line no-console
      console.error('[MatomoTrackerService] init', err);
    }
  }

  public getInstance() {
    // console.log('[getInstance]', window._paq);

    if (window && window._paq !== undefined) {
      return window._paq;
    }
    return null;
  }

  public trackEvent(category: string, action: string, name?: string, value?: string): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [category, action];
        if (name) {
          args.push(name);
        }
        if (value) {
          args.push(value);
        }

        this.ngZone.runOutsideAngular(() => {
          matomo.push(['trackEvent', ...args]);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public trackPageView(customTitle?: string): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [];
        if (customTitle) {
          args.push(customTitle);
        }

        this.ngZone.runOutsideAngular(() => {
          matomo.push(['trackPageView', ...args]);
          matomo.push(['setCustomDimension', 1, AppSettings.APP_VERSION]);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public setUserId(userId: string) {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [userId];

        this.ngZone.runOutsideAngular(() => {
          matomo.push(['setUserId', ...args]);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public resetUserId() {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        this.ngZone.runOutsideAngular(() => {
          matomo.push(['resetUserId']);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public setDocumentTitle(customTitle: string): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [];
        args.push(customTitle);
        this.ngZone.runOutsideAngular(() => {
          matomo.push(['setDocumentTitle', ...args]);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public setCustomUrl(customUrl: string): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [];
        args.push(customUrl);

        this.ngZone.runOutsideAngular(() => {
          matomo.push(['setCustomUrl', ...args]);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public enableHeartBeatTimer(customInterval?: number): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [];
        if (customInterval) {
          args.push(customInterval);
        }

        this.ngZone.runOutsideAngular(() => {
          matomo.push(['enableHeartBeatTimer', ...args]);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public disableAutoDetectNewPageView() {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        this.ngZone.runOutsideAngular(() => {
          matomo.push(['HeatmapSessionRecording::disableAutoDetectNewPageView']);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public enableHeatMapAndSessionRecording() {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        this.ngZone.runOutsideAngular(() => {
          matomo.push(['HeatmapSessionRecording::enable']);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public disableHeatMapAndSessionRecording() {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        this.ngZone.runOutsideAngular(() => {
          matomo.push(['HeatmapSessionRecording::disable']);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public setNewPageView() {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        this.ngZone.runOutsideAngular(() => {
          matomo.push(['HeatmapSessionRecording::setNewPageView']);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public optOutUser() {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        this.ngZone.runOutsideAngular(() => {
          matomo.push(['optUserOut']);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public forgetUserOptOut() {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        this.ngZone.runOutsideAngular(() => {
          matomo.push(['forgetUserOptOut']);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  /**
   * Deletes the tracking cookies currently set (useful when creating new visits).
   */
  public deleteCookies(): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        this.ngZone.runOutsideAngular(() => {
          matomo.push(['deleteCookies']);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  /**
   * Disables all first party cookies.<br />
   * Existing Matomo cookies for this websites will be deleted on the next page view.
   */
  public disableCookies(): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        this.ngZone.runOutsideAngular(() => {
          matomo.push(['disableCookies']);
        });
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }
}
