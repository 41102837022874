import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api';
import {
  AvailableDatesApiResponse,
  PackageApiResponse,
  PutAppointmentBody,
  BookingWaitingListPutApiBody,
} from '../../shared/interfaces/api/package-api.interface';
import { AppointmentApiBody } from '../../shared/interfaces/api/appointment-api.interface';
import { Appointment } from '../../modules/appointments/state/appointments.interface';

@Injectable({ providedIn: 'root' })
export class BookingApiService {
  constructor(private apiService: ApiService) {}

  getPackage(voucher: string): Observable<PackageApiResponse> {
    return this.apiService.get(`/app/booking/package/${voucher}`);
  }

  getPackageAvailableDates(voucher: string): Observable<AvailableDatesApiResponse[]> {
    return this.apiService.get(`/app/booking/package/${voucher}/appointments`);
  }

  postAppointments(voucher: string, payload: AppointmentApiBody[]): Observable<Appointment[]> {
    return this.apiService.post(`/app/booking/package/${voucher}/appointments`, payload);
  }

  putWaitingList(notify: boolean, packageId: number): Observable<[]> {
    const payload: BookingWaitingListPutApiBody = {
      notify,
    };

    return this.apiService.put(`/app/booking/package/${packageId}/waiting-list`, payload);
  }

  getAppointmentUpdateable(appointment_id: number): Observable<[]> {
    return this.apiService.get(`/app/booking/appointment/${appointment_id}/updateable`);
  }

  deleteAppointment(appointment_id: number): Observable<[]> {
    return this.apiService.del(`/app/booking/appointment/${appointment_id}`);
  }

  putAppointment(appointment_id: number, payload: PutAppointmentBody): Observable<Appointment> {
    return this.apiService.put(`/app/booking/appointment/${appointment_id}`, payload);
  }

  isBookingAvailable(): Observable<{ booking_available: boolean }> {
    return this.apiService.get('/app/booking/available');
  }
}
