<ion-content class="c-modal__ion-content">
  <app-modal class="e-group-info-modal" center="true">
    <app-icon class="c-modal__icon" size="md" name="warning-outline"></app-icon>

    <h2 class="c-modal__title">
      {{'CHECKUP_RISK_FLAGGED_TITLE' | translate}}
    </h2>

    <div class="c-modal__description u-margin-bottom--xl">
      <p *ngIf="message; else placeholder" [innerHTML]="message"></p>
      <ng-template #placeholder>
        <p>{{ 'CHECKUP_RISK_FLAGGED' | translate}}</p>
      </ng-template>
    </div>

    <span class="c-modal__footer">
      <app-button class="u-width--100" (click)="submitWarningRead()" [loading]="loading">
        {{ 'CHECKUP_RISK_SUBMIT' | translate }}
      </app-button>
    </span>
  </app-modal>
</ion-content>