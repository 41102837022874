import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Questionnaire } from '../../../modules/questionnaires/state/questionnaires.interface';
import { ArticleData } from '../../../shared/interfaces/api/article-api.interface';
import { Challenge, JourneyData } from '../../../shared/interfaces/api/journey-api.interface';
import { ChallengeData } from '../../../shared/interfaces/challenges/challenge.interface';
import { UserResults } from '../../../shared/interfaces/checkup.interface';
import {
  OngoingJourney,
  RecommendedJourney,
} from '../../../shared/interfaces/journeys/journey.interface';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  public viewStepSubject$ = new Subject<string>();

  public userLoginSubject$ = new Subject<string>();

  public userLogoutSubject$ = new Subject<string>();

  public appLanguageSubject$ = new Subject<string>();

  public appResumeSubject$ = new Subject<string>();

  public offlineSyncSubject$ = new Subject<string>();

  public networkConnectedSubject$ = new Subject<string>();

  public dataCheckupSubject$ = new Subject<UserResults>();

  public dataArticlesSubject$ = new Subject<ArticleData[]>();

  public dataCompletedQuestionnaireSubject$ = new Subject<Questionnaire[]>();

  public dataQuestionnaireSubject$ = new Subject<Questionnaire[]>();

  public articlesReadSubject$ = new Subject<string>();

  public devToolArticleSubject$ = new Subject<ArticleData>();

  public devToolJourneySubject$ = new Subject<RecommendedJourney>();

  public devToolQuestionnaireSubject$ = new Subject<Questionnaire>();

  public devToolChallengeSubject$ = new Subject<Challenge>();

  public challengeLogActionSubject$ = new Subject<string>();

  public challengeCompletedSubject$ = new Subject<ChallengeData>();

  public challengeOngoingSubject$ = new Subject<string>();

  public challengeDismissSubject$ = new Subject<{ challengeId: number; journeyId: number }>();

  public journeyStatusUpdateSubject$ = new Subject<number>();

  public challengeQuitSubject$ = new Subject<number>();

  public challengeStartSubject$ = new Subject<number>();

  public challengeUpdateSubject$ = new Subject<string>();

  public journeysRefreshSubject$ = new Subject<OngoingJourney[]>();

  public journeyQuitSubject$ = new Subject<number>();

  public journeyStartSubject$ = new Subject<JourneyData>();

  public recommendedJourneysSubject$ = new Subject<RecommendedJourney[]>();
}
