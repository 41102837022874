import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiService } from './api';

import {
  JourneyData,
  PostChallengeJourneyBody,
  PostChallengeJourneyApiResponse,
  JourneyFlagType,
  ChallengeParameters,
} from '../../shared/interfaces/api/journey-api.interface';

import { ChallengeData } from '../../shared/interfaces/challenges/challenge.interface';

import { PutChallengeActivityResponse } from '../../shared/interfaces/api/challenge-api.interface';
import {
  CompletedJourney,
  RecommendedJourney,
  OngoingJourney,
} from '../../shared/interfaces/journeys/journey.interface';

@Injectable({
  providedIn: 'root',
})
export class JourneyApiService {
  constructor(private apiService: ApiService) {}

  public getJourney(journey_id: number): Promise<JourneyData> {
    return this.apiService.get(`/app/journey/${journey_id}`).toPromise();
  }

  public postJourney(journey_id: number): Promise<JourneyData> {
    return this.apiService.post(`/app/journey/${journey_id}`, {}).toPromise();
  }

  public deleteJourney(journey_id: number): Promise<JourneyData> {
    return this.apiService.del(`/app/journey/${journey_id}`).toPromise();
  }

  public getChallengeJourney(journey_id: number, challenge_id: number): Observable<ChallengeData> {
    return this.apiService.get(`/app/journey/${journey_id}/challenge/${challenge_id}`);
  }

  public postChallengeJourney(
    journey_id: number,
    challenge_id: number,
    body: PostChallengeJourneyBody
  ): Promise<PostChallengeJourneyApiResponse> {
    return this.apiService
      .post(`/app/journey/${journey_id}/challenge/${challenge_id}/activity`, body)
      .toPromise();
  }

  public postJourneyChallengeFlag(
    journey_id: number,
    challenge_id: number,
    body: { flag: JourneyFlagType }
  ) {
    return this.apiService
      .post(`/app/journey/${journey_id}/challenge/${challenge_id}/flag`, body)
      .toPromise();
  }

  public putChallengeJourney(
    activity_id: number,
    challengeInfo: ChallengeParameters
  ): Promise<PutChallengeActivityResponse> {
    return this.apiService
      .put(`/app/journey/challenge/activity/${activity_id}`, challengeInfo)
      .toPromise();
  }

  public getCompletedJourneys(): Promise<Array<CompletedJourney>> {
    return this.apiService.get(`/app/journeys/completed`).toPromise();
  }

  public getOngoingJourneys(): Promise<Array<OngoingJourney>> {
    return this.apiService.get(`/app/journeys/ongoing`).toPromise();
  }

  public getRecommendedJourneys(): Promise<Array<RecommendedJourney>> {
    return this.apiService.get(`/app/journeys/recommended`).toPromise();
  }

  public getOngoingChallenges(): Promise<Array<ChallengeData>> {
    return this.apiService.get(`/app/journeys/challenges/ongoing`).toPromise();
  }
}
