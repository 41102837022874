import { Injectable } from '@angular/core';

import { ApiService } from './api';

@Injectable({
  providedIn: 'root',
})
export class PushApiService {
  constructor(private apiService: ApiService) {}

  putNotificationOpened(
    push_notification_id: number,
    body: { foreground: boolean; coldstart?: boolean }
  ) {
    return this.apiService.put(`/app/push/${push_notification_id}/open`, body);
  }
}
